<div class="modal-header" *ngIf="!media">
  <h4 class="modal-title pull-left">{{ modalTitle }}</h4>
  
  <button
    type="button"
    class="btn pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span><i class="bx bx-x"></i></span>
  </button>
</div>
<button
*ngIf="media"
type="button"
class="btn-close close pull-right shadow btn-close-media"
aria-label="Close"
(click)="bsModalRef.hide()"
>
<span aria-hidden="true" class="visually-hidden">&times;</span>
</button>
<div
  class="modal-body"
  *ngIf="scenario"
  style="overflow-y: scroll;position: relative;"
  [ngClass]="{'modal-body-slim':media}"
>
 
  <div [innerHTML]="modalContent" *ngIf="modalContent"></div>
  <div *ngIf="media">
    <ng-container [ngSwitch]="mediaService.getMediaType(media)">
      <ng-container *ngSwitchCase="'image'">
        <img [src]="media" alt="" class="d-block w-100" />
      </ng-container>
      <ng-container *ngSwitchCase="'video'">
        <video
          style="width: 100%; max-height: 100%"
          controls
          controlsList="nodownload"
        >
          <source
            [src]="environment.mediaUrl + '/assets/' + media"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </ng-container>
      <ng-container *ngSwitchCase="'file'">
        <a class="btn btn-primary btn-lg" [href]="media" target="_blank"
          ><i class="bx bx-download"></i> Download</a
        >
      </ng-container>
      <ng-container *ngSwitchCase="'audio'">
        <audio
          style="width: 100%; max-height: 100%"
          [src]="environment.mediaUrl + '/assets/' + media"
          controls
          controlsList="nodownload"
        >
          Your browser does not support the audio element.
        </audio>
      </ng-container>
    </ng-container>
  </div>
</div>
