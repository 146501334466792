import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'animationStyle'
})
export class AnimationStylePipe implements PipeTransform {

  transform(component: any, controlVar:any): [] {
    
    let style:any = [];
    if (component[controlVar]?.name) {
      style['--animate-duration'] =
      component[controlVar].speed + 's';
      style['--animate-delay'] = component[controlVar].delay + 's';

      if (component[controlVar].repeat) {
        if (component[controlVar].repeat !== 'infinite') {
          style['--animate-repeat'] = +component[controlVar].repeat;
        }
      }
    }

    return style;
  }

}
