import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from '../services/content.service';

@Pipe({
  name: 'componentClass',
})
export class ComponentClassPipe implements PipeTransform {
  constructor(private contentService: ContentService) {}
  transform(component: unknown, mode: string, scenario: unknown, componentData?:string): string {
    
    let classStr = this.contentService.getComponentClass(
      component,
      mode,
      scenario
    );
    return classStr;
  }
}
