import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MediaService } from 'src/app/services/media.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-modal',
  templateUrl: './player-modal.component.html',
  styleUrls: ['./player-modal.component.scss'],
})
export class PlayerModalComponent {
  @Input() scenario: any;
  media: any;
  modalTitle: any;
  modalContent: any;
  picked: any = [];
  playerInventroy: any = [];
  environment = environment;

  constructor(
    public bsModalRef: BsModalRef,
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService,
    public mediaService: MediaService
  ) {}

  ngOnInit(): void {}

}
