<div class="chart-container">
  <canvas
  *ngIf="
    component.chart.type &&
    component.chart.data[0].data &&
    component.chart.labels
  "
  baseChart
  [data]="dataSet"
  [options]="options"
  [type]="component.chart.type"
  
>
</canvas>
<span
  class="img-placeholder"
  *ngIf="
    !component.chart.type ||
    !component.chart.data[0].data ||
    !component.chart.labels
  "
>
  <i class="bx bx-pie-chart-alt-2"></i>
</span>
</div>