import { Component, Input, OnInit } from '@angular/core';
import { PlayerSessionService } from 'src/app/services/player-session.service';

@Component({
  selector: 'app-download-component',
  templateUrl: './download-component.component.html',
  styleUrls: ['./download-component.component.scss']
})
export class DownloadComponentComponent implements OnInit {
  @Input() component: any;

  constructor(private playerSessionService: PlayerSessionService) {}
  ngOnInit(): void {}
  onDownloadPressed(){
    this.playerSessionService.addToLog('downloadPressed', {'id':this.component.id, 'src':this.component.data.src});
  }
}
