<div
  class="chat-container chat-adventure"
  style="--animate-duration: 0.25s"
  [attr.id]="
    mode == 'player'
      ? 'chat-container-' + this.uuid + component.id
      : 'chat-container-editor-' + component.id
  "
>
  <div class="component-toolbar position-absolute" *ngIf="mode == 'editor'">
    <div class="toolbar-btns">
      <button
        class="btn btn-sm btn-white"
        [ngClass]="{ active: editMode }"
        placement="bottom"
        tooltip="Edit Layout"
        [delay]="300"
        (click)="onToggleEditMode()"
      >
        <i class="bx bx-edit"></i>
      </button>
    </div>
  </div>

  <div class="task-obscure" *ngIf="completed && component.data.obscure"></div>
  <img
    [attr.id]="
      mode == 'player'
        ? 'chat-avatar-' + this.uuid + component.id
        : 'chat-avatar-editor-' + component.id
    "
    class="chat-adventure-bg {{ editMode ? ' cursor-move ' : '' }}{{
      component
        | animationClass
          : 'avatarAnimationIn'
          : contentService.stringify(component)
    }}"
    [ngStyle]="component | animationStyle : 'avatarAnimationIn'"
    src="{{ component.data.avatar }}"
    *ngIf="component.data.avatar"
    alt="Background of the chat comonent"
    style="width:{{ component.data.avatarScale }}%;height:{{
      component.data.avatarScale
    }}%;{{
      'display:block;position:absolute;left:' +
        avatarPosition.x +
        'px;top:' +
        avatarPosition.y +
        'px;'
    }}"
    cdkDrag
    (cdkDragEnded)="onDragEnded($event, 'avatar')"
    (cdkDragStarted)="onDragStarted($event, 'avatar')"
    (cdkDragMoved)="onDragMove($event, 'avatar')"
    [cdkDragDisabled]="mode != 'editor' || !editMode"
  />
  <blockquote
    [attr.id]="
      mode == 'player'
        ? 'chat-speech-' + this.uuid + component.id
        : 'chat-speech-editor-' + component.id
    "
    class="speech-bubble {{ bubbleAnimClass }}{{
      editMode ? ' cursor-move' : ''
    }}{{ mode == 'player' ? ' position-absolute' : '' }}"
    [ngClass]="{
      'sb-br': component.style.bubbleStyle == 'br',
      'sb-bl': component.style.bubbleStyle == 'bl',
      'sb-tr': component.style.bubbleStyle == 'tr',
      'sb-tl': component.style.bubbleStyle == 'tl',
    }"
    cdkDrag
    cdkDragBoundary=".chat-adventure"
    (cdkDragEnded)="onDragEnded($event, 'speech')"
    [cdkDragDisabled]="mode != 'editor' || !editMode"
    [cdkDragFreeDragPosition]="mode == 'editor' ? speechPosition : null"
    style="animation-duration: 1s;width: {{
      component.style.speechWidth
    }}px;font-size:{{ component.style.bubbleTextScale }}%;line-height:{{
      +component.style.bubbleTextScale * 0.75
    }}%;"
  >
    <p>
      <span [innerHTML]="response | nl2br" *ngIf="response"></span>
      <span
        [innerHTML]="component.data.opening | nl2br"
        *ngIf="!response"
      ></span>
    </p>
  </blockquote>
  <div
    class="chat-choices-container chat-choices-container-{{
      component.style.optionsPosition
    }}"
    *ngIf="!completed"
    style="background-color:{{ component.style.optionsBg }};"
  >
    <div
      class="chat-choices animate__animated animate__fadeIn"
      *ngIf="!hideOptions"
    >
      <ng-container *ngIf="activeStems">
        <ng-container *ngFor="let stem of activeStems">
          <a
            [hidden]="playerService.getComponentHidden(stem)"
            class="speech-bubble speech-bubble-sm sb-tl hover-grow"
            (click)="onChooseStem(stem)"
            >{{ stem.q }}</a
          >
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!activeStems">
        <ng-container *ngFor="let stem of getStemsByParent(null)">
          <a
            [hidden]="playerService.getComponentHidden(stem)"
            class="speech-bubble speech-bubble-sm sb-tl hover-grow"
            (click)="onChooseStem(stem)"
            >{{ stem.q }}</a
          >
        </ng-container>
      </ng-container>
    </div>

    <button
      type="button"
      class="btn btn-ys btn-end-chat"
      (click)="onEndChat($event)"
    >
      <i
        class="bx {{ component.style.endChatIcon }} me-1"
        *ngIf="component.style.endChatIcon"
      ></i>
      <ng-container *ngIf="!component.data.endLabel">End Chat</ng-container>
      <ng-container *ngIf="component.data.endLabel">{{
        component.data.endLabel
      }}</ng-container>
    </button>
  </div>
  <div
    class="chat-choices-container chat-choices-container-{{
      component.style.optionsPosition
    }}"
    *ngIf="completed && (component.data.allowRetake || this.mode == 'editor')"
    style="background-color:{{ component.style.optionsBg }};"
  >
    <button
      type="button"
      class="btn btn-ys btn-end-chat"
      (click)="onRetakeChat()"
    >
      <i
        class="bx {{ component.style.retakeChatIcon }} me-1"
        *ngIf="component.style.retakeChatIcon"
      ></i>
      <ng-container *ngIf="!component.data.retakeLabel">Retake</ng-container>
      <ng-container *ngIf="component.data.retakeLabel">{{
        component.data.retakeLabel
      }}</ng-container>
    </button>
  </div>
</div>
