import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-user-tracking-form',
  templateUrl: './user-tracking-form.component.html',
  styleUrls: ['./user-tracking-form.component.scss'],
})
export class UserTrackingFormComponent {
  @Input() scenario: any;
  submitted = false;
  formProcessing = false;

  formData: any = {};
  userData:any = {};

  @ViewChild('userTrackingForm', { static: false }) userTrackingForm?: NgForm;

  constructor(
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {
    if (this.scenario.settings.tracking && this.scenario.settings.tracking.fields) {
      this.scenario.settings.tracking.fields.forEach((field: any) => {
        this.formData[field.id] = null;
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    this.formProcessing = true;
    // do it
    if (!this.userTrackingForm?.valid) {
      this.formProcessing = false;
      return false;
    } else {
      // this.userData = JSON.parse(JSON.stringify(this.formData));
      this.scenario.settings.tracking.fields.forEach((field: any) => {
        this.userData[field.name] = JSON.parse(JSON.stringify(this.formData[field.id]));
      });
      this.playerSessionService.setUserData(this.userData);
      return true;
    }
  }
}
