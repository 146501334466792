import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchAll() {
    return this.http
      .get<any>(environment.apiUrl + '/settings', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.settings[0];
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}


