import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from '../services/content.service';

@Pipe({
  name: 'componentStyle',
})
export class ComponentStylePipe implements PipeTransform {
  constructor(private contentService: ContentService) {}
  transform(
    component: unknown,
    mode: string,
    scenario: any,
    componentData?: string
  ): any[] {
    let componentStyles = this.contentService.getComponentStyle(
      component,
      mode,
      scenario
    );
    return componentStyles;
  }
}
