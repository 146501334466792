import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { EquirectProjection, View360Options } from '@egjs/ngx-view360';
import View360, { ControlBar, LoadingSpinner } from '@egjs/view360';
import * as glMatrix from 'gl-matrix';
import { nanoid } from 'nanoid';
import { EditorService } from 'src/app/services/editor.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import { ScenarioService } from 'src/app/services/scenario.service';
export { glMatrix };

@Component({
  selector: 'app-panoramic-image-component',
  templateUrl: './panoramic-image-component.component.html',
  styleUrls: ['./panoramic-image-component.component.scss'],
})
export class PanoramicImageComponentComponent {
  constructor(
    private editorService: EditorService,
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService,
    private scenarioService: ScenarioService
  ) {}

  activeScene = 0;

  @Input() component: any;
  @Input() scenario: any;
  @ViewChild('viewer') public view360: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  uuid = nanoid();

  hotspotVisible: any = {};

  options: Partial<View360Options> = {
    rotate: true,
    plugins: [
      new ControlBar({
        fullscreenButton: false,
        pieView: {
          position: ControlBar.POSITION.TOP_LEFT,
          order: 0,
        },
      }),
      new LoadingSpinner(),
    ],
  };
  current360SceneSub: any;
  completed = false;
  varsSub: any;
  scenarioVars: any;

  ngOnInit() {
    this.scenarioService.data.subscribe((scenarioData) => {
      this.scenario = scenarioData;
    });

    this.varsSub = this.playerSessionService.variablesChanges.subscribe(
      (changed) => {
        // copy the object so the pipe updates
        this.scenarioVars = JSON.parse(
          JSON.stringify(this.playerSessionService.getVars())
        );
      }
    );

    this.current360SceneSub = this.playerService.current360Scene.subscribe(
      (currentScene: any) => {
        if (currentScene) {
          this.component.data?.scenes.forEach(
            (scene: any, sceneIndex: number) => {
              if (scene.id == currentScene) {
                this.activeScene = sceneIndex;
                this.onSetActiveScene(this.activeScene);
              }
            }
          );
        }
      }
    );
  }

  ngAfterViewInit() {
    if (this.component?.data?.scenes[0]?.src) {
      this.view360.load(
        new EquirectProjection({
          src: this.component?.data?.scenes[0]?.src.replace(
            'https://static.yoscenario.com/',
            'https://yoscenario.s3.eu-west-2.amazonaws.com/'
          ),
        })
      );
    }
  }
  onClickHotspot(hotspot: any) {
    // check actions...
    // interactivity show window on click
    if (
      hotspot?.interactivity?.window?.id &&
      hotspot?.interactivity?.window?.trigger == 'click'
    ) {
      this.playerService.updateComponent.next({
        id: hotspot.interactivity.window.id,
        newData: { show: true },
      });
    }

    this.playerSessionService.addToLog('360HotspotPressed', {
      id: this.component.id,
      hotspot: hotspot,
    });
    this.playerService.doActions(
      hotspot.actions,
      'hotspotPressed',
      this.component
    );
  }

  onSetActiveScene(sceneIndex: number) {
    this.hotspotVisible = {};
    if (this.view360) {
      this.activeScene = sceneIndex;
      this.view360.load(
        new EquirectProjection({
          src: this.component?.data?.scenes[this.activeScene]?.src.replace(
            'https://static.yoscenario.com/',
            'https://yoscenario.s3.eu-west-2.amazonaws.com/'
          ),
        })
      ).then((response:any)=>{
        this.view360.camera.animateTo({
          yaw: 0,
          pitch: 0,
          zoom: 1,
          duration: 10,
        });
        setTimeout(() => {
          this.view360.hotspot.refresh();
          this.view360.hotspot.render(this.view360.camera);
        }, 10);
      });
      

      
    }
  }

  ngOnDestroy() {
    this.hotspotVisible = {};
    this.playerService.current360Scene.next(0);
    this.onSetActiveScene(0);
    this.current360SceneSub.unsubscribe();
    this.varsSub?.unsubscribe();
  }

  onComplete() {
    this.completed = true;
    this.hotspotVisible = {};
    this.activityEnded.emit(this.component);
    this.playerService.executeActionQueue();
  }

  onCancel() {
    this.hotspotVisible = {};
    this.playerSessionService.addToLog('360HotspotCancelled', {
      id: this.component.id,
    });
    this.completed = true;
    this.activityEnded.emit(this.component);
  }

  onHotspotShown(hotspot: any) {
    this.hotspotVisible[hotspot.id] = true;
  }
  onHotspotHidden(hotspot: any) {
    this.hotspotVisible[hotspot.id] = false;
  }
}
