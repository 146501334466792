<video
  *ngIf="!component.hidden && !playerService.getComponentHidden(component)"
  style="width: 100%; max-height: 100%"
  [attr.autoplay]="component.video.autoplay && !disableVideo ? true : null"
  [attr.loop]="component.video.loop && !disableVideo ? true : null"
  [attr.controls]="component.video.controls ? true : null"
  [attr.muted]="component.video.muted ? true : null"
  (click)="playerService.onVideoClick($event, component)"
  (canplay)="playerService.onVideoCanPlay($event, component)"
  controlsList="nodownload"
  (ended)="playerService.onVideoEnd($event, endEvents, component, content)"
  (pause)="playerService.onVideoPause($event, pauseEvents, component, content)"
  (play)="playerService.onVideoPlay($event, playEvents, component, content)"
  (timeupdate)="playerService.onVideoTimeUpdate($event, timedEvents, component, content)"
  playsinline
  id="video-{{ component.id }}"
  [muted]="muted"
>
  <source src="{{ component.video.src }}" type="video/mp4" />
  Your browser does not support the video tag.

  <!-- captions -->
  <track
    *ngIf="component.video.captions"
    label="English"
    kind="subtitles"
    srclang="en"
    src="{{ component.video.captions }}"
    default
  />
</video>

<span
  id="countdown-video-{{ component.id }}"
  class="video-progress"
  *ngIf="component.video.progressBar"
></span>
