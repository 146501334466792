import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
//import { UpdateService } from './services/update.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SettingsService } from './services/settings.service';
//import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'scenario-player';


  constructor(
   // private sw: UpdateService,
    private router: Router,
    private titleService: Title,
 //   private ccService: NgcCookieConsentService,
   // private authService: AuthService,
    private viewportScroller: ViewportScroller,
    private settingsService: SettingsService,
   // private analyticsService: AnalyticsService,
    public vcRef: ViewContainerRef,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    // check the service worker for updates
   // this.sw.checkForUpdates();
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }
}

