import { Component, EventEmitter, Input, Output } from '@angular/core';
import { nanoid } from 'nanoid';
import { ContentService } from 'src/app/services/content.service';
import { EditorService } from 'src/app/services/editor.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-adventure-component',
  templateUrl: './chat-adventure-component.component.html',
  styleUrls: ['./chat-adventure-component.component.scss'],
})
export class ChatAdventureComponentComponent {
  @Input() component: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  activeStems: any;
  currentStem: any;
  response: any;
  lastAsk: any;
  rootStem: any;

  convoAnimClass: any;
  avatarAnimClass: any;
  bubbleAnimClass: any = 'animate__animated animate__fadeIn';
  chatChoicesAnimClass: any;
  lastAskAnim: any;

  completed = false;
  environment = environment;
  chatLog: any = [];
  @Input() mode: any;
  showChoices = false;
  isTyping: any = null;
  speechPosition: any = { x: 0, y: 0 };
  avatarPosition: any = { x: 0, y: 0 };
  editMode = false;
  hideOptions = false;
  answerInterval: any;
  componentAtDragStart: any;

  partCount = 0;
  answerParts: any;
  uuid: any = nanoid(12);
  endActivitySub: any;

  constructor(
    public contentService: ContentService,
    private playerSessionService: PlayerSessionService,
    public playerService: PlayerService,
    private editorService: EditorService
  ) {}

  ngOnInit(): void {
    this.currentStem = null;
    //this.activeStems = this.getStemsByParent(this.currentStem);
    

    this.convoAnimClass = 'animate__animated animate__fadeIn';
    this.avatarAnimClass = 'animate__animated animate__fadeIn';
    this.bubbleAnimClass = 'animate__animated animate__fadeIn';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeIn';
    this.lastAskAnim = 'animate__animated animate__fadeIn';

    if (this.component && this.component?.data?.speechPosition) {
      this.speechPosition = this.component?.data?.speechPosition;
    }
    if (this.component && this.component?.data?.avatarPosition) {
      this.avatarPosition = this.component?.data?.avatarPosition;
    }
    if (!this.component.style.speechWidth) {
      this.component.style.speechWidth = 300;
    }
    if (!this.component.style.bubbleStyle) {
      this.component.style.bubbleStyle = 'br';
    }
    if (!this.component.data.avatarScale) {
      this.component.data.avatarScale = 100;
    }

    if (!this.component.style.optionsPosition) {
      this.component.style.optionsPosition = 'bottom';
    }
    if (!this.component.style.bubbleTextScale) {
      this.component.style.bubbleTextScale = 150;
    }

    if (!this.component.style.optionsBg) {
      this.component.style.optionsBg = 'rgba(0,0,0,0.5)';
    }
    //this.activeStems = $filter('filter')(component.data.stems, {parent: null}, true);
    //      $scope.chatTask.taskData[component.uId].startStem = $filter('filter')(component.data.stems, {parent: null}, true);

    this.endActivitySub = this.playerService.endActivity.subscribe(
      (endActivity) => {
        if (endActivity) {
          this.onEndChat(null);
          this.playerService.endActivity.next(false);
        }
      }
    );
    if (this.mode == 'editor') {
      this.completed = false;
    }
  }

  ngAfterViewInit() {
    // change the speech bubble to be positioned bottomly..
    if (this.mode == 'player') {
      let speechElem = document.getElementById(
        'chat-speech-' + this.uuid + this.component.id
      );
      let container = document.getElementById(
        'chat-container-' + this.uuid + this.component.id
      );

      if (speechElem && container) {
        if (
          !this.component.style.bubbleStyle ||
          this.component.style?.bubbleStyle == 'bl' ||
          this.component.style?.bubbleStyle == 'br'
        ) {
          speechElem.style.bottom =
            container.offsetHeight -
            speechElem.offsetHeight -
            this.speechPosition.y +
            'px';
        } else {
          speechElem.style.top = this.speechPosition.y + 'px';
        }

        speechElem.style.left = this.speechPosition.x + 'px';
      }

      // add avatar animations for during and out
      let avatarElem = document.getElementById(
        'chat-avatar-' + this.uuid + this.component.id
      );
      if (avatarElem) {
        setTimeout(() => {
          this.contentService.addAnimations(
            this.component,
            'avatarAnimationIn',
            'avatarAnimationDuring',
            'avatarAnimationOut',
            avatarElem
          );
        }, 10);
      }
    }
  }

  getStemsByParent(parent: any) {
    let activeStems: any = [];
    this.component.data.stems.forEach((stem: any) => {
      if (stem.parent == parent) {
        activeStems.push(stem);
      }
    });
    return activeStems;
  }

  onChooseStem(stem: any) {
    this.hideOptions = true;
    this.showChoices = false;
    this.playerSessionService.addToLog('chatStem', {
      stemId: stem.id,
      q: stem.q,
      a: stem.a,
      componentId: this.component.id,
    });

    this.chatLog.push({
      stemId: stem.id,
      q: stem.q,
      a: stem.a,
    });

    if (stem.aAudio) {
      this.playerService.playAudio(
        this.environment.mediaUrl + '/assets/' + stem.aAudio,
        'chat'
      );
    }
    if(stem.aAvatar){
      this.component.data.avatar = stem.aAvatar;
    }
    if (this.component.data.actions) {
      this.component.data.actions.forEach((action: any) => {
        this.playerService.doAction(
          this.component,
          action,
          null,
          'onChooseStem',
          stem
        );
      });
    }

    // animate
    this.convoAnimClass = 'animate__animated animate__fadeOut';
    //this.avatarAnimClass = 'animate__animated animate__fadeOutUp';
    this.bubbleAnimClass = 'animate__animated animate__fadeOut';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeOut';
    this.lastAskAnim = 'animate__animated animate__fadeOut';

    // set root?
    if (stem.setRoot) {
      this.rootStem = stem.id;
    }
    // reset root?
    if (stem.resetRoot) {
      this.rootStem = null;
    }

    this.answerParts = stem.a.split('\n\n');

    this.partCount = 0;
    // set the new stems and add the anim classes
    this.revealAnser(this.answerParts[this.partCount]);

    this.partCount++;
    if (this.partCount == this.answerParts.length) {
      this.lastAsk = stem.q;
      this.activeStems = this.getStemsByParent(stem.id);

      setTimeout(() => {
        this.hideOptions = false;
      }, 1000);
    }
    if (this.answerParts.length > 1) {
      this.answerInterval = setInterval(() => {
        if (this.partCount < this.answerParts.length) {
          this.revealAnser(this.answerParts[this.partCount]);
          this.partCount++;
          if (this.partCount == this.answerParts.length) {
            this.lastAsk = stem.q;
            this.activeStems = this.getStemsByParent(stem.id);

            setTimeout(() => {
              this.hideOptions = false;
            }, 1000);
          }
        } else {
          clearInterval(this.answerInterval);
          setTimeout(() => {
            this.hideOptions = false;
          }, 1000);
        }
      }, 2000);
    }
    // end animate
  }

  revealAnser(answer: string) {
    // set the hide classes
    // animate
    this.convoAnimClass = 'animate__animated animate__fadeOut';
    //this.avatarAnimClass = 'animate__animated animate__fadeOutUp';
    this.bubbleAnimClass = 'animate__animated animate__fadeOut';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeOut';
    this.lastAskAnim = 'animate__animated animate__fadeOut';
    setTimeout(() => {
      // set the new stems and add the anim classes
      this.response = answer;

      if (this.activeStems.length == 0) {
        // go to root stem
        this.activeStems = this.getStemsByParent(this.rootStem);
      }
      this.convoAnimClass = 'animate__animated animate__fadeIn';
      // this.avatarAnimClass = 'animate__animated animate__fadeIn';
      this.bubbleAnimClass = 'animate__animated animate__fadeIn';
      this.chatChoicesAnimClass = 'animate__animated animate__fadeIn';
      this.lastAskAnim = 'animate__animated animate__fadeIn';
    }, 300);
  }

  onEndChat(event: any) {
    this.bubbleAnimClass = 'animate__animated animate__fadeOut';
    this.completed = true;
    if (this.mode == 'player') {
      this.activityEnded.emit(this.component);
    }
  }

  onDragEnded($event: any, component: any) {
    let container = document.getElementById(
      'chat-container-editor-' + this.component.id
    );
    let speech = document.getElementById(
      'chat-speech-editor-' + this.component.id
    );
    let avatar = document.getElementById(
      'chat-avatar-editor-' + this.component.id
    );
    if (container && speech && avatar) {
      let posX;
      let posY;
      let rect = container.getBoundingClientRect();
      switch (component) {
        case 'speech':
          let rectSpeech = speech.getBoundingClientRect();
          posX = rectSpeech.x - rect.x;
          posY = rectSpeech.y - rect.y;
          this.component.data.speechPosition = { x: posX, y: posY };
          console.log(this.component.data.speechPosition);
          break;
        case 'avatar':
          if (avatar && container) {
            let rect = avatar.getBoundingClientRect();
            let playerRect = container.getBoundingClientRect();
            if(!this.component.data.avatarPosition){
              this.component.data.avatarPosition = {};
            }
            this.component.data.avatarPosition.x = rect.left - playerRect.left;
            this.component.data.avatarPosition.y = rect.top - playerRect.top;
            this.avatarPosition = this.component.data.avatarPosition;
            $event.source._dragRef.setFreeDragPosition({
              x: this.component.data.avatarPosition.x,
              y: this.component.data.avatarPosition.y,
            });
            $event.source._dragRef.reset();
          }
          break;
      }
    }
  }

  onDragStarted($event: any, component: any) {
    switch (component) {
      case 'avatar':
        if (this.component?.data?.avatarPosition) {
          this.componentAtDragStart = JSON.parse(
            JSON.stringify(this.component?.data?.avatarPosition)
          );
        }
        break;
    }
  }

  onDragMove($event: any, component: any) {
    if (this.componentAtDragStart) {
      if(!this.component.data.avatarPosition){
        this.component.data.avatarPosition = {};
      }
      this.component.data.avatarPosition.x =
        this.componentAtDragStart.x + $event?.distance?.x;
      this.component.data.avatarPosition.y =
        this.componentAtDragStart.y + $event?.distance?.y;
      this.avatarPosition = this.component.data.avatarPosition;
    }
  }

  onToggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      let instructionText: any =
        'You can drag the speech bubble and the images to move them into position';
      this.editorService.instructions.next(instructionText);
    } else {
      this.editorService.instructions.next(null);
    }
  }
  ngOnDestroy() {
    this.completed = false;
    this.endActivitySub.unsubscribe();
  }

  onRetakeChat() {
    this.rootStem = null;
    this.completed = false;
    this.currentStem = null;
    this.activeStems = this.getStemsByParent(this.currentStem);
    this.response = this.component.data.opening;
  }
}
