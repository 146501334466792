<div class="table-container">
  <table class="table" [ngClass]="{'table-striped':component.data?.options?.striped, 'table-hover':component.data?.options?.hover, 'table-bordered':component.data?.options?.border}">
    <thead>
      <tr>
        <th *ngFor="let col of component.data.cols" class="position-relative">
          {{ col.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of component.data.rows; let rowIndex = index">
        <td
          *ngFor="let col of component.data.cols; let colIndex = index"
          class="position-relative"
        >
          {{ row[col.id] }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
