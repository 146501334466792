<div
  *ngIf="component.data.image.src"
  class="hotspot-container"
  [ngClass]="{ 'hotspot-icon-hover': component?.data?.options?.pointerOnHover }"
>
  <div
    class="task-obscure"
    *ngIf="completed && component.data.options.obscure"
  ></div>

  <ng-container *ngFor="let hotspot of component.data.hotspots">
    <ng-template #popTemplate>
      <app-popover [scenario]="scenario" [component]="hotspot"></app-popover>
    </ng-template>
    <ng-template #tooltipTemplate
      ><div
        [innerHtml]="
          hotspot?.interactivity?.tooltip?.content
            ? (hotspot?.interactivity?.tooltip?.content
              | scenarioVars : scenarioVars)
            : (hotspot?.tooltip | scenarioVars : scenarioVars)
        "
      ></div
    ></ng-template>

    <div
      class="hotspot"
      [ngStyle]="getHotspotStyle(hotspot)"
      [ngClass]="getHotspotClass()"
      (click)="onHotspotPressed(hotspot)"
      [tooltip]="tooltipTemplate ? tooltipTemplate : ''"
      triggers="{{
        !hotspot?.interactivity?.tooltip?.content && !hotspot?.tooltip
          ? ''
          : 'hover'
      }}"
      containerClass="scenario-ui-{{ scenario?.id }} ys-tooltip"
      [adaptivePosition]="true"
      container="body"
      boundariesElement="viewport"
    >
      <div
        [popover]="
          hotspot.interactivity?.popover?.content?.body ||
          hotspot.interactivity?.popover?.content?.image ||
          hotspot.interactivity?.popover?.content?.video
            ? popTemplate
            : ''
        "
        [popoverTitle]="
          hotspot.interactivity?.popover?.template?.title
            ? hotspot.interactivity?.popover?.content?.title
            : ''
        "
        container="body"
        triggers="{{
          hotspot.interactivity?.popover?.template?.trigger
            ? hotspot.interactivity?.popover?.template?.trigger
            : 'click'
        }}"
        containerClass="scenario-ui-{{ scenario?.id }} ys-popover-{{
          hotspot.id
        }} ys-popover {{
          !hotspot.interactivity?.popover?.template?.title
            ? 'ys-popover-no-title'
            : ''
        }}"
        [isOpen]="!completed && hotspotVisible[hotspot.id]"
        (onShown)="onHotspotShown(hotspot)"
        (onHidden)="onHotspotHidden(hotspot)"
      >
        <i class="bx  {{ component.style.icon }}"></i>
      </div>
    </div>
  </ng-container>

  <img
    src="{{ component.data.image.src }}"
    alt=""
    class="hotspot-img"
    [attr.width]="component.data.image.width + '%'"
    [ngStyle]="getHotspotImageStyles(component)"
  />
  <div
    class="task-btns  text-{{
      component.data?.buttonAlignment
        ? component.data?.buttonAlignment
        : 'center'
    }}"
    *ngIf="!completed && !component.data?.options?.hideButtons"
  >
    <button
      class="btn btn-ys btn-primary me-2"
      (click)="onCancel()"
      *ngIf="component.data?.options?.dismissable"
      [tooltip]="
        component.data?.cancelTooltip ? component.data?.cancelTooltip : null
      "
      [delay]="300"
      container="body"
    >
      <i
        class="bx {{ component.style.cancelIcon }} bx-fw"
        *ngIf="component.style.cancelIcon"
      ></i>
      <ng-container *ngIf="component.data.cancelLabel">{{
        component.data.cancelLabel
      }}</ng-container>
      <ng-container
        *ngIf="!component.data.cancelLabel && !component.style.cancelIcon"
        >Cancel</ng-container
      >
    </button>
    <button
      class="btn btn-ys btn-primary"
      (click)="onComplete()"
      [tooltip]="
        component.data?.completeTooltip ? component.data?.completeTooltip : null
      "
      [delay]="300"
      container="body"
    >
      <i
        class="bx {{ component.style.completeIcon }} bx-fw"
        *ngIf="component.style.completeIcon"
      ></i>
      <ng-container *ngIf="component.data.completeLabel">{{
        component.data.completeLabel
      }}</ng-container>
      <ng-container
        *ngIf="!component.data.completeLabel && !component.style.completeIcon"
        >Complete</ng-container
      >
    </button>
  </div>
</div>
