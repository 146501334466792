import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent {

  @Input() reason?:any;

  ngOnInit() {
    console.log(this.reason);
  }
}
