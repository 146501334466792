import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-chart-component',
  templateUrl: './chart-component.component.html',
  styleUrls: ['./chart-component.component.scss']
})
export class ChartComponentComponent implements OnInit {
  @Input() component: any;
  @Input() scenario: any;
  @Input() mode: any;
  dataSet:any;
  options:any;


  constructor(private contentService:ContentService) {}

  ngOnInit(): void {
    this.dataSet = this.contentService.getChartDataSet(this.component, this.mode);
    this.options = this.contentService.getChartOptions(this.component, this.scenario, this.mode);

    this.contentService.refreshChart.subscribe((component:any)=>{
      if(component && component.id == this.component.id){
       // console.log('refreshed!');
        this.dataSet = this.contentService.getChartDataSet(component, this.mode);
        this.options = this.contentService.getChartOptions(component, this.scenario, this.mode);
      }
    })

    }

}
