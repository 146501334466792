<div
  class="rotate-device"
  *ngIf="scenario && scenario.settings && scenario.settings.showRotateMessage"
>
  <div class="phone"></div>
  <div class="message" *ngIf="scenario.settings.showRotateMessageText">
    {{ scenario.settings.showRotateMessageText }}
  </div>
</div>
<div class="player-bg" style="background-color: var(--ys-bg)"></div>
<div
  *ngIf="content"
  [ngStyle]="contentService.getCanvasStyle(content, scenario.style)"
  style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
  class="scenario-{{ scenario.id }}"
  id="ys-player"
  #ysplayer
  aria-live="polite"
>
  <div
    *ngIf="
      (content.canvas && content.canvas.style && content.canvas.style.bg) ||
      (scenario.style.bgColor && !content.canvas.style.bgImage)
    "
    style="width:100%;height:100%;position:absolute;top:0;left:0;background-color:{{
      content.canvas.style.bg ? content.canvas.style.bg : 'var(--ys-bg)'
    }}"
  ></div>

  <div
    class="countdown-clock"
    *ngIf="activeCountdown"
    style="z-index: 10000000"
  >
    {{ activeCountdownClock }}
  </div>

  <div
    class="step-content-container"
    id="step-content-container"
    style="transform: translate(-50%, -50%) scale({{ scale }}) ; width:{{
      scenario.settings.canvas.width
    }}px; height:{{ scenario.settings.canvas.height }}px;"
    *ngIf="scale && animsComplete"
    #stepContentContainer
  >
    <app-user-tracking-form
      [scenario]="scenario"
      *ngIf="
        !userDataSet &&
        scenario.settings.tracking &&
        scenario.settings.tracking.method == 'form' &&
        scenario.settings.tracking.fields
      "
    ></app-user-tracking-form>
    <div
      class="global-content"
      *ngIf="!step?.content?.config?.hideGlobalComponent"
    >
      <app-global-components
        [scenario]="scenario"
        [content]="scenario.layout.content"
        [scale]="scale"
        style="z-index: 99999; position: absolute; top: 0; left: 0"
      ></app-global-components>
    </div>

    <div
      *ngIf="canvasAnimationStyle || canvasOverflow || canvasDimensions"
      class="step-content {{ canvasAnimationClass }}"
      [attr.style]="
        canvasAnimationStyle + '; ' + canvasOverflow + '; ' + canvasDimensions
      "
    >
      <ng-container *ngFor="let component of this.content.components">
        <ng-container *ngIf="component.draggable">
          <div
            id="component-drag-{{ component.id }}"
            class="component-drag"
            cdkDrag
            (cdkDragStarted)="onDragStarted($event, component)"
            (cdkDragMoved)="onDragMove($event, component)"
            (cdkDragEnded)="onDragEnd($event, component)"
            [cdkDragDisabled]="component?.disableDrag"
            style="position:absolute;width:{{ component.width }}px;height:{{
              component.height
            }}px;top:{{ component.pos.y }}px;left:{{
              component.pos.x
            }}px;z-index:{{ component.style.zIndex }};"
            [hidden]="playerService.getComponentHidden(component, content)"
          >
            <app-component-container
              [component]="component"
              [content]="content"
              [scenario]="scenario"
              [disableVideo]="disableVideo"
              [disableAudio]="disableAudio"
              [muted]="muted"
              [step]="step"
              [scale]="scale"
            ></app-component-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!component.draggable">
          <app-component-container
            [scale]="scale"
            [component]="component"
            [content]="content"
            [scenario]="scenario"
            [disableVideo]="disableVideo"
            [disableAudio]="disableAudio"
            [muted]="muted"
            [step]="step"
          ></app-component-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
