import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { DOCUMENT } from '@angular/common';
import { PlayerService } from 'src/app/services/player.service';
import { ScenarioService } from 'src/app/services/scenario.service';
import { PreviewService } from 'src/app/services/preview.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CdkDrag, CdkDragEnd, Point } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit, OnDestroy {
  @Input() content: any;
  contentRef: any;
  @Input() step: any;
  @Input() scenario: any;
  pristineScenario: any;
  @Input() width: any;
  @Input() height: any;
  @Input() options: any;
  @Input() disableAnimations = false;
  @Input() disableVideo = false;
  @Input() disableAudio = false;
  @Input() disableOverflow = false;
  @Input() disableResize: any;
  @Input() disableBodyStyles: any = false;
  canvasAnimationClass?: string;
  canvasAnimationStyle?: string;
  canvasOverflow?: string;
  canvasDimensions: string = 'width:1024px;height:576px';
  activeCountdown: any;
  activeCountdownClock: any = 0;
  userDataSet = false;
  scenarioVars: any;
  @ViewChild('stepContentContainer') stepContentContainer?: ElementRef;
  @ViewChild('ysPlayer', { static: false }) ysPlayer?: ElementRef;

  audioObjs: any = [];
  muted = false;
  audioControls = false;

  elem: any;

  scale: any;
  scenarioStyles: any;
  scenarioUiStyles: any;

  widthContainer: any;

  // subscriptions
  currentStepSub: any | Subscription;
  userDataSetSub: any;
  activeCountdownSub: any;
  activeCountdownClockSub: any;
  mutedSub: any;
  fullscreenSub: any;
  previewResizeSub: any;
  refreshContentSub: any;
  varsSub: any;
  restartedSub: any;
  updateComponentSub: any;
  makeChoiceSub: any;

  animationTimeouts: any;

  componentAtDragStart: any;
  componentsAtDragStart: any = {};
  animsComplete = true;

  constructor(
    public contentService: ContentService,
    private playerSessionService: PlayerSessionService,
    public playerService: PlayerService,
    private scenarioService: ScenarioService,
    private previewService: PreviewService,
    @Inject(DOCUMENT) private document: any,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    /* this.previewService.refreshScenario.subscribe((refreshScenario)=>{
      if(refreshScenario){
        console.log('refreshed?')
        this.scenario = JSON.parse(JSON.stringify(this.scenario));
        this.playerService.setScenario(this.scenario);
        this.playerService.preloadAssets();
      }
    });*/

    this.scenario = JSON.parse(JSON.stringify(this.scenario));
    this.pristineScenario = JSON.parse(JSON.stringify(this.scenario));

    // overflow
    if (this.scenario.settings?.canvas?.overflow) {
      this.canvasOverflow =
        ' overflow:' + this.scenario.settings?.canvas?.overflow;
    }
    if (
      this.scenario.settings?.canvas?.width &&
      this.scenario.settings?.canvas?.height
    ) {
      this.canvasDimensions =
        'width:' +
        this.scenario.settings.canvas.width +
        'px;height:' +
        this.scenario.settings.canvas.height +
        'px;';
    }

    // clone the content so we don't disturb the original with actions..
    this.contentRef = this.content;
    this.content = JSON.parse(JSON.stringify(this.content));

    this.elem = document.documentElement;
    this.playerService.setScenario(this.scenario);
    this.playerService.preloadAssets();
    // load fonts!

    this.playerSessionService.initSession(this.scenario);
    this.playerService.currentStep.next('');

    // do scenario actions?
    if (
      this.scenario &&
      this.scenario.settings &&
      this.scenario.settings.actions
    ) {
      this.scenario.settings.actions.forEach((action: any) => {
        // separate for now? Just to test?
        this.playerService.doAction(
          { type: 'scenario' },
          action,
          null,
          null,
          null
        );
      });
    }

    this.currentStepSub = this.playerService.currentStep.subscribe((stepId) => {
      if (stepId) {
        this.goToStep(stepId);
      }
    });

    this.userDataSetSub = this.playerSessionService.userDataSet.subscribe(
      (userDataSet) => {
        this.userDataSet = userDataSet;
      }
    );

    this.activeCountdownSub =
      this.playerService.activeCountdownActive.subscribe((activeCountdown) => {
        this.activeCountdown = activeCountdown;
      });
    this.activeCountdownClockSub =
      this.playerService.activeCountdownClock.subscribe(
        (activeCountdownClock) => {
          this.activeCountdownClock = activeCountdownClock;
        }
      );

    this.varsSub = this.playerSessionService.variablesChanges.subscribe(
      (changed) => {
        // copy the object so the pipe updates
        this.scenarioVars = JSON.parse(
          JSON.stringify(this.playerSessionService.getVars())
        );
      }
    );
    this.restartedSub = this.playerService.restarted.subscribe((restarted) => {
      if (restarted) {
        this.scenario = JSON.parse(JSON.stringify(this.pristineScenario));
      }
    });

    this.makeChoiceSub = this.playerService.makeChoice.subscribe((choice) => {
      if (choice) {
        this.onChoice(choice);
      }
    });

    this.playerService.currentStepId = this.step.step_id;

    if (this.step) {
      this.playerService.doActions(this.step.actions, 'step', this.step);
      this.step.content.components.forEach((component: any) => {
        if (component?.data?.actions) {
          this.playerService.doActions(
            component.data.actions,
            'component',
            component,
            this.step.content
          );
        }
      });
    }

    this.mutedSub = this.playerService.muted.subscribe((muted) => {
      this.muted = muted;
    });

    this.fullscreenSub = this.playerService.fullscreen.subscribe(
      (fullscreen) => {
        if (fullscreen == true) {
          this.openFullscreen();
        } else {
          this.closeFullscreen();
        }
      }
    );

    // this.scaleViewport(this.width);

    // check for preview resizes?
    this.previewResizeSub = this.previewService.resizeScreen.subscribe(
      (response) => {
        if (response) {
          this.scaleViewport(response);
        }
      }
    );

    if (this.disableAnimations != true) {
      var animationIn = this.content.canvas.animationIn;
      var animationInSpeed = this.content.canvas.animationInSpeed;
      var animationInDelay = this.content.canvas.animationInDelay;
      if (
        !animationIn?.name &&
        this.scenario?.style?.stepAnimation?.animationIn
      ) {
        animationIn = this.scenario.style.stepAnimation.animationIn;
        animationInSpeed = this.scenario.style.stepAnimation.animationInSpeed;
        animationInDelay = this.scenario.style.stepAnimation.animationInDelay;
      }

      // if(animationIn){
      if (typeof animationIn == 'string') {
        this.canvasAnimationClass =
          'animate__animated animate__delay-1s animate__' + animationIn;
        this.canvasAnimationStyle =
          '--animate-duration:' +
          animationInSpeed +
          's;--animate-delay:' +
          animationInDelay +
          's;';
      } else if (animationIn && animationIn.name) {
        this.canvasAnimationClass =
          'animate__animated animate__delay-1s animate__' + animationIn.name;
        this.canvasAnimationStyle =
          '--animate-duration:' +
          animationIn.speed +
          's;--animate-delay:' +
          animationIn.delay +
          's;';
      } else {
        this.canvasAnimationClass =
          'animate__animated animate__delay-1s animate__';
        this.canvasAnimationStyle = '--animate-duration:s;--animate-delay:s;';
      }
      //}
    }
    // do action for this step?
    this.refreshContentSub = this.contentService.refreshContent.subscribe(
      (value) => {
        this.content = JSON.parse(JSON.stringify(this.contentRef));
        this.scaleViewport(this.width);
      }
    );

    // a soundtrack?
    if (this.scenario.settings.soundtrack && !this.disableAudio) {
      let soundtrackLoop = false;
      if (this.scenario.settings.soundtrackLoop) {
        soundtrackLoop = true;
      }
      this.playerService.playAudio(
        this.scenario.settings.soundtrack,
        'soundtrack',
        soundtrackLoop
      );

      if (this.scenario.settings.soundtrackDisableAutoplay) {
        this.playerService.stopAudio('soundtrack');
      }

      this.audioControls = true;
    }

    // actions for step 1?
    // this.playerService.doActions(this.step.actions, 'step', this.step);

    //apply styles to host
    if (!this.disableBodyStyles) {
      this.scenarioStyles = this.scenarioService.getScenarioStyles(
        this.scenario,
        true
      );
      const css =
        ':root{' +
        Object.entries(this.scenarioStyles)
          .map(([k, v]) => `${k}:${v}`)
          .join(';') +
        '}';
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }

    // do the Scenartio UI even if disable body..

    this.scenarioUiStyles = this.scenarioService.getScenarioUiStyles(
      this.scenario
    );
    const css =
      '.scenario-ui-' +
      this.scenario.id +
      '{' +
      Object.entries(this.scenarioUiStyles)
        .map(([k, v]) => `${k}:${v}`)
        .join(';') +
      '}';
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);

    // preview service active?
    /* this.previewService.activeStep.subscribe((activeStep: any) => {
      if (activeStep) {
        this.goToStep(activeStep.step_id, true);
      }
    });*/
    this.scenarioService.appendCustomCss(this.scenario);
  }

  ngAfterViewInit() {
    this.updateComponentSub = this.playerService.updateComponent.subscribe(
      (componentUpdate: any) => {
        if (componentUpdate && componentUpdate.id && componentUpdate.newData) {
          // get the component
          var newData = componentUpdate.newData;

          /*this.scenario.steps.forEach((step: any) => {
        step.content.components.forEach((component: any) => {
          if (component.id == componentUpdate.id) {
            for (const [key, value] of Object.entries(newData.style)) {
              component.style[key] = value;
             }
          }
        });
      });*/
          this.content.components.forEach((component: any) => {
            if (component.id == componentUpdate.id) {
              if (newData.hidden || newData.show) {
                if (newData.hidden) {
                  component.hidden = newData.hidden;

                  // remove the anims here??
                  // refresh the anims
                  if (component.animationIn) {
                    let oldAdmin = { ...component.animationIn };
                    component.animationIn = null;
                    setTimeout(() => {
                      component.animationIn = oldAdmin;
                    }, 10);
                  }

                  if (component.type == 'block') {
                    this.playerService
                      .getBlockComponents(component, this.content)
                      .forEach((_component: any) => {
                        if (_component.animationIn) {
                          let oldAdmin = { ..._component.animationIn };
                          _component.animationIn = null;
                          setTimeout(() => {
                            _component.animationIn = oldAdmin;
                          }, 10);
                        }
                      });
                  }
                }
                if (newData.show) {
                  component.hidden = false;
                }
              } else {
                if (newData.style) {
                  for (let key in newData.style) {
                    let value: any = newData.style[key];
                    switch (key) {
                      default:
                        component.style[key] = value;
                        break;
                    }
                  }
                }
                if (newData.data) {
                  for (const [key, value] of Object.entries(newData.data)) {
                    if (key != 'actions') {
                      component.data[key] = value;
                    }
                  }
                }
                if (newData.draggable != undefined) {
                  component.disableDrag = !newData.draggable;
                }
                if (newData.pos) {
                  component.pos = newData.pos;
                }
                if (newData.icon) {
                  component.icon = newData.icon;
                }
                if (newData.animationIn) {
                  // blank it first?
                  component.animationIn = null;
                  setTimeout(() => {
                    component.animationIn = newData.animationIn;
                  }, 10);
                }
                if (newData.width) {
                  component.width = newData.width;
                }
                if (newData.height) {
                  component.height = newData.height;
                }
              }
            }
          });
        }
      }
    );
    this.onListenToAnimations(this.step.content.components);
  }

  scaleViewport(width: any) {
    if (width == 'window') {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      if (
        this.width / this.scenario.settings.canvas.width <
        this.height / this.scenario.settings.canvas.height
      ) {
        this.scale = this.width / this.scenario.settings.canvas.width;
      } else {
        this.scale = this.height / this.scenario.settings.canvas.height;
      }
    } else if (width == 'container') {
      this.width = this.elRef.nativeElement.parentElement.offsetWidth;
      this.height = this.elRef.nativeElement.parentElement.offsetHeight;

      if (
        this.width / this.scenario.settings.canvas.width <
        this.height / this.scenario.settings.canvas.height
      ) {
        this.scale = this.width / this.scenario.settings.canvas.width;
      } else {
        this.scale = this.height / this.scenario.settings.canvas.height;
      }
    } else {
      this.scale = this.width / this.scenario.settings.canvas.width;
    }
    if(this.scenario.settings?.disableScaleUp && this.scale>1 && document.fullscreenElement == null){
      this.scale = 1;
    }
  }

  onChoice(choice: any) {
    // make it null for animations
    if (choice.choice_id) {
      this.playerSessionService.addToLog('makeChoice', {
        id: choice.choice_id,
        stepId: this.step.step_id,
      });
      // do the choice actions here
      this.playerService.doActions(choice.actions, 'choice', choice);
      if (this.disableAnimations != true) {
        this.canvasAnimationClass = '';
        this.canvasAnimationStyle = '';
      }

      this.step.content.components.forEach((component: any) => {
        if (component.type == 'choices') {
          component.hidden = component.hiddenTillEnd;
        }
        if (component.type == 'pick') {
          component.data.feedback = 'Task completed';
        }
      });

      if (choice.target_step_id) {
        this.playerService.currentStep.next(choice.target_step_id);
      }
    }
  }

  onListenToAnimations(components: any) {
    this.animationTimeouts = [];
    components.forEach((component: any) => {

      // choices global anims
      if (
        component.type == 'choices' &&
        this.scenario.style.choiceAnimation?.animationIn?.name &&
        !component.animationIn?.name
      ) {
        component.animationIn =
          this.scenario.style.choiceAnimation?.animationIn;
      }
      if (
        component.type == 'choices' &&
        this.scenario.style.choiceAnimation?.animationDuring?.name &&
        !component.animationDuring?.name
      ) {
        component.animationDuring =
          this.scenario.style.choiceAnimation?.animationDuring;
      }

      if (component.animationIn && component.animationIn.name) {
        component.animationIn.completed = false;
      }

      let componentElem = document.getElementById(
        'component-ys-' + component.id
      );

      if (componentElem) {
        // add the animation in
        // set the animationIn animation
        if (typeof component.animationIn == 'string') {
          //componentElem?.classList.add('animate__animated');
          //componentElem?.classList.add('animate__delay-1s');
          //// componentElem?.classList.add('animate__' + component.animationIn);
          // componentElem?.style?.setProperty(
          //   '--animate-duration',
          //    component.animationInSpeed + 's'
          //  );
          componentElem?.style?.setProperty(
            '--animate-delay',
            component.animationInDelay + 's'
          );

          componentElem?.style?.setProperty(
            '--animate-repeat',
            component.animationInRepeat
          );
        } else {
          if (component.animationIn && component.animationIn.name) {
            //componentElem?.classList.add('animate__animated');
            //componentElem?.classList.add('animate__delay-1s');
            //componentElem?.classList.add(
            //  'animate__' + component.animationIn.name
            // );
            componentElem?.style?.setProperty(
              '--animate-duration',
              component.animationIn.speed + 's'
            );
            componentElem?.style?.setProperty(
              '--animate-delay',
              component.animationIn.delay + 's'
            );

            componentElem?.style?.setProperty(
              '--animate-repeat',
              component.animationIn.repeat
            );
          }
        }

        componentElem.addEventListener('animationend', () => {
          if (
            component.animationIn &&
            component.animationIn.name &&
            ((component.animationDuring &&
              !componentElem?.classList.contains(
                'animate__' + component.animationDuring.name
              )) ||
              !component.animationDuring)
          ) {
            component.animationIn.completed = true;

            // remove the scenario level choice anim?
            if (
              component.type == 'choices' &&
              this.scenario.style?.choiceAnimation?.animationIn
            ) {
              componentElem?.classList.remove(
                'animate__' +
                  this.scenario.style?.choiceAnimation?.animationIn.name
              );
            }
            if (component.animationIn.name) {
              componentElem?.classList.remove(
                'animate__' + component.animationIn.name
              );
              componentElem?.classList.remove('animate__infinite');

              if (component.type == 'block') {
                let blockComponents = this.playerService.getBlockComponents(
                  component,
                  this.content
                );
                blockComponents.forEach((_component: any) => {
                  var _componentElem = document.getElementById(
                    'block-component-' + _component.id
                  );
                  _componentElem?.classList.remove(
                    'animate__' + component.animationIn.name
                  );
                  _componentElem?.classList.remove('animate__infinite');
                });
              }
            }

            if (component.animationDuring && component.animationDuring.name) {
              componentElem?.classList.add(
                'animate__' + component.animationDuring.name
              );
              componentElem?.style?.setProperty(
                '--animate-duration',
                component.animationDuring.speed + 's'
              );
              componentElem?.style?.setProperty(
                '--animate-delay',
                component.animationDuring.delay + 's'
              );

              componentElem?.style?.setProperty(
                '--animate-repeat',
                component.animationDuring.repeat
              );

              if (component.type == 'block') {
                let blockComponents = this.playerService.getBlockComponents(
                  component,
                  this.content
                );
                blockComponents.forEach((_component: any) => {
                  var _componentElem = document.getElementById(
                    'block-component-' + _component.id
                  );
                  _componentElem?.classList.add(
                    'animate__' + component.animationDuring.name
                  );
                  _componentElem?.style?.setProperty(
                    '--animate-duration',
                    component.animationDuring.speed + 's'
                  );
                  _componentElem?.style?.setProperty(
                    '--animate-delay',
                    component.animationDuring.delay + 's'
                  );

                  _componentElem?.style?.setProperty(
                    '--animate-repeat',
                    component.animationDuring.repeat
                  );
                });
              }
            }
          }
        });

        if (component.animationOut && component.animationOut.name) {
          var actionTimeout = setTimeout(() => {
            componentElem?.classList.add(
              'animate__' + component.animationOut.name
            );
            componentElem?.style?.setProperty(
              '--animate-duration',
              component.animationOut.speed + 's'
            );

            componentElem?.style?.setProperty(
              '--animate-repeat',
              component.animationOut.repeat
            );
          }, component.animationOut.delay * 1000);

          this.animationTimeouts.push(actionTimeout);

          if (component.type == 'block') {
            let blockComponents = this.playerService.getBlockComponents(
              component,
              this.content
            );
            blockComponents.forEach((_component: any) => {
              var _componentElem = document.getElementById(
                'block-component-' + _component.id
              );
              var actionTimeout = setTimeout(() => {
                _componentElem?.classList.add(
                  'animate__' + component.animationOut.name
                );
                _componentElem?.style?.setProperty(
                  '--animate-duration',
                  component.animationOut.speed + 's'
                );

                _componentElem?.style?.setProperty(
                  '--animate-repeat',
                  component.animationOut.repeat
                );
              }, component.animationOut.delay * 1000);

              this.animationTimeouts.push(actionTimeout);
            });
          }
        }
      }
    });
  }
  setComponentAnimations(components: any) {}

  goToStep(stepId: string, skipActions = false) {
    this.animsComplete = false;
    // console.log(this.scenario.steps);
    if (this.getStep(stepId)) {
      let stepTitle = '';
      if (!this.getStep(stepId)) {
        console.log(
          'Step ' + stepId + ' not found for scenario ' + this.scenario.title
        );
      }
      if (this.getStep(stepId) && this.getStep(stepId).title) {
        stepTitle = this.getStep(stepId).title;
      }
      this.playerSessionService.addToLog('goToStep', {
        id: stepId,
        title: stepTitle,
      });
      this.playerService.stopAudio('step');
      this.playerService.stepChange.next(stepId);
      this.step = this.getStep(stepId);
      if (!skipActions && this.step.actions) {
        // console.log('Gotostepaction')
        this.playerService.doActions(this.step.actions, 'step', this.step);
      }

      // component actions?
      if (!skipActions) {
        this.step.content.components.forEach((component: any) => {
          if (component?.data?.actions) {
            this.playerService.doActions(
              component.data.actions,
              'component',
              component,
              this.step.content
            );
          }
        });
      }
      this.content = this.step.content;
      this.playerService.currentStepId = stepId;
      this.playerSessionService.sessionData.route.push(stepId);
      this.playerSessionService.sessionData.history.push(stepId);

      // skip anims complete if there are none
      if (
        !this.content?.canvas?.animationIn?.name &&
        !this.scenario?.style?.stepAnimation?.animationIn?.name
      ) {
        this.animsComplete = true;
      }
      if (this.disableAnimations != true) {
        this.canvasAnimationClass = '';
        setTimeout(() => {
          this.setComponentAnimations(this.step.content.components);
          this.onListenToAnimations(this.step.content.components);

          var animationIn = this.content.canvas.animationIn;
          var animationInSpeed = this.content.canvas.animationInSpeed;
          var animationInDelay = this.content.canvas.animationInDelay;
          if (
            !animationIn?.name &&
            this.scenario?.style?.stepAnimation?.animationIn
          ) {
            animationIn = this.scenario.style.stepAnimation.animationIn;
            animationInSpeed =
              this.scenario.style.stepAnimation.animationInSpeed;
            animationInDelay =
              this.scenario.style.stepAnimation.animationInDelay;
          }

          if (typeof animationIn == 'string') {
            this.canvasAnimationClass =
              'animate__animated animate__delay-1s animate__' + animationIn;
            this.canvasAnimationStyle =
              '--animate-duration:' +
              animationInSpeed +
              's;--animate-delay:' +
              animationInDelay +
              's;';
          } else if (animationIn && animationIn.name) {
            this.canvasAnimationClass =
              'animate__animated animate__delay-1s animate__' +
              animationIn.name;
            this.canvasAnimationStyle =
              '--animate-duration:' +
              animationIn.speed +
              's;--animate-delay:' +
              animationIn.delay +
              's;';
          } else {
            this.canvasAnimationClass =
              'animate__animated animate__delay-1s animate__';
            this.canvasAnimationStyle =
              '--animate-duration:s;--animate-delay:s;';
          }
          this.animsComplete = true;
        }, 5);
      } else {
        this.animsComplete = true;
      }
    }
  }

  getStep(stepId: string) {
    let _step: any;
    this.scenario.steps.forEach((step: any) => {
      if (step.step_id == stepId) {
        _step = step;
      }
    });
    return _step;
  }

  getComponents() {
    return this.content.components;
  }

  /*
  playAudio(audio: string, audioType: string) {
    let audioObj = new Audio(audio);
    let volume;
    if (this.muted == true) {
      volume = 0;
    } else {
      volume = 1;
    }
    audioObj.volume = volume;

    audioObj.className = 'scAudio';
    audioObj.play();
    var audioData = {
      obj: audioObj,
      type: audioType,
    };
    this.audioObjs.push(audioData);
  }*/
  /*
  stopAudio(audioType: string) {
    this.audioObjs.forEach((_audio: any) => {
      if (_audio.type == audioType || audioType == 'all') {
        _audio.obj.pause();
      }
    });
  }*/
  onMute() {
    this.playerService.muted.next(true);
  }
  onUnMute() {
    this.playerService.muted.next(false);
  }

  ngOnDestroy(): void {
    this.currentStepSub?.unsubscribe();

    this.userDataSetSub?.unsubscribe();
    this.activeCountdownSub?.unsubscribe();
    this.activeCountdownClockSub?.unsubscribe();
    this.mutedSub?.unsubscribe();
    this.fullscreenSub?.unsubscribe();
    this.previewResizeSub?.unsubscribe();
    this.refreshContentSub?.unsubscribe();
    this.varsSub?.unsubscribe();
    this.restartedSub?.unsubscribe();
    this.makeChoiceSub?.unsubscribe();
    this.playerService.updateComponent.next({});
    this.updateComponentSub?.unsubscribe();

    this.closeFullscreen();
    this.audioObjs.forEach((_audio: any) => {
      _audio.obj.pause();
    });
    this.playerService.stopAudio('all');
    this.playerService.removeAudio();
    this.scenarioService.unloadRootStyles();
    this.playerService.clearStepIntervals();
    this.playerService.clearStepTimeouts();
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (document.fullscreenElement !== null) {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (!this.disableResize) {
      this.calculateScale();
    }
  }

  @HostListener('window:fullscreenchange', ['$event'])
  onResizeFullscreen(event: any) {
    this.scaleViewport('container');
  }

  calculateScale() {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (this.scenario.settings.canvas) {
      if (
        width / this.scenario.settings.canvas.width <
        height / this.scenario.settings.canvas.height
      ) {
        this.scale = width / this.scenario.settings.canvas.width;
      } else {
        this.scale = height / this.scenario.settings.canvas.height;
      }
    }
    if(this.scenario.settings?.disableScaleUp && this.scale>1){
      this.scale = 1;
    }
  }

  getVars() {
    return this.playerSessionService.getVars();
  }

  // any keypresses
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: any) {
    let elementName = event?.target?.nodeName?.toLowerCase();
    let elementClass = event?.target?.className?.toLowerCase();

    if (
      elementName != 'input' &&
      elementName != 'textarea' &&
      !elementClass.includes('ql-editor')
    ) {
      // default controls
      if (
        this.scenario &&
        this.scenario.settings &&
        this.scenario.settings.enableKeyboardControls
      ) {
        switch (event.key) {
          case ' ':
            this.playerService.goToFirstChoice();
            break;
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
            this.playerService.goToNthChoice(+event.key - 1);
            break;
          case 'h':
            this.playerService.onShowHelp();
            break;
          case 'i':
            this.playerService.onShowInventory();
            break;
        }
      }

      if (
        this.scenario &&
        this.scenario.settings &&
        this.scenario.settings.actions
      ) {
        this.scenario.settings.actions.forEach((action: any) => {
          // separate for now? Just to test?
          let hasKeyPress = false;

          action.events.forEach((event: any, eIndex: number) => {
            if (event.id == 'onKeyPress') {
              hasKeyPress = true;
            }
          });

          if (hasKeyPress) {
            this.playerService.doAction(
              { type: 'scenario' },
              action,
              null,
              'keyPress',
              { key: event.key }
            );
          }
        });
      }
    }
  }

  /*
  dragEnded($event: CdkDragEnd, component: any) {
    const elementMoving = $event.source.getRootElement();
    const elementMovingRect: ClientRect = elementMoving.getBoundingClientRect();

    let div = document.getElementById('component-drag-' + component.id);
    let containerDiv = document.getElementsByClassName('step-content-container')[0];
    if (div && containerDiv) {
      let xpos =
        (elementMovingRect.left -
          containerDiv.getBoundingClientRect().left) /
        this.scale;
      div.style.left = xpos + 'px';
      let ypos =
        (elementMovingRect.top -
          containerDiv.getBoundingClientRect().top) /
        this.scale;
      div.style.top = ypos + 'px';

      const cdkDrag = $event.source as CdkDrag;
      cdkDrag.reset();
    }

    // dropped
    this.step.content.components.forEach((_component:any)=>{
      if(_component.id != component.id){
        if(this.elemCollide(document.getElementById('component-ys-' + component.id), document.getElementById('component-ys-' + _component.id))){
          console.log('collide with ' + _component.id);
        }
        else{
        }
      }
    });
    
  }
  onEnter(event: any) {
    console.log(event);
  }
  dragConstrainPoint(point: any, dragRef: any) {
    let zoomMoveXDifference = 0;
    let zoomMoveYDifference = 0;
    if (this.scale! - 1) {
      zoomMoveXDifference = (1 - this.scale) * dragRef.getFreeDragPosition().x;
      zoomMoveYDifference = (1 - this.scale) * dragRef.getFreeDragPosition().y;
    }
    return {
      x: point.x - dragRef._pickupPositionInElement.x + zoomMoveXDifference,
      y: point.y - dragRef._pickupPositionInElement.y + zoomMoveYDifference,
    };
  }

  elemCollide(el1: any, el2: any) {
    var rect1 = el1.getBoundingClientRect();
    var rect2 = el2.getBoundingClientRect();

    console.log(rect1);
    console.log(rect2);

    return !(rect1.right < rect2.left || 
      rect1.left > rect2.right || 
      rect1.bottom < rect2.top || 
      rect1.top > rect2.bottom);
  }

  elemInside(el1: any, el2: any) {
    var rect1 = el1.getBoundingClientRect();
    var rect2 = el2.getBoundingClientRect();

    return (
      rect2.top <= rect1.top &&
      rect1.top <= rect2.bottom &&
      rect2.top <= rect1.bottom &&
      rect1.bottom <= rect2.bottom &&
      rect2.left <= rect1.left &&
      rect1.left <= rect2.right &&
      rect2.left <= rect1.right &&
      rect1.right <= rect2.right
    );
  }*/

  clearAnimationTimeouts() {
    // clear any timesouts
    for (let i = 0; i < this.animationTimeouts.length; i++) {
      clearTimeout(this.animationTimeouts[i]);
    }
    this.animationTimeouts = [];
  }

  dragConstrainPoint(
    point: any,
    dragRef: any,
    dimensions: DOMRect,
    pickupPositionInElement: Point
  ) {
    let zoomMoveXDifference = 0;
    let zoomMoveYDifference = 0;

    let pos = JSON.parse(JSON.stringify(dragRef.getFreeDragPosition()));
    zoomMoveXDifference = pos.x - pos.x * this.scale;
    zoomMoveYDifference = pos.y - pos.y * this.scale;

    let newX = point.x - pickupPositionInElement.x + zoomMoveXDifference;
    let newY = point.y - pickupPositionInElement.y + zoomMoveYDifference;

    return {
      x: point.x,
      y: point.y,
    };
  }

  onDragStarted($event: any, component: any) {
    this.componentAtDragStart = JSON.parse(JSON.stringify(component));
    let componentCopy = JSON.parse(JSON.stringify(component));
    component.startPos = {
      x: componentCopy.pos.x,
      y: componentCopy.pos.y,
    };
    if (component.type == 'block') {
      let components = this.playerService.getBlockComponents(
        component,
        this.content
      );
      components.forEach((_component: any) => {
        this.componentsAtDragStart[_component.id] = JSON.parse(
          JSON.stringify(_component)
        );
      });
    }
  }

  onDragMove($event: any, component: any) {
    if (this.componentAtDragStart) {
      component.pos.x =
        this.componentAtDragStart.pos.x + $event?.distance?.x / this.scale;
      component.pos.y =
        this.componentAtDragStart.pos.y + $event?.distance?.y / this.scale;
    }
    // is this a block?
    if (component.type == 'block') {
      let components = this.playerService.getBlockComponents(
        component,
        this.content
      );
      components.forEach((_component: any) => {
        _component.pos.x =
          this.componentsAtDragStart[_component.id].pos.x +
          $event?.distance?.x / this.scale;
        _component.pos.y =
          this.componentsAtDragStart[_component.id].pos.y +
          $event?.distance?.y / this.scale;
      });
    }
  }

  onDragEnd($event: any, component: any) {
    // set it and reset the drag?
    let elem = document.getElementById('component-drag-' + component.id);
    let playerElem = document.getElementById('step-content-container');
    if (elem && playerElem) {
      let rect = elem.getBoundingClientRect();
      let playerRect = playerElem.getBoundingClientRect();
      component.pos.x = (rect.left - playerRect.left) / this.scale;
      component.pos.y = (rect.top - playerRect.top) / this.scale;
      $event.source._dragRef.setFreeDragPosition({
        x: component.pos.x,
        y: component.pos.y,
      });
      $event.source._dragRef.reset();
    }
    // do the actions
    this.playerService.onComponentDropped(component, this.content);
  }
}
