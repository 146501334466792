import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataEncryptionService {

  constructor(private http: HttpClient) { }


  generateEncryptedData(published_id:number, subject:string): Observable<any> {
    const payload = {
      published_id,
      subject,
      action:'encrypt'
    };
    return this.http.post<any>(environment.apiUrl + '/user_data_encryption', payload, {
      observe: 'response',
    }).pipe(
      catchError(this.handleError),
      map((resData:any) => {
        return resData.body.output;
      })
    );
  }


  decryptData(published_id:number, subject:string): Observable<any> {
    const payload = {
      published_id,
      subject,
      action:'decrypt'
    };
    return this.http.post<any>(environment.apiUrl + '/user_data_encryption', payload, {
      observe: 'response',
    }).pipe(
      catchError(this.handleError),
      map((resData:any) => {
        return resData.body.output;
      })
    );
  }




  private handleError(errorRes: HttpErrorResponse) {
    // console.log(errorRes);
    let errorMessage =
      'An unknown error occurred.  If the problem persists please contact us.';
    if (!errorRes.statusText) {
      return throwError(errorMessage);
    }
    console.log(errorRes);

    switch (errorRes.statusText) {
      case 'INVALID_CODE':
        errorMessage = 'The code provided was incorrect';
        break;
      case 'Unprocessable Entity':
        errorMessage = 'The data could not be processed.';
        break;
    }
    return throwError(errorMessage);
  }
}
