<div class="chat-container chat-messenger" style="--animate-duration: 0.25s">
  <div class="task-obscure" *ngIf="completed && component.data.obscure"></div>
  <!-- chatbox -->
  <div
    class="chatbox"
    [attr.id]="
      mode == 'player'
        ? 'chat-box-' + component.id
        : 'chat-box-editor-' + component.id
    "
  >
    <div class="modal-dialog-scrollable">
      <div class="modal-content">
        <div
          class="msg-head"
          [attr.id]="
            mode == 'player'
              ? 'chat-head-' + component.id
              : 'chat-head-editor-' + component.id
          "
        >
          <div class="row">
            <div class="col-8">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img
                    class="img-fluid avatar"
                    src="{{ component.data.avatar }}"
                    *ngIf="component.data.avatar"
                    alt="user img"
                    style="width:{{ component.data.avatarWidth }}px;height:{{
                      component.data.avatarWidth
                    }}px;"
                  />
                </div>
                <div class="flex-grow-1 ms-3">
                  <div class="avatar-name" *ngIf="component.data?.avatarName">
                    {{ component.data.avatarName }}
                  </div>
                  <p *ngIf="component.data?.avatarRole">
                    {{ component.data.avatarRole }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4"></div>
          </div>
        </div>

        <div
          class="modal-body"
          [attr.id]="
            mode == 'player'
              ? 'chat-body-' + component.id
              : 'chat-body-editor-' + component.id
          "
        >
          <div class="msg-body" style="opacity:{{ showChoices ? '0.5' : 1 }};">
            <ul>
              <li class="sender">
                <p>{{ component.data?.opening }}</p>
                <span class="time">{{ startTime | date : "hh:mm a" }}</span>
              </li>

              <ng-container *ngFor="let chat of chatLog">
                <li class="reply">
                  <p>{{ chat.q }}</p>
                  <span class="time">{{ chat.time | date : "hh:mm a" }}</span>
                </li>
                <li class="sender">
                  <p *ngIf="isTyping != chat.uuid">{{ chat.a }}</p>
                  <p *ngIf="isTyping == chat.uuid">
                    <span class="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </p>
                  <span class="time" *ngIf="isTyping != chat.uuid">{{
                    chat.time | date : "hh:mm a"
                  }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div
          *ngIf="!completed"
          class="send-box"
          [attr.id]="
            mode == 'player'
              ? 'chat-foot-' + component.id
              : 'chat-foot-editor-' + component.id
          "
        >
          <form action="">
            <div class="position-relative">
              <input
                type="text"
                class="form-control position-relative"
                aria-label="message…"
                placeholder="Write message…"
                (click)="showChoices = true"
                (focus)="showChoices = true"
              />
              <div
                class="msg-body chat-choices"
                *ngIf="showChoices && !completed"
              >
                <ul>
                  <li class="reply" *ngFor="let stem of activeStems">
                    <a (click)="onChooseStem(stem)" role="button" tabindex="0">
                      <p>{{ stem.q }}</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-ys"
              (click)="onEndChat($event)"
            >
              <i
                class="bx {{ component.style.endChatIcon }} me-1"
                *ngIf="component.style.endChatIcon"
              ></i>
              <ng-container *ngIf="!component.data.endLabel"
                >End Chat</ng-container
              >
              <ng-container *ngIf="component.data.endLabel">{{
                component.data.endLabel
              }}</ng-container>
            </button>
          </form>
        </div>

        <!-- retake -->
        <div
          *ngIf="
            completed && (component.data.allowRetake || this.mode == 'editor')
          "
          class="send-box"
          [attr.id]="
            mode == 'player'
              ? 'chat-foot-' + component.id
              : 'chat-foot-editor-' + component.id
          "
        >
          <form action="">
            <div class="position-relative"></div>
            <button
              type="button"
              class="btn btn-ys btn-end-chat"
              (click)="onRetakeChat()"
            >
              <i
                class="bx {{ component.style.retakeChatIcon }} me-1"
                *ngIf="component.style.retakeChatIcon"
              ></i>
              <ng-container *ngIf="!component.data.retakeLabel"
                >Retake</ng-container
              >
              <ng-container *ngIf="component.data.retakeLabel">{{
                component.data.retakeLabel
              }}</ng-container>
            </button>
          </form>
        </div>
        <!-- end retake -->
      </div>
    </div>
  </div>
  <!-- chatbox -->
</div>
