import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  publishReplay,
  refCount,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Scenario } from '../models/scenario.model';
import * as WebFont from 'webfontloader';
import { nanoid } from 'nanoid';

@Injectable({
  providedIn: 'root',
})
export class ScenarioService {
  data = new BehaviorSubject(null);
  saved = new BehaviorSubject<any>(null);
  slug = '/scenarios';

  allScenarios?: Observable<any[]> | null = null;

  constructor(private http: HttpClient) {}
  setData(data: any) {
    this.saved.next(false);
    // are the defaults set?
    if (
      data.layout.content.components &&
      data.layout.content.components.length < 2
    ) {
      this.initScenarioLayoutDefaults(data);
    }
    this.data.next(data);
  }

  fetchAll(): Observable<any[]> {
    if (!this.allScenarios) {
      this.allScenarios = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData._embedded.scenarios.forEach((item: any) => {
              // Monkey patch!
              let scenarioData = JSON.parse(item.scenario_data);

              if (scenarioData && scenarioData.id != item.scenario_id) {
                scenarioData.id = item.scenario_id;
              }

              (item.scenario_data = scenarioData), returnArray.push(item);
            });
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allScenarios;
  }

  fetchLegacy(): Observable<any[]> {
    return this.http
      .get<any>(environment.apiUrl + '/legacy_scenarios', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.legacy_scenarios;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchLegacyJson(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/legacy_scenarios/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.allScenarios = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          // do it here?
          // Monkeypatch fix scenario id error on copied scenarios..

          let scenarioData = JSON.parse(responseData.scenario_data);
          if (scenarioData.id != id) {
            console.log('patching id...');
            scenarioData.id = id;
          }

          const item = new Scenario(
            +responseData.scenario_id,
            +responseData.user_id,
            responseData.title,
            scenarioData,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(title: string, scenario_data: string) {
    this.clearCache();
    const payload = {
      title,
      scenario_data,
    };
    return this.http.post<Scenario>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(id: number, title: string, scenario_data: string) {
    this.clearCache();
    const payload = { title, scenario_data };
    this.saved.next(true);
    return this.http.patch<Scenario>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  archive(id: number) {
    return this.http.patch<Scenario>(
      environment.apiUrl + this.slug + '/' + id,
      {
        status: 2,
      },
      {
        observe: 'response',
      }
    );
  }

  unarchive(id: number) {
    return this.http.patch<Scenario>(
      environment.apiUrl + this.slug + '/' + id,
      {
        status: 1,
      },
      {
        observe: 'response',
      }
    );
  }

  updateTitle(id: number, title: string) {
    return this.http.patch<Scenario>(
      environment.apiUrl + this.slug + '/' + id,
      {
        title,
      },
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  unloadRootStyles() {
    document.documentElement.removeAttribute('style');
  }
  getScenarioStyles(scenario: any, setRootStyles?: any) {
    let style: any;
    style = {};
    if (scenario.style) {
      style['--ys-color'] = scenario.style.textColor;
      style['--ys-primary'] = scenario.style.primaryColor;
      style['--ys-bg'] = scenario.style.bgColor;
      style['--ys-choice-bg'] = scenario.style.choiceBg;
      style['--ys-choice-text'] = scenario.style.choiceColor;
      style['--ys-choice-border'] = scenario.style.choiceBorder;

      style['--ys-font-base'] = scenario.style.font;
      style['--ys-font-heading'] = scenario.style.fontHeading;
      style['--ys-font-sans'] = scenario.style.fontSans;
      style['--ys-font-serif'] = scenario.style.fontSerif;
      style['--ys-font-mono'] = scenario.style.fontMono;
      style['--ys-font-script'] = scenario.style.fontScript;

      // set the root vars too
      // This is problematic...
      if (setRootStyles) {
        for (let key in style) {
          let value = style[key];
          document.documentElement.style.setProperty(key, style[key]);
        }
      }

      if (scenario.style.stepAnimation) {
        if (scenario.style.stepAnimation.animationIn) {
          if (typeof scenario.style.stepAnimation.animationIn == 'string') {
            style['--ys-step-animation'] =
              scenario.style.stepAnimation.animationIn;
            style['--ys-step-animation-speed'] =
              scenario.style.stepAnimation.animationInSpeed;
            style['--ys-step-animation-delay'] =
              scenario.style.stepAnimation.animationInDelay;
          } else {
            style['--ys-step-animation'] =
              scenario.style.stepAnimation.animationIn.name;
            style['--ys-step-animation-speed'] =
              scenario.style.stepAnimation.animationIn.speed;
            style['--ys-step-animation-delay'] =
              scenario.style.stepAnimation.animationIn.delay;
          }
        }
      }

      style['--ys-component-fill'] = scenario.style.textColor;

      style['--ys-component-radius'] = scenario.style.radius;
      style['--ys-component-padding'] = scenario.style.padding;

      if (scenario.style.componentAnimation) {
        if (scenario.style.componentAnimation.animationIn) {
          if (
            typeof scenario.style.componentAnimation.animationIn == 'string'
          ) {
            style['--ys-component-animation'] =
              scenario.style.componentAnimation.animationIn;
            style['--ys-component-animation-speed'] =
              scenario.style.componentAnimation.animationInSpeed;
            style['--ys-component-animation-delay'] =
              scenario.style.componentAnimation.animationInDelay;
          } else {
            style['--ys-component-animation'] =
              scenario.style.componentAnimation.animationIn.name;
            style['--ys-component-animation-speed'] =
              scenario.style.componentAnimation.animationIn.speed;
            style['--ys-component-animation-delay'] =
              scenario.style.componentAnimation.animationIn.delay;
          }
        }
      }

      // choices
      if (scenario.style.choiceMargin) {
        style['--ys-component-choice-margin'] =
          scenario.style.choiceMargin + 'px';
      }
      if (scenario.style.choicePadding != undefined) {
        // is it split notation?
        let padding = '0';
        if (String(scenario.style.choicePadding).indexOf(' ') >= 0) {
          padding = scenario.style.choicePadding.replaceAll(' ', 'px ') + 'px';
        } else {
          padding = scenario.style.choicePadding + 'px';
        }
        style['--ys-component-choice-padding'] = padding;
      }

      if (scenario.style.choiceFontSize) {
        style['--ys-component-choice-font-scale'] =
          scenario.style.choiceFontSize + '%';
      }
      if (scenario.style.choiceFontWeight) {
        style['--ys-component-choice-font-weight'] =
          scenario.style.choiceFontWeight;
      }
      if (scenario.style.choiceLineHeight) {
        style['--ys-component-choice-line-height'] =
          scenario.style.choiceLineHeight + '%';
      }
      if (scenario.style.choiceLetterSpacing) {
        style['--ys-component-choice-letter-spacing'] =
          (scenario.style.choiceLetterSpacing - 100) / 100 + 'em';
      }
      if (scenario.style.choiceColor) {
        style['--ys-component-choice-color'] = scenario.style.choiceColor;
      }

      if (scenario.style.choiceBg) {
        style['--ys-component-choice-bg'] = scenario.style.choiceBg;
      } else {
        style['--ys-component-choice-bg'] = scenario.style.primaryColor;
      }
      if (scenario.style.choiceBorder) {
        style['--ys-component-choice-border'] =
          scenario.style.choiceBorder.width +
          'px ' +
          scenario.style.choiceBorder.style +
          ' ' +
          scenario.style.choiceBorder.color;
      }

      // choice container
      if (scenario.style.choiceContainerPadding) {
        style['--ys-component-choice-container-padding'] =
          scenario.style.choiceContainerPadding + 'px';
      }
      if (scenario.style.choiceContainerBg) {
        style['--ys-component-choice-container-bg'] =
          scenario.style.choiceContainerBg;
      }
      if (scenario.style.choiceContainerRadius) {
        style['--ys-component-choice-container-radius'] =
          scenario.style.choiceContainerRadius + 'px';
      }
      if (scenario.style.choiceContainerOpacity) {
        style['--ys-component-choice-container-opacity'] =
          scenario.style.choiceContainerOpacity;
        +'%';
      }
      if (scenario.style.choiceContainerBlur) {
        style['--ys-component-choice-container-blur'] =
          scenario.style.choiceContainerBlur + 'px';
      }

      if (scenario.style.choiceHoverColor) {
        style['--ys-component-choice-hover-color'] =
          scenario.style.choiceHoverColor;
      }

      if (scenario.style.choiceHoverBg) {
        style['--ys-component-choice-hover-bg'] = scenario.style.choiceHoverBg;
      }
      if (scenario.style.choiceHoverBorder) {
        style['--ys-component-choice-hover-border'] =
          scenario.style.choiceHoverBorder.width +
          'px ' +
          scenario.style.choiceHoverBorder.style +
          ' ' +
          scenario.style.choiceHoverBorder.color;
      }

      if (scenario.style.choiceRadius || scenario.style.choiceRadius == 0) {
        style['--ys-component-choice-radius'] =
          scenario.style.choiceRadius + 'px';
      }
      if (scenario.style.choiceOpacity) {
        style['--ys-component-choice-opacity'] =
          scenario.style.choiceOpacity + '%';
      }
      if (scenario.style.choiceBlur) {
        style['--ys-component-choice-blur'] = scenario.style.choiceBlur + 'px';
      }

      if (scenario.style.choiceflexDirection) {
        style['--ys-component-flex-direction'] =
          scenario.style.choiceflexDirection;
      }
      if (scenario.style.choiceflexAlign) {
        style['--ys-component-flex-align'] = scenario.style.choiceflexAlign;
      }
      if (scenario.style.choiceflexJustify) {
        style['--ys-component-flex-justify'] = scenario.style.choiceflexJustify;
        if (scenario.style.choiceflexAlign == 'stretch') {
          //style['flex-grow'] = 1;
        }
      }

      //shadow
      if (scenario.style.choiceShadow && scenario.style.choiceShadow.offsetX) {
        style['--ys-component-choice-shadow'] =
          scenario.style.choiceShadow.offsetX +
          'px ' +
          scenario.style.choiceShadow.offsetY +
          'px ' +
          scenario.style.choiceShadow.blur +
          'px ' +
          scenario.style.choiceShadow.spread +
          'px ' +
          scenario.style.choiceShadow.color;
      }
      //text shadow
      if (
        scenario.style.choiceTextShadow &&
        scenario.style.choiceTextShadow.offsetX
      ) {
        style['--ys-component-choice-text-shadow'] =
          scenario.style.choiceTextShadow.offsetX +
          'px ' +
          scenario.style.choiceTextShadow.offsetY +
          'px ' +
          scenario.style.choiceTextShadow.blur +
          'px ' +
          scenario.style.choiceTextShadow.color;
      }
      if (scenario.style.textAlign) {
        style['--ys-component-text-align'] = scenario.style.textAlign;
      }

      // fonts...
      if (scenario.style.choiceFont) {
        switch (scenario.style.choiceFont) {
          case 'body':
            style['--ys-component-choice-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-component-choice-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-component-choice-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-component-choice-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-component-choice-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-component-choice-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-component-choice-font'] =
              scenario.style.choiceFontFamily;
            break;
        }
      }

      // buttons

      // fonts...
      if (scenario.style.buttonFont) {
        switch (scenario.style.buttonFont) {
          case 'body':
            style['--ys-component-button-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-component-button-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-component-button-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-component-button-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-component-button-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-component-button-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-component-button-font'] =
              scenario.style.buttonFontFamily;
            break;
        }
      }

      if (scenario.style.buttonPadding) {
        if (scenario.style.buttonPadding != undefined) {
          // is it split notation?
          let padding = '0';
          if (String(scenario.style.buttonPadding).indexOf(' ') >= 0) {
            padding =
              scenario.style.buttonPadding.replaceAll(' ', 'px ') + 'px';
          } else {
            padding = scenario.style.buttonPadding + 'px';
          }
          style['--ys-component-button-padding'] = padding;
        }
      }

      if (scenario.style.buttonRadius || scenario.style.buttonRadius == 0) {
        style['--ys-component-button-radius'] =
          scenario.style.buttonRadius + 'px';
      }
      if (scenario.style.textAlign) {
        style['--ys-component-button-align'] = scenario.style.textAlign;
      }

      if (scenario.style.buttonFontSize) {
        style['--ys-component-button-font-size'] =
          scenario.style.buttonFontSize + '%';
      }

      if (scenario.style.buttonLetterSpacing) {
        style['--ys-component-button-letter-spacing'] =
          (scenario.style.buttonLetterSpacing - 100) / 100 + 'em';
      }

      if (scenario.style.buttonLineHeight) {
        style['--ys-component-button-line-height'] =
          scenario.style.buttonLineHeight + '%';
      }

      if (scenario.style.buttonBg) {
        style['--ys-component-button-bg'] = scenario.style.buttonBg;
      } else {
        style['--ys-component-button-bg'] = scenario.style.primaryColor;
      }

      if (scenario.style.buttonBorder) {
        style['--ys-component-button-border'] =
          scenario.style.buttonBorder.width +
          'px ' +
          scenario.style.buttonBorder.style +
          ' ' +
          scenario.style.buttonBorder.color;
      }

      if (scenario.style.buttonColor) {
        style['--ys-component-button-color'] = scenario.style.buttonColor;
      }
      if (scenario.style.buttonHoverBg) {
        style['--ys-component-button-hover-bg'] = scenario.style.buttonHoverBg;
      }
      if (scenario.style.buttonHoverBorder) {
        style['--ys-component-button-hover-border'] =
          scenario.style.buttonHoverBorder.width +
          'px ' +
          scenario.style.buttonHoverBorder.style +
          ' ' +
          scenario.style.buttonHoverBorder.color;
      }
      if (scenario.style.buttonHoverColor) {
        style['--ys-component-button-hover-color'] =
          scenario.style.buttonHoverColor;
      }
      //shadow
      if (scenario.style.buttonShadow && scenario.style.buttonShadow.offsetX) {
        style['--ys-component-button-shadow'] =
          scenario.style.buttonShadow.offsetX +
          'px ' +
          scenario.style.buttonShadow.offsetY +
          'px ' +
          scenario.style.buttonShadow.blur +
          'px ' +
          scenario.style.buttonShadow.spread +
          'px ' +
          scenario.style.buttonShadow.color;
      }
      //text shadow
      if (
        scenario.style.buttonTextShadow &&
        scenario.style.buttonTextShadow.offsetX
      ) {
        style['--ys-component-button-text-shadow'] =
          scenario.style.buttonTextShadow.offsetX +
          'px ' +
          scenario.style.buttonTextShadow.offsetY +
          'px ' +
          scenario.style.buttonTextShadow.blur +
          'px ' +
          scenario.style.buttonTextShadow.color;
      }

      // forms
      if (scenario.style.formLabelColor) {
        style['--ys-label-color'] = scenario.style.formLabelColor;
      }

      // fonts...
      if (scenario.style.fontLabelFont) {
        switch (scenario.style.fontLabelFont) {
          case 'body':
            style['--ys-label-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-label-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-label-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-label-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-label-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-label-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-label-font'] = scenario.style.fontLabelFamily;
            break;
        }
      }

      if (scenario.style.inputColor) {
        style['--ys-input-color'] = scenario.style.inputColor;
      }
      if (scenario.style.inputBg) {
        style['--ys-input-bg'] = scenario.style.inputBg;
      }
      if (scenario.style.formLabelColor) {
        style['--ys-label-color'] = scenario.style.formLabelColor;
      }

      if (scenario.style.inputPlaceholder) {
        style['--ys-input-placeholder'] = scenario.style.inputPlaceholder;
      }
      if (scenario.style.inputFocus) {
        style['--ys-input-focus'] = scenario.style.inputFocus;
      }

      // fonts...
      if (scenario.style.fontInputFont) {
        switch (scenario.style.fontInputFont) {
          case 'body':
            style['--ys-input-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-input-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-input-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-input-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-input-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-input-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-input-font'] = scenario.style.fontFamilyInput;
            break;
        }
      }

      // pick tasks
      if (scenario.style.itemTextColor) {
        style['--ys-component-pick-item-text-color'] =
          scenario.style.itemTextColor;
      }
      if (scenario.style.itemBgColor) {
        style['--ys-component-pick-item-bg-color'] = scenario.style.itemBgColor;
      }
      if (scenario.style.itemHoverTextColor) {
        style['--ys-component-pick-item-hover-text-color'] =
          scenario.style.itemHoverTextColor;
      }
      if (scenario.style.itemHoverBg) {
        style['--ys-component-pick-item-hover-bg-color'] =
          scenario.style.itemHoverBg;
      }
      if (scenario.style.itemPickedTextColor) {
        style['--ys-component-pick-item-picked-text-color'] =
          scenario.style.itemPickedTextColor;
      }
      if (scenario.style.itemPickedBgColor) {
        style['--ys-component-pick-item-picked-bg-color'] =
          scenario.style.itemPickedBgColor;
      }

      // fonts...
      if (scenario.style.itemFont) {
        switch (scenario.style.itemFont) {
          case 'body':
            style['--ys-component-pick-item-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-component-pick-item-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-component-pick-item-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-component-pick-item-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-component-pick-item-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-component-pick-item-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-component-pick-item-font'] =
              scenario.style.fontLabelFamily;
            break;
        }
      }

      //outline
      if (
        scenario.style.outline &&
        scenario.style.outline.width &&
        scenario.style.outline.style &&
        scenario.style.outline.color
      ) {
        style['--ys-component-pick-item-picked-outline'] =
          scenario.style.outline.width +
          'px ' +
          scenario.style.outline.style +
          ' ' +
          scenario.style.outline.color;
      }

      // inventory..
      if (scenario.style.inventoryTextColor) {
        style['--ys-inventory-text-color'] = scenario.style.inventoryTextColor;
      }
      if (scenario.style.inventoryBgColor) {
        style['--ys-inventory-bg-color'] = scenario.style.inventoryBgColor;
      }
      if (scenario.style.inventoryDividerColor) {
        style['--ys-inventory-divider-color'] =
          scenario.style.inventoryDividerColor;
      }
      if (scenario.style.inventoryHideIcon) {
        style['--ys-inventory-hide-icon'] = 'none';
      }
      if (
        scenario.style.inventoryRadius ||
        scenario.style.inventoryRadius == 0
      ) {
        style['--ys-inventory-radius'] = scenario.style.inventoryRadius + 'px';
      }

      if (scenario.style.inventoryTitle) {
        style['--ys-inventory-title'] = scenario.style.inventoryTitle;
      }

      if (scenario.style.inventoryBgImage) {
        style['--ys-inventory-bg-image'] =
          'url(' + scenario.style.inventoryBgImage + ')';
      }
      if (scenario.style.inventoryBgImagePosition) {
        style['--ys-inventory-bg-image-position'] =
          scenario.style.inventoryBgImagePosition;
      }
      if (scenario.style.inventoryBgImageSize) {
        style['--ys-inventory-bg-image-size'] =
          scenario.style.inventoryBgImageSize;
      }
      if (scenario.style.inventoryFont) {
        switch (scenario.style.inventoryFont) {
          case 'body':
            style['--ys-inventory-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-inventory-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-inventory-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-inventory-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-inventory-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-inventory-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-inventory-font'] = scenario.style.inventoryFontFamily;
            break;
        }
      }

      // modals..
      if (scenario.style.modalTextColor) {
        style['--ys-modal-text-color'] = scenario.style.modalTextColor;
      }
      if (scenario.style.modalBgColor) {
        style['--ys-modal-bg-color'] = scenario.style.modalBgColor;
      }
      if (scenario.style.modalDividerColor) {
        style['--ys-modal-divider-color'] = scenario.style.modalDividerColor;
      }
      if (scenario.style.modalRadius || scenario.style.modalRadius == 0) {
        style['--ys-modal-radius'] = scenario.style.modalRadius + 'px';
      }
      if (scenario.style.modalFont) {
        switch (scenario.style.modalFont) {
          case 'body':
            style['--ys-modal-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-modal-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-modal-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-modal-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-modal-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-modal-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-modal-font'] = scenario.style.modalFontFamily;
            break;
        }
      }

      // alerts..
      if (scenario.style.alertTextColor) {
        style['--ys-alert-text-color'] = scenario.style.alertTextColor;
      }
      if (scenario.style.alertBgColor) {
        style['--ys-alert-bg-color'] = scenario.style.alertBgColor;
      }
      if (scenario.style.alertRadius || scenario.style.alertRadius == 0) {
        style['--ys-alert-radius'] = scenario.style.alertRadius + 'px';
      }
      if (scenario.style.alertFont) {
        switch (scenario.style.alertFont) {
          case 'body':
            style['--ys-alert-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-alert-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-alert-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-alert-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-alert-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-alert-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-alert-font'] = scenario.style.alertFontFamily;
            break;
        }
      }
      if (scenario.style.alertButtonColor) {
        style['--ys-alert-button-color'] = scenario.style.alertButtonColor;
      }

      if (scenario.style.alertButtonBg) {
        style['--ys-alert-button-bg'] = scenario.style.alertButtonBg;
      }
      if (scenario.style.alertButtonHoverColor) {
        style['--ys-alert-button-hover-color'] =
          scenario.style.alertButtonHoverColor;
      }

      if (scenario.style.alertButtonHoverBg) {
        style['--ys-alert-button-hover-bg'] = scenario.style.alertButtonHoverBg;
      }

      // tooltips..
      if (scenario.style.tooltipTextColor) {
        style['--ys-tooltip-text-color'] = scenario.style.tooltipTextColor;
      }
      if (scenario.style.tooltipBgColor) {
        style['--ys-tooltip-bg-color'] = scenario.style.tooltipBgColor;
      }
      if (scenario.style.tooltipRadius || scenario.style.tooltipRadius == 0) {
        style['--ys-tooltip-radius'] = scenario.style.tooltipRadius + 'px';
      }

      if (scenario.style.tooltipPadding != undefined) {
        // is it split notation?
        let padding = '0';
        if (String(scenario.style.tooltipPadding).indexOf(' ') >= 0) {
          padding = scenario.style.tooltipPadding.replaceAll(' ', 'px ') + 'px';
        } else {
          padding = scenario.style.tooltipPadding + 'px';
        }

        style['--ys-tooltip-padding'] = padding;
      }

      if (scenario.style.tooltipTextScale) {
        style['--ys-tooltip-text-scale'] =
          scenario.style.tooltipTextScale + '%';
      }
      if (scenario.style.tooltipFont) {
        switch (scenario.style.tooltipFont) {
          case 'body':
            style['--ys-tooltip-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-tooltip-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-tooltip-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-tooltip-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-tooltip-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-tooltip-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-tooltip-font'] = scenario.style.tooltipFontFamily;
            break;
        }
      }

      // popovers..
      if (scenario.style.popoverTextColor) {
        style['--ys-popover-text-color'] = scenario.style.popoverTextColor;
      }
      if (scenario.style.popoverBgColor) {
        style['--ys-popover-bg-color'] = scenario.style.popoverBgColor;
      }
      if (scenario.style.popoverRadius || scenario.style.popoverRadius == 0) {
        style['--ys-popover-radius'] = scenario.style.popoverRadius + 'px';
      }
      if (scenario.style.popoverFont) {
        switch (scenario.style.popoverFont) {
          case 'body':
            style['--ys-popover-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-popover-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-popover-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-popover-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-popover-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-popover-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-popover-font'] = scenario.style.popoverFontFamily;
            break;
        }
      }
    }
    return style;
  }

  getScenarioUiStyles(scenario: any) {
    let style: any;
    style = {};
    if (scenario.style) {
      // inventory..
      if (scenario.style.inventoryTextColor) {
        style['--ys-inventory-text-color'] = scenario.style.inventoryTextColor;
      }
      if (scenario.style.inventoryBgColor) {
        style['--ys-inventory-bg-color'] = scenario.style.inventoryBgColor;
      }
      if (scenario.style.inventoryDividerColor) {
        style['--ys-inventory-divider-color'] =
          scenario.style.inventoryDividerColor;
      }
      if (scenario.style.inventoryHideIcon) {
        style['--ys-inventory-hide-icon'] = 'none';
      }
      if (
        scenario.style.inventoryRadius ||
        scenario.style.inventoryRadius == 0
      ) {
        style['--ys-inventory-radius'] = scenario.style.inventoryRadius + 'px';
      }

      if (scenario.style.inventoryTitle) {
        style['--ys-inventory-title'] = scenario.style.inventoryTitle;
      }

      if (scenario.style.inventoryBgImage) {
        style['--ys-inventory-bg-image'] =
          'url(' + scenario.style.inventoryBgImage + ')';
      }
      if (scenario.style.inventoryBgImagePosition) {
        style['--ys-inventory-bg-image-position'] =
          scenario.style.inventoryBgImagePosition;
      }
      if (scenario.style.inventoryBgImageSize) {
        style['--ys-inventory-bg-image-size'] =
          scenario.style.inventoryBgImageSize;
      }
      if (scenario.style.inventoryFont) {
        switch (scenario.style.inventoryFont) {
          case 'body':
            style['--ys-inventory-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-inventory-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-inventory-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-inventory-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-inventory-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-inventory-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-inventory-font'] = scenario.style.inventoryFontFamily;
            break;
        }
      }

      // modals..
      if (scenario.style.modalTextColor) {
        style['--ys-modal-text-color'] = scenario.style.modalTextColor;
      }
      if (scenario.style.modalBgColor) {
        style['--ys-modal-bg-color'] = scenario.style.modalBgColor;
      }
      if (scenario.style.modalDividerColor) {
        style['--ys-modal-divider-color'] = scenario.style.modalDividerColor;
      }
      if (scenario.style.modalRadius || scenario.style.modalRadius == 0) {
        style['--ys-modal-radius'] = scenario.style.modalRadius + 'px';
      }
      if (scenario.style.modalFont) {
        switch (scenario.style.modalFont) {
          case 'body':
            style['--ys-modal-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-modal-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-modal-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-modal-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-modal-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-modal-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-modal-font'] = scenario.style.modalFontFamily;
            break;
        }
      }

      // alerts..
      if (scenario.style.alertTextColor) {
        style['--ys-alert-text-color'] = scenario.style.alertTextColor;
      }
      if (scenario.style.alertBgColor) {
        style['--ys-alert-bg-color'] = scenario.style.alertBgColor;
      }
      if (scenario.style.alertRadius || scenario.style.alertRadius == 0) {
        style['--ys-alert-radius'] = scenario.style.alertRadius + 'px';
      }
      if (scenario.style.alertFont) {
        switch (scenario.style.alertFont) {
          case 'body':
            style['--ys-alert-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-alert-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-alert-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-alert-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-alert-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-alert-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-alert-font'] = scenario.style.alertFontFamily;
            break;
        }
      }
      if (scenario.style.alertButtonColor) {
        style['--ys-alert-button-color'] = scenario.style.alertButtonColor;
      }

      if (scenario.style.alertButtonBg) {
        style['--ys-alert-button-bg'] = scenario.style.alertButtonBg;
      }
      if (scenario.style.alertButtonHoverColor) {
        style['--ys-alert-button-hover-color'] =
          scenario.style.alertButtonHoverColor;
      }

      if (scenario.style.alertButtonHoverBg) {
        style['--ys-alert-button-hover-bg'] = scenario.style.alertButtonHoverBg;
      }

      // tooltips..
      if (scenario.style.tooltipTextColor) {
        style['--ys-tooltip-text-color'] = scenario.style.tooltipTextColor;
      }
      if (scenario.style.tooltipBgColor) {
        style['--ys-tooltip-bg-color'] = scenario.style.tooltipBgColor;
      }
      if (scenario.style.tooltipRadius || scenario.style.tooltipRadius == 0) {
        style['--ys-tooltip-radius'] = scenario.style.tooltipRadius + 'px';
      }
      if (scenario.style.tooltipPadding != undefined) {
        // is it split notation?
        let padding = '0';
        if (String(scenario.style.tooltipPadding).indexOf(' ') >= 0) {
          padding = scenario.style.tooltipPadding.replaceAll(' ', 'px ') + 'px';
        } else {
          padding = scenario.style.tooltipPadding + 'px';
        }

        style['--ys-tooltip-padding'] = padding;
      }
      if (scenario.style.tooltipTextScale) {
        style['--ys-tooltip-text-scale'] =
          scenario.style.tooltipTextScale + '%';
      }
      if (scenario.style.tooltipFont) {
        switch (scenario.style.tooltipFont) {
          case 'body':
            style['--ys-tooltip-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-tooltip-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-tooltip-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-tooltip-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-tooltip-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-tooltip-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-tooltip-font'] = scenario.style.tooltipFontFamily;
            break;
        }
      }

      // popovers..
      if (scenario.style.popoverTextColor) {
        style['--ys-popover-text-color'] = scenario.style.popoverTextColor;
      }
      if (scenario.style.popoverBgColor) {
        style['--ys-popover-bg-color'] = scenario.style.popoverBgColor;
      }
      if (scenario.style.popoverRadius || scenario.style.popoverRadius == 0) {
        style['--ys-popover-radius'] = scenario.style.popoverRadius + 'px';
      }

      if (scenario.style.popoverFont) {
        switch (scenario.style.popoverFont) {
          case 'body':
            style['--ys-popover-font'] = 'var(--ys-font-base)';
            break;
          case 'sans':
            style['--ys-popover-font'] = 'var(--ys-font-sans)';
            break;
          case 'serif':
            style['--ys-popover-font'] = 'var(--ys-font-serif)';
            break;
          case 'heading':
            style['--ys-popover-font'] = 'var(--ys-font-heading)';
            break;
          case 'mono':
            style['--ys-popover-font'] = 'var(--ys-font-mono)';
            break;
          case 'script':
            style['--ys-popover-font'] = 'var(--ys-font-script)';
            break;
          case 'custom':
            // load the custom font!
            style['--ys-popover-font'] = scenario.style.popoverFontFamily;
            break;
        }
      }
    }

    return style;
  }

  resetScenartioStyles() {
    let style: any = {};
    style['--ys-primary'] = '';
    style['--ys-bg'] = '';
    style['--ys-color'] = '';
    style['--ys-text-color'] = '';
    style['--ys-choice-bg'] = '';
    style['--ys-choice-text'] = '';
    style['--ys-choice-border'] = '';

    style['--ys-font-base'] = '';
    style['--ys-font-heading'] = '';
    style['--ys-font-sans'] = '';
    style['--ys-font-serif'] = '';
    style['--ys-font-mono'] = '';
    style['--ys-font-script'] = '';

    // inputs
    style['--ys-input-font'] = '';
    style['--ys-input-color'] = '';
    style['--ys-label-font'] = '';
    style['--ys-label-color'] = '';

    style['--ys-step-animation'] = '';
    style['--ys-step-animation-speed'] = '';
    style['--ys-step-animation-delay'] = '';

    style['--ys-component-fill'] = '';
    style['--ys-component-radius'] = '';
    style['--ys-component-padding'] = '';
    style['--ys-component-animation'] = '';
    style['--ys-component-animation-speed'] = '';
    style['--ys-component-animation-delay'] = '';

    style['--ys-component-grid-cols'] = '';
    style['--ys-component-grid-gap'] = '';
    style['--ys-component-font-scale'] = '';
    style['--ys-component-font-weight'] = '';
    style['--ys-component-letter-spacing'] = '';
    style['--ys-component-line-height'] = '';

    // choices
    style['--ys-component-choice-margin'] = '';
    style['--ys-component-choice-padding'] = '';
    style['--ys-component-choice-radius'] = '';
    style['--ys-component-choice-opacity'] = '';
    style['--ys-component-text-align'] = '';
    style['--ys-component-flex-align'] = '';
    style['--ys-component-flex-justify'] = '';
    style['--ys-component-flex-direction'] = '';
    style['--ys-component-choice-bg'] = '';
    style['--ys-component-choice-border'] = '';
    style['--ys-component-choice-color'] = '';
    style['--ys-component-choice-hover-bg'] = '';
    style['--ys-component-choice-hover-border'] = '';
    style['--ys-component-choice-hover-color'] = '';
    style['--ys-component-choice-shadow'] = '';
    style['--ys-component-choice-font'] = '';
    style['--ys-component-choice-font-scale'] = '';
    style['--ys-component-choice-font-weight'] = '';
    style['--ys-component-choice-letter-spacing'] = '';
    style['--ys-component-choice-line-height'] = '';

    style['--ys-component-outline'] = '';
    style['--ys-component-shadow'] = '';
    style['--ys-component-text-shadow'] = '';
    style['--ys-component-svg-shadow'] = '';

    // buttons
    style['--ys-component-button-padding'] = '';
    style['--ys-component-button-radius'] = '';
    style['--ys-component-button-align'] = '';
    style['--ys-component-button-bg'] = '';
    style['--ys-component-button-border'] = '';
    style['--ys-component-button-color'] = '';
    style['--ys-component-button-hover-bg'] = '';
    style['--ys-component-button-hover-border'] = '';
    style['--ys-component-button-hover-color'] = '';

    style['--ys-component-question'] = '';
    style['--ys-component-question-hover'] = '';
    style['--ys-component-question-bg'] = '';
    style['--ys-component-question-bg-hover'] = '';

    // tables
    style['--ys-component-th-bg-color'] = '';
    style['--ys-component-th-color'] = '';
    style['--ys-component-tr-bg-color'] = '';
    style['--ys-component-tr-color'] = '';
    style['--ys-component-tr-alt-bg-color'] = '';

    // hotspot
    style['--ys-component-icon-color'] = '';
    style['--ys-component-icon-bg'] = '';

    // modals etc

    //chat
    style['--ys-component-bubbleBg'] = '';
    style['--ys-component-bubbleColor'] = '';
    style['--ys-component-optionBubbleBg'] = '';
    style['--ys-component-optionBubbleColor'] = '';
    style['--ys-component-bubbleBg'] = '';
    style['--ys-component-chat-position'] = '';
    style['--ys-component-avatar-position'] = '';

    // pick
    style['--ys-component-pick-item-text-color'] = '';
    style['--ys-component-pick-item-bg-color'] = '';
    style['--ys-component-pick-item-hover-text-color'] = '';
    style['--ys-component-pick-item-hover-bg-color'] = '';
    style['--ys-component-pick-item-picked-text-color'] = '';
    style['--ys-component-pick-item-picked-bg-color'] = '';
    style['--ys-component-pick-item-picked-outline'] = '';
    style['--ys-component-pick-item-font'] = '';

    //inventory
    style['--ys-inventory-text-color'] = '';
    style['--ys-inventory-bg-color'] = '';
    style['--ys-inventory-divider-color'] = '';
    style['--ys-inventory-font'] = '';
    style['--ys-inventory-hide-icon'] = '';
    style['--ys-inventory-radius'] = '';
    style['--ys-inventory-title'] = '';
    style['--ys-inventory-bg-image'] = '';
    style['--ys-inventory-bg-image-position'] = '';
    style['--ys-inventory-bg-image-size'] = '';

    // modal
    style['--ys-modal-text-color'] = '';
    style['--ys-modal-bg-color'] = '';
    style['--ys-modal-divider-color'] = '';
    style['--ys-modal-font'] = '';
    style['--ys-modal-radius'] = '';

    // alert
    style['--ys-alert-text-color'] = '';
    style['--ys-alert-bg-color'] = '';
    style['--ys-alert-font'] = '';
    style['--ys-alert-radius'] = '';

    return style;
  }

  getScenarioCardStyles(scenario: any) {
    let style: any;
    style = {};
    if (scenario.style) {
      style['color'] = scenario.style.textColor;
      style['background-color'] = scenario.style.primaryColor;

      style['font-family'] = scenario.style.font;
    }
    return style;
  }

  formatScenarioVars(_vars: Array<any>) {
    let returnVars: any = [];
    _vars.forEach((_var) => {
      let mention = {
        id: _var.name,
        value: _var.name,
      };
      returnVars.push(_var.name);
    });
    return returnVars;
  }

  loadScenario(path: string, mode: any = null) {
    if (mode == 'local') {
      return this.http.get<any>('./data/published/' + path, {
        responseType: 'json',
      });
    } else {
      return this.http.get<any>(environment.mediaUrl + '/published/' + path, {
        responseType: 'json',
      });
    }
  }

  initScenarioLayoutDefaults(scenario: any) {
    // is the controls component missing?
    if (!scenario.layout.content.components) {
      scenario.layout.content.components = [];
    }
    // check the default components are there
    let hasControls = false;
    let hasLogo = false;
    scenario.layout.content.components.forEach((component: any) => {
      if (component.type == 'controls') {
        hasControls = true;
      }
      if (component.type == 'yo-logo') {
        hasLogo = true;
      }
    });
    if (!hasControls) {
      // add a controls component
      let defaultWidth = 160;
      let defaultHeight = 50;
      let defaultX = 0;
      let defaultY = 0;

      if (scenario.settings.canvas.width) {
        defaultX = scenario.settings.canvas.width - defaultWidth;
      }

      let controlsComponent = {
        id: nanoid(12),
        type: 'controls',
        transform: {
          translate: { x: 0, y: 0, z: 0 },
          rotate: 0,
        },
        data: {
          showInventory: false,
          showFullscreen: true,
          showVolume: false,
          showHelp: false,
          showRestart: false,
          showClose: false,
        },
        style: {
          bg: null,
          color: null,
          colours: [],
          zIndex: 1,
          radius: null,
          textAlign: 'left',
          fontSize: 100,
          lineHeight: 100,
          letterSpacing: 100,
          padding: null,
          opacity: 100,
          blur: 0,
          flip: {
            h: false,
            v: false,
          },
          class: '',
          subClass: '',
        },
        scale: 1,
        width: defaultWidth,
        height: defaultHeight,
        originalWidth: defaultWidth,
        originalHeight: defaultHeight,
        autoHeight: true,
        newX: 0,
        newY: 0,
        pos: { x: defaultX, y: defaultY },
        drag: { x: null, y: null },
      };
      scenario.layout.content.components.push(controlsComponent);
    }
    if (!hasLogo && !hasControls) {
      let yoLogoComponent = {
        id: nanoid(12),
        type: 'yo-logo',
        transform: {
          translate: { x: 0, y: 0, z: 0 },
          rotate: 0,
        },
        data: {
          showInventory: false,
          showFullscreen: true,
          showVolume: true,
          showHelp: true,
        },
        style: {
          bg: null,
          color: null,
          zIndex: 99999999,
          radius: null,
          textAlign: 'left',
          fontSize: 100,
          lineHeight: 100,
          letterSpacing: 100,
          padding: null,
          opacity: 70,
          blur: 0,
          flip: {
            h: false,
            v: false,
          },
          class: '',
          subClass: '',
        },
        scale: 1,
        width: 20,
        height: 20,
        originalWidth: 20,
        originalHeight: 20,
        autoHeight: true,
        newX: 0,
        newY: 0,
        pos: { x: 10, y: scenario.settings.canvas.height - 30 },
        drag: { x: null, y: null },
        locked: true,
      };
      scenario.layout.content.components.push(yoLogoComponent);
    }
  }

  initScenarioDefaults(scenario: any) {
    if (!scenario.ysVersion) {
      scenario.ysVersion = environment.ysVersion;
    }
    // do the settings
    if (!scenario.settings) {
      scenario.settings = {};
    }
    if (!scenario.settings.canvas) {
      scenario.settings.canvas = {
        width: 1024,
        height: 576,
      };
    }

    scenario.settings.cardSize = 'medium';

    if (!scenario.settings.vars) {
      scenario.settings.vars = [];
    }

    if (!scenario.settings.actions) {
      scenario.settings.actions = [];
    }
    if (!scenario.settings.tracking) {
      scenario.settings.tracking = {};
    }
    if (!scenario.settings.soundtrack) {
      scenario.settings.soundtrack = null;
    }
    if (scenario.settings.enableKeyboardControls == undefined) {
      scenario.settings.enableKeyboardControls = true;
    }

    if (!scenario.layout) {
      scenario.layout = {
        content: {
          components: [],
        },
      };
    }
    this.initScenarioLayoutDefaults(scenario);

    // styles
    if (!scenario.style) {
      scenario.style = {
        primaryColor: environment.defaultColours.primary,
        textColor: environment.defaultColours.text,
        font: 'Manrope',
        padding: 10,
        buttonBg: null,
        choiceBg: null,
        fontMono: 'Roboto Mono',
        fontSans: 'Manrope',
        flexAlign: 'center',
        fontSerif: 'PT Serif',
        buttonFont: 'body',
        fontFamily: null,
        fontScript: 'Dancing Script',
        choiceColor: environment.defaultColours.choiceColor,
        fontHeading: 'Manrope',
        buttonBorder: {
          color: null,
          style: null,
          width: null,
        },
        buttonRadius: 5,
        choiceBorder: {
          color: null,
          style: null,
          width: null,
        },
        choiceRadius: 5,
        choiceShadow: {
          blur: null,
          color: null,
          spread: null,
          offsetX: null,
          offsetY: null,
        },
        choiceFontWeight: 800,
        buttonHoverBg: environment.defaultColours.primary,
        buttonPadding: 10,
        choiceHoverBg: environment.defaultColours.primary,
        flexDirection: 'row',
        stepAnimation: {},
        choiceAnimation: {
          animationIn: null,
          animationInDelay: null,
          animationInSpeed: null,
        },
        animationInDelay: '0.0',
        animationInSpeed: '1.0',
        buttonHoverBorder: {
          color: null,
          style: null,
          width: null,
        },
        choiceBorderHover: {
          color: null,
          style: null,
          width: null,
        },
        choiceHoverBorder: {
          color: null,
          style: null,
          width: null,
        },
        componentAnimation: {},
      };
    }

    // inventory
    if (!scenario.inventory) {
      scenario.inventory = {
        items: [],
        actions: [],
      };
    }

    return scenario;
  }

  public loadFont(font: string): void {
    // check preset fonts
    if (environment.presetFonts.indexOf(font) == -1) {
      // check user fonts

      const fontLink = this.checkFontLinks(font);

      if (!fontLink) {
        try {
          WebFont.load({
            google: {
              families: [font],
            },
          });
        } catch (e) {
          console.warn('Failed to load the font:', font);
        }
      }
    }
  }
  urlFontName(name: string) {
    return name.replace(' ', '+');
  }
  checkFontLinks(font: string) {
    const links = Array.from(document.head.getElementsByTagName('link'));
    if (!links || !links.length) return false;

    return links.find(
      (link) =>
        link.hasAttribute('href') &&
        link.getAttribute('href') ===
          'https://fonts.googleapis.com/css?family=' + this.urlFontName(font)
    );
  }

  loadFonts(scenario: any) {
    let fontsLoaded: any = [];
    if (scenario.style) {
      // load fonts;
      this.loadFont(scenario.style.font);
      fontsLoaded.push(scenario.style.font);
      this.loadFont(scenario.style.fontHeading);
      fontsLoaded.push(scenario.style.fontHeading);
      this.loadFont(scenario.style.fontSans);
      fontsLoaded.push(scenario.style.fontSans);
      this.loadFont(scenario.style.fontSerif);
      fontsLoaded.push(scenario.style.fontSerif);
      this.loadFont(scenario.style.fontMono);
      fontsLoaded.push(scenario.style.fontMono);
      this.loadFont(scenario.style.fontScript);
      fontsLoaded.push(scenario.style.fontScript);
    }

    // check the components for fonts
    if (scenario.steps) {
      scenario.steps.forEach((step: any) => {
        step.content.components.forEach((component: any) => {
          if (component.style && component.style.fontFamily) {
            if (fontsLoaded.indexOf(component.style.fontFamily) == -1) {
              this.loadFont(component.style.fontFamily);
              fontsLoaded.push(component.style.fontFamily);
            }
          }
        });
      });
    }
  }

  appendCustomCss(scenario: any) {
    // remove any existing one
    var elem = document.querySelector(
      '#scenario-' + scenario.id + '-custom-css'
    );
    elem?.parentNode?.removeChild(elem);
    if (scenario && scenario.style && scenario.style.css) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.setAttribute('id', 'scenario-' + scenario.id + '-custom-css');
      style.appendChild(
        document.createTextNode(
          this.prependClasses(scenario.style.css, '.scenario-' + scenario.id)
        )
      );
      head.appendChild(style);
    }
  }

  prependClasses(css: string, prepend: string) {
    var outputString = css.replace(
      /(^(?:\s|[^@{])*?|[},]\s*)(\/\/.*\s+|.*\/\*[^*]*\*\/\s*|@media.*{\s*|@font-face.*{\s*)*([.#]?-?[_a-zA-Z]+[_a-zA-Z0-9-]*)(?=[^}]*{)/g,
      '$1$2 ' + prepend + ' $3'
    );
    return outputString;
  }

  checkScenario(scenario_id: number) {
    this.clearCache();
    const payload = {
      scenario_id,
    };
    return this.http.post<Scenario>(
      environment.apiUrl + '/check_scenario',
      payload,
      {
        observe: 'response',
      }
    );
  }

  getColours(scenario: any) {
    let colours: any = [];
    colours.push(scenario.style.primaryColor);
    colours.push(scenario.style.textColor);
    colours.push(scenario.style.bgColor);

    if (scenario.style.colours) {
      scenario.style.colours.forEach((colour: any) => {
        colours.push(colour.colour);
      });
    }
    return colours;
  }

  getStep(stepId: string, scenario: any) {
    let _step: any;
    scenario.steps.forEach((step: any) => {
      if (step.step_id == stepId) {
        _step = step;
      }
    });
    return _step;
  }

  getStartStep(scenario: any) {
    let startStep;
    if (scenario && scenario.steps && scenario.steps[0]) {
      if (
        scenario.settings.startStep &&
        this.getStep(scenario.settings.startStep, scenario)
      ) {
        startStep = this.getStep(scenario.settings.startStep, scenario);
      } else {
        startStep = scenario.steps[0];
      }
    }
    return startStep;
  }

  onCanvasResize(scenario: any) {
    scenario.layout.content.components.forEach((component: any) => {
      if (component.type == 'controls') {
        component.pos.x = scenario.settings.canvas.width - component.width;
        component.pos.y = 0;
      }
      if (component.type == 'yo-logo') {
        component.pos.x = 10;
        component.pos.y = scenario.settings.canvas.height - 30;
      }
    });
  }

  getOrphanSteps(scenario: any) {
    let orphanSteps: any = [];
    let stepsWithChoices: any = [];
    scenario.choices.forEach((choice: any) => {
      stepsWithChoices.push(choice.target_step_id);
    });

    if (scenario?.settings?.startStep) {
      // if start step is not here add it
      scenario.steps.forEach((_step: any) => {
        if (_step.step_id == scenario?.settings?.startStep) {
          orphanSteps.push(_step);
        }
      });
    }

    scenario.steps.forEach((_step: any) => {
      if (
        stepsWithChoices.indexOf(_step.step_id) == -1 &&
        _step.step_id != scenario?.settings?.startStep
      ) {
        orphanSteps.push(_step);
      }
    });

    // if no orphans use the first step
    if (orphanSteps.length == 0) {
      orphanSteps.push(scenario.steps[0]);
    }

    // if orphan step has no choices use the next step too
    let choicesFound = false;
    scenario.choices.forEach((choice: any) => {
      if (choice.step_id == orphanSteps[0].step_id) {
        choicesFound = true;
      }
    });
    if (!choicesFound) {
      orphanSteps.push(scenario.steps[1]);
    }

    return orphanSteps;
  }
}
