import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  Observable,
  publishReplay,
  refCount,
  Subject,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  error = new Subject<string>();
  resourceName = 'media';
  allMedias?: Observable<any[]> | null = null;
  allMediaFolders?: Observable<any[]> | null = null;

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<any[]> {
    if (!this.allMedias) {
      this.allMedias = this.http
        .get<any>(environment.apiUrl + '/media', {
          //environment.apiUrl + this.slug
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.media;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allMedias;
  }

  fetchFolders(): Observable<any[]> {
    if (!this.allMediaFolders) {
      this.allMediaFolders = this.http
        .get<any>(environment.apiUrl + '/media_folders', {
          //environment.apiUrl + this.slug
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData._embedded.media_folders;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allMediaFolders;
  }

  search(query: string, source: string) {
    let params = new HttpParams();
    params = params.append('query', query.toString());
    params = params.append('source', source.toString());

    return this.http
      .get<any>(environment.apiUrl + '/media', {
        responseType: 'json',
        params,
      })
      .pipe(
        map((responseData) => {
          const results = responseData['_embedded'].media;
          return results;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.allMedias = null;
    this.allMediaFolders = null;
  }

  update(id: number, title: string, folderId: number) {
    this.clearCache();
    return this.http.patch(
      environment.apiUrl + '/media/' + id,
      {
        title: title,
        folder_id: folderId,
      },
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/media/' + id
    );
  }

  deleteList(medias: any) {
    this.clearCache();
    let mediaIds: any = [];
    medias.forEach((media: any) => {
      mediaIds.push(media.media_id);
    });

    const options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids: mediaIds,
      },
    };

    return this.http.delete(environment.apiUrl + '/media', options);
  }

  upload(formData: any) {
    return this.http.post(environment.apiUrl + '/media', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadBase64(asset: any, folder?: any) {
    this.clearCache();
    const payload = {
      asset_base64: asset,
      folder_id: folder,
    };
    return this.http.post<any>(environment.apiUrl + '/media', payload, {
      observe: 'response',
    });
  }

  getThumbPath(filename: string, size: string, fullPath?: boolean) {
    if (fullPath) {
      filename = filename.replace(environment.mediaUrl + '/assets/', '');
    }
    if (filename && size) {
      var resizePath = 'resized/';
      var img = filename;
      var pathParts = filename.split('/');

      if (pathParts[1]) {
        img = pathParts[1];
        resizePath = pathParts[0] + '/resized/';
      }

      var parts = img.split('.');
      if (parts[1] == 'gif' || parts[1] == 'svg') {
        var file = filename;
      } else {
        var file = resizePath + parts[0] + '-' + size + '.' + parts[1];
      }

      if (fullPath) {
        file = environment.mediaUrl + '/assets/' + file;
      }
      return file;
    } else {
      return '';
    }
  }

  addFolder(folderName: string) {
    this.clearCache();
    return this.http.post(
      environment.apiUrl + '/media_folders',
      {
        folder: folderName,
      },
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  updateFolder(folderId: number, folderName: string) {
    this.clearCache();
    return this.http.patch(
      environment.apiUrl + '/media_folders/' + folderId,
      {
        folder: folderName,
      },
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getMediaType(filename: string) {
    let filetype = 'unknown';
    var re = /(?:\.([^.]+))?$/;
    let ext = re.exec(filename);
    if (ext && ext[1]) {
      if (
        ext[1] == 'jpg' ||
        ext[1] == 'png' ||
        ext[1] == 'gif' ||
        ext[1] == 'jpeg' || 
        filename.includes('images.unsplash.com/photo') || 
        filename.includes('giphy.com')
      ) {
        filetype = 'image';
      }
      if (
        ext[1] == 'mp3' ||
        ext[1] == 'ogg' ||
        ext[1] == 'wav' ||
        ext[1] == 'webm'
      ) {
        filetype = 'audio';
      }
      if (ext[1] == 'mp4') {
        filetype = 'video';
      }
    }

    return filetype;
  }

  getMediaByFilename(filename: string, medias: any) {
    filename = filename.replace(environment.mediaUrl + '/assets/', '');
    let media: any;
    medias.forEach((_media: any) => {
      if (_media.filename == filename) {
        media = _media;
      }
    });
    return media;
  }
}
