import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlayerService } from './player.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  public activeStep = new BehaviorSubject('');
  public showPreview = new BehaviorSubject(false);
  public resizeScreen = new BehaviorSubject('');
  public refreshScenario = new BehaviorSubject({} as any);

  constructor() {
   /* this.showPreview.subscribe((showPreview: any) => {
      // when it shows reset the player
      //this.playerService.
    });*/
  }
}
