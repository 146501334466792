import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs-component',
  templateUrl: './tabs-component.component.html',
  styleUrls: ['./tabs-component.component.scss']
})
export class TabsComponentComponent implements OnInit {
  @Input() component: any;

  ngOnInit(): void {}
}
