import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PlayerComponent } from './player/player/player.component';
import { LoaderComponent } from './loader/loader.component';

const routes: Routes = [
  {
    path: ':hash',
    component: LoaderComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
      pageTitle: 'Play scenario',
    },
  },
  {
    path: '',
    component: LoaderComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
      pageTitle: 'Play scenario',
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
