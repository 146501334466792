import { Component, EventEmitter, Input, Output } from '@angular/core';
import { nanoid } from 'nanoid';
import { ContentService } from 'src/app/services/content.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-messenger-component',
  templateUrl: './chat-messenger-component.component.html',
  styleUrls: ['./chat-messenger-component.component.scss'],
})
export class ChatMessengerComponentComponent {
  @Input() component: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  activeStems: any;
  currentStem: any;
  response: any;
  lastAsk: any;
  rootStem: any;

  convoAnimClass: any;
  avatarAnimClass: any;
  bubbleAnimClass: any;
  chatChoicesAnimClass: any;
  lastAskAnim: any;

  completed = false;
  environment = environment;
  chatLog: any = [];
  @Input() mode: any;
  showChoices = false;
  isTyping: any = null;
  startTime = new Date();

  endActivitySub: any;

  constructor(
    private contentService: ContentService,
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {
    this.currentStem = null;
    this.activeStems = this.getStemsByParent(this.currentStem);
    this.response = this.component.data.opening;

    this.convoAnimClass = 'animate__animated animate__fadeIn';
    this.avatarAnimClass = 'animate__animated animate__zoomIn';
    this.bubbleAnimClass = 'animate__animated animate__zoomIn';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeIn';
    this.lastAskAnim = 'animate__animated animate__fadeIn';

    //this.activeStems = $filter('filter')(component.data.stems, {parent: null}, true);
    //      $scope.chatTask.taskData[component.uId].startStem = $filter('filter')(component.data.stems, {parent: null}, true);

    if (!this.component.style.bubbleBg) {
      this.component.style.bubbleBg = '#efefef';
    }
    if (!this.component.style.bubbleColor) {
      this.component.style.bubbleColor = '#333333';
    }

    if (!this.component.style.optionBubbleBg) {
      this.component.style.optionBubbleBg = '#4b7bec';
    }
    if (!this.component.style.optionBubbleColor) {
      this.component.style.optionBubbleColor = '#ffffff';
    }
    if (!this.component.style.bg) {
      this.component.style.bg = '#ffffff';
      this.component.style.color = '#333333';
    }
    this.endActivitySub = this.playerService.endActivity.subscribe(
      (endActivity) => {
        if (endActivity) {
          this.onEndChat(null);
          this.playerService.endActivity.next(false);
        }
      }
    );
    if(this.mode=='editor'){
      this.completed = false;
    }
  }

  ngAfterViewInit() {
    // do the height
    if (this.mode == 'player') {
      let componentElem = document.getElementById(
        'chat-box-' + this.component.id
      );
      let chatBody = document.getElementById('chat-body-' + this.component.id);
      let chatHeader = document.getElementById(
        'chat-head-' + this.component.id
      );
      let chatFooter = document.getElementById(
        'chat-foot-' + this.component.id
      );
      if (componentElem && chatBody && chatHeader && chatFooter) {
        chatBody.style.minHeight =
          componentElem.offsetHeight -
          chatHeader.offsetHeight -
          chatFooter.offsetHeight -
          20 +
          'px';
      }
    }

    if (this.mode == 'editor') {
      let componentElem = document.getElementById(
        'chat-box-editor-' + this.component.id
      );
      let chatBody = document.getElementById(
        'chat-body-editor-' + this.component.id
      );
      let chatHeader = document.getElementById(
        'chat-head-editor-' + this.component.id
      );
      let chatFooter = document.getElementById(
        'chat-foot-editor-' + this.component.id
      );
      if (componentElem && chatBody && chatHeader && chatFooter) {
        chatBody.style.minHeight =
          componentElem.offsetHeight -
          chatHeader.offsetHeight -
          chatFooter.offsetHeight -
          20 +
          'px';
      }
    }
  }

  getStemsByParent(parent: any) {
    let activeStems: any = [];
    this.component.data.stems.forEach((stem: any) => {
      if (stem.parent == parent) {
        activeStems.push(stem);
      }
    });
    return activeStems;
  }

  onChooseStem(stem: any) {
    this.showChoices = false;
    let chosenUUid = nanoid();
    this.playerSessionService.addToLog('chatStem', {
      stemId: stem.id,
      q: stem.q,
      a: stem.a,
      componentId: this.component.id,
      time: new Date(),
      uuid: chosenUUid,
    });

    this.chatLog.push({
      stemId: stem.id,
      q: stem.q,
      a: stem.a,
      time: new Date(),
      uuid: chosenUUid,
    });

    if (stem.aAudio) {
      this.playerService.playAudio(
        this.environment.mediaUrl + '/assets/' + stem.aAudio,
        'chat'
      );
    }
    if(stem.aAvatar){
      this.component.data.avatar = stem.aAvatar;
    }
    if (this.component.data.actions) {
      this.component.data.actions.forEach((action: any) => {
        this.playerService.doAction(
          this.component,
          action,
          null,
          'onChooseStem',
          stem
        );
      });
    }

    // animate
    this.convoAnimClass = 'animate__animated animate__fadeOut';
    //this.avatarAnimClass = 'animate__animated animate__fadeOutUp';
    this.bubbleAnimClass = 'animate__animated animate__fadeOut';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeOut';
    this.lastAskAnim = 'animate__animated animate__fadeOut';

    // set root?
    if (stem.setRoot) {
      this.rootStem = stem.id;
    }
    // reset root?
    if (stem.resetRoot) {
      this.rootStem = null;
    }

    this.isTyping = chosenUUid;
    // scroll to latest
    setTimeout(() => {
      this.scrollChatBody();
    }, 100);

    // set the hide classes
    setTimeout(() => {
      // set the new stems and add the anim classes
      this.lastAsk = stem.q;
      this.response = stem.a;
      this.activeStems = this.getStemsByParent(stem.id);

      if (this.activeStems.length == 0) {
        // go to root stem
        this.activeStems = this.getStemsByParent(this.rootStem);
      }
      this.convoAnimClass = 'animate__animated animate__fadeIn';
      // this.avatarAnimClass = 'animate__animated animate__zoomIn';
      this.bubbleAnimClass = 'animate__animated animate__zoomIn';
      this.chatChoicesAnimClass = 'animate__animated animate__fadeIn';
      this.lastAskAnim = 'animate__animated animate__fadeIn';
    }, 300);

    setTimeout(() => {
      this.isTyping = null;
      this.scrollChatBody();
    }, 1000);

    // end animate
  }

  onEndChat(event: any) {
    this.completed = true;
    this.activityEnded.emit(this.component);
  }

  scrollChatBody() {
    let chatBody = document.getElementById('chat-body-' + this.component.id);
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight;
    }
  }

  ngOnDestroy() {
    this.endActivitySub.unsubscribe();
  }


  onRetakeChat() {
    this.rootStem = null;
    this.completed = false;
    this.currentStem = null;
    this.activeStems = this.getStemsByParent(this.currentStem);
    this.response = this.component.data.opening;
  }
}
