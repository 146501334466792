import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-pick-task',
  templateUrl: './pick-task.component.html',
  styleUrls: ['./pick-task.component.scss'],
})
export class PickTaskComponent implements OnInit {
  @Input() component: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();
  playerInventroy: any = [];
  completed = false;
  pickCompleted = false;
  picked: any = [];
  constructor(
    private playerSessionService: PlayerSessionService,
    public playerService: PlayerService
  ) {}
  ngOnInit(): void {
    this.playerInventroy = this.playerSessionService.sessionData.inventory;
  }

  onPickItems() {
    this.playerSessionService.addToLog('pickItems', {
      id: this.component.id,
      items: this.picked,
    });
    // perform the actions...
    this.pickCompleted = true;

    if (!this.playerSessionService.sessionData.taskResponses) {
      this.playerSessionService.sessionData.taskResponses = {};
    }

    if (
      !this.playerSessionService.sessionData.taskResponses[this.component.id]
    ) {
      this.playerSessionService.sessionData.taskResponses[this.component.id] =
        [];
    }
    this.playerSessionService.sessionData.taskResponses[this.component.id].push(
      {
        picked: this.picked,
        completed: new Date(),
      }
    );

    this.playerService.doActions(
      this.component.data.actions,
      'pickTask',
      this.component
    );

    /*



    this.component.data.actions.forEach((action: any) => {
      let pass = true;
      action.events.forEach((event: any) => {
        switch (event.id) {
          case 'onComplete':
            // do nothing as it's complete
            break;
        }
      });

      // we have passed
      if (pass == true) {
        action.actions.forEach((eventAction: any) => {
          switch (eventAction.id) {
            case 'addToInventory':
              this.picked.forEach((item: any) => {
                if (
                  this.playerInventroy.indexOf(
                    item
                  ) == -1
                ) {
                  this.playerInventroy.push(item);
                }
              });
              break;
          }
        });
      }
    });*/
  }

  onPickItem(item: any) {
    if (!this.picked) {
      this.picked = [];
    }
    if (this.picked.indexOf(item) == -1) {
      if (
        !this.component.data.options.max ||
        (this.component.data.options.max > 0 &&
          +this.component.data.options.max > this.picked.length)
      ) {
        this.picked.push(item);
      }
    } else {
      this.picked.splice(this.picked.indexOf(item), 1);
    }
  }

  itemIsPicked(item: any) {
    if (this.picked) {
      return this.picked.indexOf(item) !== -1;
    } else {
      return false;
    }
  }

  onComplete() {
    this.completed = true;
    this.activityEnded.emit(this.component);
    this.playerService.executeActionQueue();

    // delete the items?
    // do the items need to stay if picked?
    if (!this.component.data.options.retainItems) {
      this.picked.forEach((pickedItem: any) => {
        // delete it from the task
        let deleteDex = -1;
        this.component.data.items.forEach((item: any, index: number) => {
          if (item.id == pickedItem.id) {
            deleteDex = index;
          }
        });
        if (deleteDex > -1) {
          // delete it
          this.component.data.items.splice(deleteDex, 1);
        }
      });
    }
  }

  onCancel() {
    this.playerSessionService.addToLog('pickCancelled', {
      id: this.component.id,
    });
    this.completed = true;
    this.activityEnded.emit(this.component);
  }

  getItemClass() {
    let animClass = '';
    if (this.component.itemAnimationIn) {
      animClass =
        'animate__animated animate__delay-1s animate__' +
        this.component.itemAnimationIn.name;
    }
    return animClass;
  }

  getItemStyle() {
    let animStyle = '';
    if (this.component.itemAnimationIn) {
      animStyle =
        '--animate-duration:' +
        this.component.itemAnimationIn.speed +
        's;--animate-delay:' +
        this.component.itemAnimationIn.delay +
        's;';
    }
    return animStyle;
  }

  getGridStyle(itemCount: number) {
    let repeat = itemCount;
    if (itemCount > 7) {
      repeat = Math.round(itemCount / 2);
    }
    if(repeat < 4){
      repeat = 4;
    }
    return 'grid-template-columns:repeat(' + repeat + ', 1fr) !important;';
  }
}
