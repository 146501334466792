import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from '../services/content.service';

@Pipe({
  name: 'blockAnimation',
})
export class BlockAnimationPipe implements PipeTransform {
  constructor(private contentService: ContentService) {}
  transform(
    component: unknown,
    content: unknown,
    componentSettings: string
  ): string {
    let classStr = this.contentService.getBlockAnimation(component, content);
    return classStr;
  }
}
