import { Component, Input, OnInit } from '@angular/core';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-button-component',
  templateUrl: './button-component.component.html',
  styleUrls: ['./button-component.component.scss'],
})
export class ButtonComponentComponent implements OnInit {
  @Input() component: any;

  constructor(private playerSessionService: PlayerSessionService, private playerService:PlayerService) {}
  ngOnInit(): void {}

  onButtonPressed(){
    this.playerSessionService.addToLog('buttonPressed', {'id':this.component.id, 'text':this.component.data.text});
    this.playerService.doActions(this.component.data.actions, 'buttonPressed', this.component);
    if(this.component.data.url){
      window.open(this.component.data.url, '_blank');
    }
  }
}
