import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { PlayerSessionService } from 'src/app/services/player-session.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @Input() component: any;
  @Input() scenario: any;
  elem: any;
  varsSub: any;
  scenarioVars: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private playerSessionService: PlayerSessionService
  ) {}

  ngOnInit() {
    if (this.component.interactivity?.popover?.template?.maxWidth) {
      this.elem = document.documentElement;
      var elem = document.querySelector('#ys-popover-css-' + this.component.id);

      elem?.parentNode?.removeChild(elem);
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      let popoverCss =
        '.ys-popover-' +
        this.component.id +
        '{ max-width:' +
        this.component.interactivity.popover.template.maxWidth +
        'px}';
      style.appendChild(document.createTextNode(popoverCss));
      style.setAttribute('id', 'ys-popover-css-' + this.component.id);
      head.appendChild(style);
    }
    this.varsSub = this.playerSessionService.variablesChanges.subscribe(
      (changed) => {
        // copy the object so the pipe updates
        this.scenarioVars = JSON.parse(
          JSON.stringify(this.playerSessionService.getVars())
        );
      }
    );
  }
  ngOnDestroy(): void {
    this.varsSub?.unsubscribe();
  }
}
