<app-player
*ngIf="scenario && startStep&& !unavailable"
[step]="startStep"
[scenario]="scenario"
[content]="startStep?.content"
[width]="'window'"
[options]="{}"
></app-player>
<div *ngIf="isLoading" class="p-4">Loading...</div>
<div *ngIf="notFound" class="p-4">No scenario found...</div>
<app-unavailable *ngIf="unavailable" [reason]="unavailableError"></app-unavailable>