<div *ngIf="!pickCompleted">
  <!-- start -->

  <div
    class="task-obscure"
    *ngIf="completed && component.data.options.obscure"
  ></div>
  <div class="pick-task-content">
    <div class="pick-task-instructions" *ngIf="component.data.instructions">
      <div>{{ component.data.instructions }}</div>
    </div>
    <ng-container *ngIf="component.data.useChosen">
      <div class="inventory-items-wrap">
        <div
          class="inventory-items mt-1"
          style="{{ getGridStyle(playerInventroy?.length) }}"
          *ngIf="playerInventroy && playerInventroy.length > 0"
        >
          <ng-container *ngFor="let item of playerInventroy">
            <button
              class="inventory-item border-0 pointer {{ getItemClass() }}"
              style="{{ getItemStyle() }}"
              (click)="onPickItem(item)"
              [ngClass]="{
                'item-picked': itemIsPicked(item),
                'inventory-item-text': !item.image
              }"
            >
              <img
                *ngIf="item.image"
                src="{{ item.image }}"
                alt=" {{ playerService.parseVars(item.description) }}"
              />
              <div class="item-name">
                {{ playerService.parseVars(item.name) }}
              </div>
            </button>
          </ng-container>
        </div>
      </div>
      <div *ngIf="playerInventroy && playerInventroy.length < 1">
        There are no items to pick
      </div>
    </ng-container>
    <ng-container *ngIf="!component.data.useChosen">
      <div class="inventory-items-wrap">
        <div
          class="inventory-items mt-1"
          style="{{ getGridStyle(component.data.items?.length) }}"
          *ngIf="component.data.items.length > 0"
        >
          <ng-container *ngFor="let item of component.data.items">
            <button
              class="inventory-item border-0 pointer {{ getItemClass() }}"
              style="{{ getItemStyle() }}"
              (click)="onPickItem(item)"
              [ngClass]="{
                'item-picked': itemIsPicked(item),
                'inventory-item-text': !item.image
              }"
            >
              <img
                *ngIf="item.image"
                src="{{ item.image }}"
                alt="{{ item.description }}"
              />
              <div class="item-name">{{ item.name }}</div>
            </button>
          </ng-container>
        </div>
        <div *ngIf="component.data.items.length < 1">
          There are no items to pick
        </div>
      </div>
    </ng-container>
  </div>
  <!-- end -->
  <div class="task-btns text-center" *ngIf="!completed">
    <div class="small mb-3" *ngIf="component.data.options.max">
      You have picked {{ picked.length }} of
      {{ component.data.options.max }} items
    </div>
    <button
      class="btn btn-choice btn-ys me-2"
      (click)="onCancel()"
      *ngIf="component.data.options.dismissable"
    >
      Cancel
    </button>
    <button
      class="btn btn-choice btn-ys"
      (click)="onPickItems()"
      [disabled]="
        picked.length == 0 ||
        (component.data.options.min &&
          picked.length < component.data.options.min)
      "
    >
      <ng-container *ngIf="component.data.pickLabel">{{
        component.data.pickLabel
      }}</ng-container>
      <ng-container *ngIf="!component.data.pickLabel">Pick items</ng-container>
    </button>
  </div>
</div>
<div *ngIf="pickCompleted">
  <div class="task-obscure" *ngIf="completed"></div>

  <div class="pick-task-content">
    <div *ngIf="component.data.feedback">{{ component.data.feedback }}</div>
    <div *ngIf="!component.data.feedback">Task completed</div>
    <div class="task-btns">
      <button
        class="btn btn-choice btn-ys"
        (click)="onComplete()"
        *ngIf="!completed"
      >
        <ng-container *ngIf="component.data.continueLabel">{{
          component.data.continueLabel
        }}</ng-container>
        <ng-container *ngIf="!component.data.continueLabel"
          >Continue</ng-container
        >
      </button>
    </div>
  </div>
</div>
