import { Component, Input } from '@angular/core';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-audio-component',
  templateUrl: './audio-component.component.html',
  styleUrls: ['./audio-component.component.scss'],
})
export class AudioComponentComponent {
  @Input() component: any;
  @Input() content: any;
  @Input() disableAudio: any;
  @Input() muted: any;
  timedEvents: any = [];
  pauseEvents: any = [];
  playEvents: any = [];
  endEvents: any = [];

  constructor(
    private playerSessionService: PlayerSessionService,
    public playerService: PlayerService
  ) {}

  ngOnInit(): void {
    this.timedEvents = [];
    this.component?.data?.actions.forEach((action: any) => {
      action.events.forEach((_event: any) => {
        if (_event.id == 'onMediaTime') {
          // yes
          let actionToAdd = {
            time: _event.vars[0].value,
            action: action,
          };
          this.timedEvents.push(actionToAdd);
        }
        if (_event.id == 'onMediaPaused') {
          this.pauseEvents.push(action);
        }
        if (_event.id == 'onMediaPlays') {
          this.playEvents.push(action);
        }
        if (_event.id == 'onMediaEnds') {
          this.endEvents.push(action);
        }
      });
    });
  }
}
