<div class="modal-header">
  <h4 class="modal-title pull-left">
    <i class="bx bxs-briefcase-alt-2"></i> 
    <ng-container *ngIf="scenario.style?.inventoryTitle">{{scenario.style?.inventoryTitle}}</ng-container>
    <ng-container *ngIf="!scenario.style?.inventoryTitle">Inventory</ng-container>
    
  </h4>
  <button
    type="button"
    class="btn pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span><i class="bx bx-x"></i></span>
  </button>
</div>
<div
  class="modal-body"
  *ngIf="scenario && playerInventroy"
  style="max-height: 75vh; overflow-y: scroll"
>
  <p *ngIf="playerInventroy && playerInventroy.length == 0">No items</p>
  <div
    class="inventory-items mt-4"
    *ngIf="playerInventroy && playerInventroy.length > 0"
  >
    <ng-container *ngFor="let item of playerInventroy">
      <button
        class="inventory-item border-0 pointer"
        (click)="onPickItem(item)"
        [ngClass]="{
          'item-picked': itemIsPicked(item),
          'inventory-item-text': !item.image
        }"
      >
        <img
          *ngIf="item.image"
          src="{{ item.image }}"
          alt=" {{ playerService.parseVars(item.description) }}"
          class="square"
        />
        <div class="item-name">{{ playerService.parseVars(item.name) }}</div>
      </button>
    </ng-container>
  </div>
</div>
<div class="modal-footer text-center justify-content-center">
  <button
    type="button"
    class="btn btn-primary btn-ys"
    (click)="onUseItems()"
    [disabled]="picked.length == 0"
  >
    <span *ngIf="picked.length == 0">No items picked</span>
    <span *ngIf="picked.length > 0"
      >Use {{ picked.length }} Item<span *ngIf="picked.length != 1"
        >s</span
      ></span
    >
  </button>
</div>
