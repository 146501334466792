import { Component, Input, OnInit } from '@angular/core';
import { nanoid } from 'nanoid';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  @Input() scenario: any;
  public picked: any = [];
  public playerInventroy: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private playerSessionService: PlayerSessionService,
    public playerService: PlayerService
  ) {}

  ngOnInit(): void {
    this.playerInventroy = this.playerSessionService.sessionData.inventory;
    if (!this.scenario.inventory) {
      this.scenario.inventory = {
        items: [],
        actions: [],
      };
    }
    if (!this.scenario.inventory.items) {
      this.scenario.inventory.items = [];
    }
    if (!this.scenario.inventory.actions) {
      this.scenario.inventory.actions = [];
    }

    this.scenario.inventory.items.forEach((item: any) => {
      if (!item.id) {
        item.id = nanoid(12);
      }
    });
  }

  onPickItem(item: any) {
    if (!this.picked) {
      this.picked = [];
    }
    if (this.picked.indexOf(item) == -1) {
      this.picked.push(item);
    } else {
      this.picked.splice(this.picked.indexOf(item), 1);
    }
  }

  itemIsPicked(item: any) {
    if (this.picked) {
      return this.picked.indexOf(item) !== -1;
    } else {
      return false;
    }
  }

  onUseItems() {

    this.playerSessionService.addToLog('useInventoryItems', {items:JSON.parse(JSON.stringify(this.picked))});

    this.playerSessionService.sessionData.inventory.picked = this.picked;
    let actionsPerformed = this.playerService.doActions(
      this.scenario.inventory.actions,
      'inventory',
      this.scenario.inventory
    );
    this.picked = [];
    if(actionsPerformed == 0){
      Swal.fire({
        customClass: {'container':'ys-swal'},
        title: 'Nothing useful happened',
        icon: 'info',
        position: 'center',
      });
    }
  }
}
