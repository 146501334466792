export class Scenario {
  public scenario_id: number;
  public user_id: number;
  public title: string;
  public scenario_data: string;
  public created: string;
  public modified: string;

  constructor(
    scenario_id: number,
    user_id: number,
    title: string,
    scenario_data: string,
    created: string,
    modified: string,
  ) {
    this.scenario_id = scenario_id;
    this.user_id = user_id;
    this.title = title;
    this.scenario_data = scenario_data;
    this.created = created;
    this.modified = modified;
  }
}
