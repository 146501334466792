import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-component',
  templateUrl: './chat-component.component.html',
  styleUrls: ['./chat-component.component.scss'],
})
export class ChatComponentComponent implements OnInit {
  @Input() component: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  activeStems: any;
  currentStem: any;
  response: any;
  lastAsk: any;
  rootStem: any;

  convoAnimClass: any;
  avatarAnimClass: any;
  bubbleAnimClass: any;
  chatChoicesAnimClass: any;
  lastAskAnim: any;

  completed = false;
  environment = environment;
  endActivitySub: any;

  constructor(
    private contentService: ContentService,
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {
    this.currentStem = null;
    this.activeStems = this.getStemsByParent(this.currentStem);
    this.response = this.component.data.opening;

    this.convoAnimClass = 'animate__animated animate__fadeIn';
    this.avatarAnimClass = 'animate__animated animate__zoomIn';
    this.bubbleAnimClass = 'animate__animated animate__zoomIn';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeIn';
    this.lastAskAnim = 'animate__animated animate__fadeIn';

    this.endActivitySub = this.playerService.endActivity.subscribe(
      (endActivity) => {
        if (endActivity) {
          this.onEndChat(null);
          this.playerService.endActivity.next(false);
        }
      }
    );
  }

  getStemsByParent(parent: any) {
    let activeStems: any = [];
    this.component.data.stems.forEach((stem: any) => {
      if (stem.parent == parent) {
        activeStems.push(stem);
      }
    });
    return activeStems;
  }

  onChooseStem(stem: any) {
    this.playerSessionService.addToLog('chatStem', {
      stemId: stem.id,
      q: stem.q,
      a: stem.a,
      componentId: this.component.id,
    });

    if (stem.aAudio) {
      this.playerService.playAudio(
        this.environment.mediaUrl + '/assets/' + stem.aAudio,
        'chat'
      );
    }

    if(stem.aAvatar){
      this.component.data.avatar = stem.aAvatar;
    }
    if (this.component.data.actions) {
      this.component.data.actions.forEach((action: any) => {
        this.playerService.doAction(
          this.component,
          action,
          null,
          'onChooseStem',
          stem
        );
      });
    }

    // animate
    this.convoAnimClass = 'animate__animated animate__fadeOut';
    //this.avatarAnimClass = 'animate__animated animate__fadeOutUp';
    this.bubbleAnimClass = 'animate__animated animate__fadeOut';
    this.chatChoicesAnimClass = 'animate__animated animate__fadeOut';
    this.lastAskAnim = 'animate__animated animate__fadeOut';

    // set root?
    if (stem.setRoot) {
      this.rootStem = stem.id;
    }
    // reset root?
    if (stem.resetRoot) {
      this.rootStem = null;
    }
    // set the hide classes

    setTimeout(() => {
      // set the new stems and add the anim classes
      this.lastAsk = stem.q;
      this.response = stem.a;
      this.activeStems = this.getStemsByParent(stem.id);

      if (this.activeStems.length == 0) {
        // go to root stem
        this.activeStems = this.getStemsByParent(this.rootStem);
      }
      this.convoAnimClass = 'animate__animated animate__fadeIn';
      // this.avatarAnimClass = 'animate__animated animate__zoomIn';
      this.bubbleAnimClass = 'animate__animated animate__zoomIn';
      this.chatChoicesAnimClass = 'animate__animated animate__fadeIn';
      this.lastAskAnim = 'animate__animated animate__fadeIn';
    }, 300);

    // end animate
  }

  onEndChat(event: any) {
    this.completed = true;
    this.activityEnded.emit(this.component);
  }
  ngOnDestroy() {
    this.endActivitySub.unsubscribe();
  }

  onRetakeChat() {
    this.rootStem = null;
    this.completed = false;
    this.currentStem = null;
    this.activeStems = this.getStemsByParent(this.currentStem);
    this.response = this.component.data.opening;
  }
}
