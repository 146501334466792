import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-component-container',
  templateUrl: './component-container.component.html',
  styleUrls: ['./component-container.component.scss'],
})
export class ComponentContainerComponent implements OnInit {
  @Input() component: any;
  @Input() content: any;
  @Input() scenario: any;
  scenarioVars: any;
  @Input() disableVideo: any;
  @Input() disableAudio: any;
  @Input() muted: any;
  @Input() step: any;
  varsSub: any;
  @Input() scale: any;
  fullscreen = false;

  constructor(
    public playerService: PlayerService,
    public contentService: ContentService,
    private playerSessionService: PlayerSessionService
  ) {}

  ngOnInit() {
    this.varsSub = this.playerSessionService.variablesChanges.subscribe(
      (changed) => {
        // copy the object so the pipe updates
        this.scenarioVars = JSON.parse(
          JSON.stringify(this.playerSessionService.getVars())
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.varsSub?.unsubscribe();
  }
  stringify(object: object) {
    return JSON.stringify(object);
  }

  // Globals


  onOpenFullscreen() {
    this.fullscreen = true;
    this.playerService.fullscreen.next(true);
  }
  onCloseFullscreen() {
    this.fullscreen = false;
    this.playerService.fullscreen.next(false);
  }

  closeFullscreen() {
    this.fullscreen = false;
    this.playerService.fullscreen.next(false);
  }
  onUnMute() {
    this.muted = false;
    this.playerService.muted.next(false);
  }

  onMute() {
    this.muted = true;
    this.playerService.muted.next(true);
  }

  onShowInventory() {
    this.playerService.onShowInventory();
    /*
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
      },
    };
    this.bsModalRef = this.modalService.show(
      InventoryComponent,
      initialState
    );
    this.bsModalRef.setClass('modal-xl anim-open modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });*/
  }
  onShowHelp() {
    this.playerService.onShowHelp();
    /*
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
        helpContent:this.helpContent
      },
    };
    this.bsModalRef = this.modalService.show(
      PlayerHelpComponent,
      initialState
    );
    this.bsModalRef.setClass('modal-xl anim-open modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });*/
  }

  onRestart() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to restart this scenario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Restart it',
    }).then((result) => {
      if (result.value) {
        this.playerService.restart();
      }
    });
  }

  onClose() {
    this.playerService.onEnd();
  }

  onBack() {
    this.playerService.onPreviousStep();
  }

  canGoBack() {
    return this.playerSessionService.sessionData?.history?.length > 1;
  }

}
