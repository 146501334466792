import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss']
})
export class FormComponentComponent  implements OnInit {

  submitted = false;
  formSubmitted = false;
  completed = false;
  formData:any = {};
  @Input() component: any;

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  @ViewChild("form", { static: false }) entryForm?: NgForm;

  constructor(private playerSessionService: PlayerSessionService, private playerService:PlayerService) {}


  ngOnInit(): void {
    this.component.data.fields.forEach((field:any) => {
      this.formData[field.id] = null;
    });
  }

  onSubmit(){
    this.formSubmitted = true;
    if(!this.entryForm?.valid){
      return;
    }
    
    this.playerSessionService.addToLog('formSubmitted', {'id':this.component.id, 'formData':this.formData});
    
    this.submitted = true;
    if(!this.playerSessionService.sessionData.taskResponses){
      this.playerSessionService.sessionData.taskResponses = {};
    }

    if(!this.playerSessionService.sessionData.taskResponses[this.component.id]){
      this.playerSessionService.sessionData.taskResponses[this.component.id] = [];
    }
    this.playerSessionService.sessionData.taskResponses[this.component.id].push({
      formData:this.formData,
      completed: new Date()
    })

    this.playerService.doActions(this.component.data.actions, 'formTask', this.component);

  }


  onComplete(){
    this.completed = true;
    this.activityEnded.emit(this.component);
    this.playerService.executeActionQueue();
  }

  onCancel(){
    this.playerSessionService.addToLog('formCancelled', {'id':this.component.id});
    this.completed = true;
    this.activityEnded.emit(this.component);
  }
}
