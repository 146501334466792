import { HostListener, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PlayerSessionService } from './player-session.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PlayerModalComponent } from '../player/player-modal/player-modal.component';
import { InventoryComponent } from '../player/inventory/inventory.component';
import { PlayerHelpComponent } from '../player/global-components/player-help/player-help.component';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { ScenarioService } from './scenario.service';
import { DOCUMENT } from '@angular/common';
import { ConfettiService } from './confetti.service';
dayjs.extend(duration);

// for SCORM / xAPI
declare const window: any;
declare const lrs: any;
declare const TinCan: any;
declare const xapiActor: any;

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  public muted = new BehaviorSubject(false);
  public fullscreen = new BehaviorSubject(false);
  public currentStep = new BehaviorSubject('');
  public stepChange = new BehaviorSubject('');
  public activeCountdownClock = new BehaviorSubject(0);
  public activeCountdownActive = new BehaviorSubject(false);
  public restarted = new BehaviorSubject(false);
  public updateComponent = new BehaviorSubject({});
  public makeChoice = new BehaviorSubject({});
  public endActivity = new BehaviorSubject(false);
  public current360Scene = new BehaviorSubject({});
  currentStepId: any;
  mutedState = false;
  audioObjs: any = [];

  scenarioData: any;
  sessionData: any;
  activeCountdown: any;
  completeActivity: any;
  API: any;
  scenario: any;
  actionQueue: any = [];
  stepTimers: any = [];
  stepIntervals: any = [];
  stepVarListeners: any = [];

  eventConditions: any = [
    'onOverlaps',
    'onNotOverlaps',
    'onVar',
    'onScore',
    'onItemInInventory',
    'onQuizScore',
  ];
  previousVars: any;

  videoTime: any = {};

  constructor(
    private playerSessionService: PlayerSessionService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private scenarioService: ScenarioService,
    private confettiService: ConfettiService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.stepChange.subscribe((response) => {
      // console.log('step changed');
      // the step has changed - do stuff
      this.clearStepTimeouts();
      this.clearStepIntervals();
      this.clearStepVarListeners();
    });
    this.muted.subscribe((isMuted) => {
      this.mutedState = isMuted;
      this.audioObjs.forEach((_audio: any) => {
        _audio.obj.muted = isMuted;
      });
    });

    this.playerSessionService.variablesChanges.subscribe((vars) => {
      // do it here
      if (vars) {
        this.stepVarListeners.forEach((varListener: any) => {
          if (varListener) {
            varListener();
          }
        });
      }
    });
  }

  setScenario(scenario: any) {
    this.scenario = scenario;
  }

  doActions(actions: any[], type: string, component: any, content?: any) {
    if (component?.type == 'pick') {
    }
    // console.log('Do actions = ');
    // console.log(actions);
    let actionsPerformed = 0;
    if (Array.isArray(actions)) {
      actions.forEach((action) => {
        let actionPerformed = this.doAction(
          component,
          action,
          null,
          type,
          content
        );

        if (actionPerformed) {
          actionsPerformed++;
        }
      });
    }
    return actionsPerformed;
  }

  doAction(
    component: any,
    action: any,
    stepId: any,
    trigger: any,
    triggerData: any
  ) {
    //console.log(trigger);
    // check the conditions..

    // console.log('Do Action');
    let pass = true;
    if (!component.type) {
      // step of inventory..
      if (component.inventory_id) {
        component.type = 'inventory';
      } else if (component.parent_id) {
        component.type = 'step';
      } else if (component.choice_id) {
        component.type = 'makeChoice';
      }
    }

    let taskId: any;
    /* if (component.type != 'step') {
      if (this.playerSessionService.sessionData.steps[stepId]) {
         taskId = component.uId;
        if (this.playerSessionService.sessionData.steps[stepId].taskData) {
          var taskData = this.sessionData.steps[stepId].taskData[taskId];
        }
      }
    }*/

    let ignoreTriggers = [
      'componentHovered',
      'buttonPressed',
      'componentPressed',
      'onChooseStem',
      'componentDropped',
      'onQuizQuestionAnswered',
    ];
    let passes: any = [];
    let hasTimeout = 0;
    let hasInterval = 0;
    let isVarListener = false;
    if (ignoreTriggers.indexOf(trigger) != -1) {
      pass = false;
    }
    action.events.forEach((event: any, eIndex: number) => {
      // catch triggers

      let picked: any = [];
      let fieldValue: any;
      switch (event.id) {
        case 'onPressed':
          pass = false;
          if (trigger == 'buttonPressed') {
            pass = true;
          }
          if (trigger == 'componentPressed') {
            pass = true;
          }
          if (trigger == 'hotspotPressed') {
            pass = true;
          }
          break;
        case 'onDropped':
          pass = false;
          if (trigger == 'componentDropped') {
            pass = true;
            /*
            // check if it is dropped on a component?
            var droppedOn = this.getEventVar(event.vars, 'component');
            if (droppedOn) {
              pass = false;
              // check it
              // are we dropped over anything?
              let collisions: any = [];
              triggerData.components.forEach((_component: any) => {
                if (_component.id != component.id) {
                  if (this.doComponentsCollide(component, _component)) {
                    collisions.push(_component.id);
                  }
                }
              });

              if(collisions.indexOf(droppedOn)!==-1){
                pass = true;
              }
            }*/
          }
          break;
        /*
        case 'onOverlaps':
          pass = false;
          var droppedOn = this.getEventVar(event.vars, 'component');
          if (droppedOn) {
            // check it
            // are we dropped over anything?
            let collisions: any = [];
            triggerData?.components.forEach((_component: any) => {
              if (_component.id != component.id) {
                if (this.doComponentsCollide(component, _component)) {
                  collisions.push(_component.id);
                }
              }
            });

            if (collisions.indexOf(droppedOn) !== -1) {
              pass = true;
            }
          }
          break;
        
        case 'onNotOverlaps':
          pass = true;
          var droppedOn = this.getEventVar(event.vars, 'component');
          if (droppedOn) {
            // check it
            // are we dropped over anything?
            let collisions: any = [];
            triggerData?.components.forEach((_component: any) => {
              if (_component.id != component.id) {
                if (this.doComponentsCollide(component, _component)) {
                  collisions.push(_component.id);
                }
              }
            });

            if (collisions.indexOf(droppedOn) !== -1) {
              pass = false;
            }
          }
          break;*/
        case 'onComplete':
          pass = false;
          if (trigger == 'onComplete') {
            pass = true;
          }
          break;
        // for components..
        case 'onPress':
          pass = false;
          if (trigger == 'buttonPressed') {
            pass = true;
          }
          if (trigger == 'componentPressed') {
            pass = true;
          }
          if (trigger == 'hotspotPressed') {
            pass = true;
          }
          break;
        case 'onHover':
          pass = false;
          if (trigger == 'hotspotHover') {
            pass = true;
          }
          if (trigger == 'componentHovered') {
            pass = true;
          }
          break;
        case 'onStem':
          pass = false;
          if (trigger == 'onChooseStem') {
            if (triggerData.id == this.getEventVar(event.vars, 'stem')) {
              pass = true;
            }
          }
          break;
        // medias - ignore all
        case 'onMediaTime':
          pass = false;
          if (trigger == 'onMediaTime') {
            pass = true;
          }
          break;
        case 'onMediaEnds':
          pass = false;
          if (trigger == 'onMediaEnds') {
            pass = true;
          }
          break;
        case 'onMediaPlays':
          pass = false;
          if (trigger == 'onMediaPlays') {
            pass = true;
          }
          break;
        case 'onMediaPaused':
          pass = false;
          if (trigger == 'onMediaPaused') {
            pass = true;
          }
          break;
        case 'onQuizResults':
          pass = false;
          if (trigger == 'onQuizResults') {
            pass = true;
          }
          break;
        case 'onQuizQuestionAnswered':
          pass = false;
          if (trigger == 'onQuizQuestionAnswered') {
            let questionData = this.getEventVar(event.vars, 'quizQuestion');
            pass = true;
            if (
              questionData.question &&
              triggerData.question.id != questionData.question
            ) {
              pass = false;
            }
            if (questionData.answer) {
              pass = false;
              triggerData.answers.forEach((_answer: any) => {
                if (_answer.id == questionData.answer) {
                  pass = true;
                }
              });
            }
          }
          break;
        case 'onStart':
          pass = true;
          break;
        case 'onPicksAll':
          if (trigger != 'inventory' && trigger != 'pickTask') {
            pass = false;
          } else {
            picked = this.getPicked(component);
            pass = true;
            var items = this.getEventVar(event.vars, 'items');

            var itemIds: any = [];
            var pickedIds: any = [];
            items.forEach((_item_id: any) => {
              itemIds.push(_item_id.id);
            });
            picked.forEach((pickedItem: any) => {
              pickedIds.push(pickedItem.id);
            });
            itemIds.forEach((itemId: string) => {
              if (pickedIds.indexOf(itemId) == -1) {
                pass = false;
              }
            });
          }
          break;
        case 'onPicksOnly':
          if (trigger != 'inventory' && trigger != 'pickTask') {
            pass = false;
          } else {
            picked = this.getPicked(component);
            pass = true;
            var items = this.getEventVar(event.vars, 'items');

            var itemIds: any = [];
            var pickedIds: any = [];
            items.forEach((_item_id: any) => {
              itemIds.push(_item_id.id);
            });
            picked.forEach((pickedItem: any) => {
              pickedIds.push(pickedItem.id);
            });

            items.forEach((_item_id: any) => {
              if (pickedIds.indexOf(_item_id.id) == -1) {
                pass = false;
              }
            });

            if (items.length != picked.length) {
              pass = false;
            }
          }
          break;
        case 'onNotPicksAll':
          if (trigger != 'inventory' && trigger != 'pickTask') {
            pass = false;
          } else {
            picked = this.getPicked(component);

            pass = true;
            let pMatches = 0;
            var items = this.getEventVar(event.vars, 'items');

            items.forEach((_item_id: any) => {
              picked.forEach((pickedItem: any) => {
                if (pickedItem.id == _item_id.id) {
                  pMatches++;
                }
              });
            });

            if (pMatches == items.length) {
              pass = false;
            }
          }
          break;

        case 'onPicksAny':
          if (trigger != 'inventory' && trigger != 'pickTask') {
            pass = false;
          } else {
            pass = false;
            picked = this.getPicked(component);
            var items = this.getEventVar(event.vars, 'items');
            items.forEach((_item_id: any) => {
              picked.forEach((pickedItem: any) => {
                if (pickedItem.id == _item_id.id) {
                  pass = true;
                }
              });
            });
          }
          break;

        case 'onPicksNone':
          if (trigger != 'inventory' && trigger != 'pickTask') {
            pass = false;
          } else {
            pass = true;
            picked = this.getPicked(component);
            var items = this.getEventVar(event.vars, 'items');
            items.forEach((_item_id: any) => {
              picked.forEach((pickedItem: any) => {
                if (pickedItem.id == _item_id.id) {
                  pass = false;
                }
              });
            });
          }
          break;

        case 'onFormSubmit':
          if (trigger != 'formTask') {
            pass = false;
          } else {
            pass = true;
          }
          break;
        case 'onFieldEquals':
          if (trigger != 'formTask') {
            pass = false;
          } else {
            fieldValue = this.getFieldValue(
              component,
              this.getEventVar(event.vars, 'field')
            );

            var val = this.getEventVar(event.vars, 'val');
            pass = false;

            var vals: any = [];
            if (!Array.isArray(val)) {
              vals.push(val);
            } else {
              vals = val;
            }
            if (vals.indexOf(fieldValue) != -1) {
              pass = true;
            }
          }
          break;

        case 'onFieldNotEquals':
          if (trigger != 'formTask') {
            pass = false;
          } else {
            fieldValue = this.getFieldValue(
              component,
              this.getEventVar(event.vars, 'field')
            );
            var val = this.getEventVar(event.vars, 'val');

            pass = false;

            var vals: any = [];
            if (!Array.isArray(val)) {
              vals.push(val);
            } else {
              vals = val;
            }
            if (vals.indexOf(fieldValue) == -1) {
              pass = true;
            }
          }
          break;

        /*
        case 'onVar':
          // console.log(event.vars);
          // console.log(this.playerSessionService.sessionData);
          pass = false;
          var _val = this.getEventVar(event.vars, 'val');
          var _var = this.getSessionVar(
            this.playerSessionService.sessionData,
            this.getEventVar(event.vars, 'var')
          );
          var comparator = _val.charAt(0);
          var comparatorB = _val.charAt(1);
          switch (comparator) {
            default:
              // console.log('comparing ' + _var + ' == ' + _val);
              if (_var == _val) {
                pass = true;
              }
              break;
            case '>':
              switch (comparatorB) {
                default:
                  if (_var > _val.substring(1)) {
                    pass = true;
                  }
                  break;
                case '=':
                  if (_var >= _val.substring(2)) {
                    pass = true;
                  }
                  break;
              }
              break;
            case '<':
              switch (comparatorB) {
                default:
                  if (_var < _val.substring(1)) {
                    pass = true;
                  }
                  break;
                case '=':
                  if (_var <= _val.substring(2)) {
                    pass = true;
                  }
                  break;
              }
              break;

            case '!':
              if (comparatorB == '=') {
                if (_var != _val.substring(2)) {
                  pass = true;
                }
              }
              break;
          }

          break;

        case 'onScore':
          // console.log(event.vars);
          // console.log(this.playerSessionService.sessionData);
          pass = false;
          var _scoreVal = this.getEventVar(event.vars, 'val');
          var _scoreVar = this.playerSessionService.sessionData.score;
          var comparator = _scoreVal.charAt(0);
          var comparatorB = _scoreVal.charAt(1);
          switch (comparator) {
            default:
              // console.log('comparing ' + _scoreVar + ' == ' + _scoreVal);
              if (_scoreVar == _scoreVal) {
                pass = true;
              }
              break;
            case '>':
              switch (comparatorB) {
                default:
                  if (_scoreVar > _scoreVal.substring(1)) {
                    pass = true;
                  }
                  break;
                case '=':
                  if (_scoreVar >= _scoreVal.substring(2)) {
                    pass = true;
                  }
                  break;
              }
              break;
            case '<':
              switch (comparatorB) {
                default:
                  if (_scoreVar < _scoreVal.substring(1)) {
                    pass = true;
                  }
                  break;
                case '=':
                  if (_scoreVar <= _scoreVal.substring(2)) {
                    pass = true;
                  }
                  break;
              }
              break;

            case '!':
              if (comparatorB == '=') {
                if (_scoreVar != _scoreVal.substring(2)) {
                  pass = true;
                }
              }
              break;
          }

          break;

        case 'onItemInInventory':
          pass = true;
          var itemsInInventory: any = [];
          // check items
          this.playerSessionService.sessionData?.inventory?.forEach(
            (item: any, index: number) => {
              itemsInInventory.push(item.id);
            }
          );

          let eventItems = this.getEventVar(event.vars, 'items');
          eventItems.forEach((itemId: any, item: any) => {
            if (itemsInInventory.indexOf(itemId.id) == -1) {
              pass = false;
            }
          });

          break;
*/
        case 'onTimeout':
          if (ignoreTriggers.indexOf(trigger) == -1) {
            var delay = this.getEventVar(event.vars, 'seconds');

            delay = this.parseVars(delay);
            hasTimeout = delay * 1000;
          }
          break;

        case 'onInterval':
          //console.log(event.eventVars);
          if (ignoreTriggers.indexOf(trigger) == -1) {
            var delay = this.getEventVar(event.vars, 'seconds');
            delay = this.parseVars(delay);
            hasInterval = delay * 1000;
          }
          break;

        case 'onVarChange':
          //console.log(event.eventVars);
          if (ignoreTriggers.indexOf(trigger) == -1) {
            if (!event?.registered) {
              isVarListener = true;
            } else {
              pass = false;
            }
          }
          break;

        /*
        case 'onQuizScore':
          // console.log(event.vars);
          // console.log(this.playerSessionService.sessionData);
          let score = this.getQuizScore(component);
          pass = false;
          var _val = this.getEventVar(event.vars, 'score');
          var comparator = _val.charAt(0);
          switch (comparator) {
            default:
              // console.log('comparing ' + _var + ' == ' + _val);
              if (score == _val) {
                pass = true;
              }
              break;
            case '>':
              if (score > _val.substring(1)) {
                pass = true;
              }
              break;
            case '<':
              if (score < _val.substring(1)) {
                pass = true;
              }
              break;
            case '>=':
              if (score >= _val.substring(2)) {
                pass = true;
              }
              break;
            case '<=':
              if (score <= _val.substring(2)) {
                pass = true;
              }
              break;
            case '!=':
              if (score != _val.substring(2)) {
                pass = true;
              }
              break;
          }
          break;*/
        // key press
        case 'onKeyPress':
          pass = false;
          if (
            trigger &&
            trigger == 'keyPress' &&
            triggerData &&
            triggerData.key &&
            triggerData.key == this.getEventVar(event.vars, 'key')
          ) {
            pass = true;
          }
          break;
      }

      // check the conditions
      if (this.eventConditions.indexOf(event.id) !== -1 && pass == true) {
        pass = this.checkCondition(event, trigger, component, triggerData);
      }

      passes.push(pass);
    });

    // check the trigger..
    if (trigger == 'onComplete') {
      let hasOnComplete = false;
      action.events.forEach((event: any, eIndex: number) => {
        if (event.id == 'onComplete') {
          hasOnComplete = true;
        }
      });
      if (!hasOnComplete) {
        passes.push(false);
      }
    }

    // check if they all pass
    passes.forEach((p: any) => {
      if (p == false) {
        pass = false;
      }
    });

    //$timeout(function () {
    let doTheAction = (actions: any, pass: any) => {
      if (pass == true) {
        actions.actions.forEach((action: any) => {
          switch (action.id) {
            case 'restart':
              this.restart();
              break;

            case 'addToInventory':
              switch (component.type) {
                case 'pick':
                  var picked = this.getPicked(component);

                  // check bu it not item..
                  let inventoryItemIds: any = [];
                  this.playerSessionService.sessionData.inventory.forEach(
                    (item: any) => {
                      inventoryItemIds.push(item.id);
                    }
                  );

                  picked.forEach((item: any) => {
                    if (inventoryItemIds.indexOf(item.id) == -1) {
                      this.playerSessionService.sessionData.inventory.push(
                        item
                      );
                    }
                  });

                  break;
              }
              break;
            case 'addItemToInventory':
              var items = this.getActionVar(action.vars, 'items');
              // check bu it not item..
              let inventoryItemIds: any = [];
              this.playerSessionService.sessionData.inventory.forEach(
                (_item: any) => {
                  inventoryItemIds.push(_item.id);
                }
              );

              items.forEach((item: any) => {
                if (inventoryItemIds.indexOf(item.id) == -1) {
                  // get the item by id?
                  var scenarioItem = this.getInventoryItem(item);
                  this.playerSessionService.sessionData.inventory.push(
                    scenarioItem
                  );
                }
              });

              /*
              var taskId = component.uId;

              // which inventory?
              var inventoryIndex = null;
              if (!this.addedItems[action.vars.inventoryId]) {
                this.addedItems[action.vars.inventoryId] = 0;
              }
              this.scenarioData.inventorys.forEach(function (inventory, index) {
                if (inventory.inventory_id == action.vars.inventoryId) {
                  inventoryIndex = index;
                }
              });

              action.vars.items.forEach(function (item) {
                // add straight to inventory
                if (
                  !this.scenarioData.inventorys[inventoryIndex].config.sections[0]
                    .items
                ) {
                  this.scenarioData.inventorys[
                    inventoryIndex
                  ].config.sections[0].items = [];
                }

                // is this item already in the inventory?
                var hasItem = false;
                this.scenarioData.inventorys[
                  inventoryIndex
                ].config.sections[0].items.forEach(function (tItem, tIindex) {
                  if (tItem.id == item) {
                    hasItem = true;
                  }
                });

                if (hasItem == false) {
                  this.scenarioData.inventorys[
                    inventoryIndex
                  ].config.sections[0].items.push(
                    this.playerSessionService.sessionDataItemsById[item]
                  );
                  this.addedItems[action.vars.inventoryId]++;
                }
              });
              /*
                            break;
                    }*/
              break;

            case 'removeItemFromInventory':
              var items = this.getActionVar(action.vars, 'items');
              items.forEach((item: any) => {
                let deleteDex = -1;

                this.playerSessionService.sessionData.inventory.forEach(
                  (inventoryItem: any, index: any) => {
                    if (inventoryItem.id == item.id) {
                      deleteDex = index;
                    }
                  }
                );
                if (deleteDex > -1) {
                  this.playerSessionService.sessionData.inventory.splice(
                    deleteDex,
                    1
                  );
                }
              });

              /*
              var taskId = component.uId;

              // which inventory?
              var inventoryIndex = null;
              this.scenarioData.inventorys.forEach(function (inventory, index) {
                if (inventory.inventory_id == action.vars.inventoryId) {
                  inventoryIndex = index;
                }
              });

              action.vars.items.forEach(function (item) {
                // add straight to inventory
                this.scenarioData.inventorys[
                  inventoryIndex
                ].config.sections[0].items.forEach(function (
                  __item,
                  __itemIndex
                ) {
                  if (__item.id == item) {
                    // remove it..
                    this.scenarioData.inventorys[
                      inventoryIndex
                    ].config.sections[0].items.splice(__itemIndex, 1);
                  }
                });
              });
*/
              break;
            case 'showFeedback':
              component.data.feedback = this.parseVars(
                this.getActionVar(action.vars, 'feedback')
              );

              /*
              if (!this.activeFeedbacks) {
                this.activeFeedbacks = [];
              }
              var feedback = {
                title: action.vars.title,
                message: action.vars.message,
                type: action.vars.type,
              };
              this.activeFeedbacks.push(feedback);
*/
              break;

            case 'showAlert':
              let msg = this.getActionVar(action.vars, 'message');
              if (msg) {
                msg = this.parseVars(msg);
              } else {
                msg = null;
              }
              let title = this.getActionVar(action.vars, 'title');
              let icon = this.getActionVar(action.vars, 'type');
              let position = this.getActionVar(action.vars, 'position');

              let hideConfirmButton = this.getActionVar(
                action.vars,
                'hideConfirmButton'
              );
              if (!hideConfirmButton) {
                hideConfirmButton = false;
              }
              let autoClose = this.getActionVar(action.vars, 'autoClose');
              if (autoClose) {
                autoClose = autoClose * 1000;
              }
              let toast = this.getActionVar(action.vars, 'toast');
              let backdrop = true;

              /*if (this.publishedConfig.disableAlerts != 'yes') {
               
                if (action.vars.position == 'top-end') {
                  backdrop = null;
                }*/

              if (icon == 'blank') {
                icon = '';
              }
              Swal.fire({
                customClass: {
                  popup: 'ys-swal scenario-ui-' + this.scenario.id,
                  confirmButton: 'btn-choice btn-ys btn',
                },
                buttonsStyling: false,
                title: this.parseVars(title),
                icon: icon,
                position: position,
                html: msg,
                backdrop: backdrop,
                showConfirmButton: !hideConfirmButton,
                timer: autoClose,
                toast: toast,
                timerProgressBar: true,
              });
              // }

              break;
            case 'showModal':
              this.onShowModal(
                this.getActionVar(action.vars, 'title'),
                this.getActionVar(action.vars, 'content')
              );
              break;

            case 'goToUrl':
              if (this.getActionVar(action.vars, 'target') == '_blank') {
                window.open(this.getActionVar(action.vars, 'url'), '_blank');
              } else {
                window.location = this.getActionVar(action.vars, 'url');
              }
              break;

            case 'showMedia':
              // allow for current component media
              var useCurrent = this.getActionVar(action.vars, 'useCurrent');
              if (useCurrent) {
                var mediaUrl = '';
                switch (component.type) {
                  case 'image':
                    mediaUrl = component.content.url;
                    break;
                }
                if (mediaUrl) {
                  this.onShowMedia(mediaUrl);
                }
              } else {
                this.onShowMedia(this.getActionVar(action.vars, 'media'));
              }

              break;
            case 'controlMedia':
              var componentId: any = this.getActionVar(
                action.vars,
                'component'
              );
              var control: any = this.getActionVar(action.vars, 'mediaControl');
              let videoElem: any = document.getElementById(
                'video-' + componentId
              );
              let audioElem: any = document.getElementById(
                'audio-' + componentId
              );
              let mediaElem: any;
              if (audioElem) {
                mediaElem = audioElem;
              }
              if (videoElem) {
                mediaElem = videoElem;
              }
              if (mediaElem) {
                switch (control.control) {
                  case 'play':
                    mediaElem.play();
                    break;
                  case 'pause':
                    mediaElem.pause();
                    break;
                  case 'toggle':
                    if (!mediaElem.paused) {
                      mediaElem.pause();
                    } else {
                      mediaElem.play();
                    }
                    break;
                  case 'restart':
                    mediaElem.pause();
                    mediaElem.currentTime = 0;
                    mediaElem.play();
                    break;
                  case 'skipTo':
                    mediaElem.pause();
                    mediaElem.currentTime = control.time;
                    mediaElem.play();
                    break;
                  case 'skipForward':
                    mediaElem.pause();
                    mediaElem.currentTime += +control.time;
                    mediaElem.play();
                    break;
                  case 'skipBack':
                    mediaElem.pause();
                    mediaElem.currentTime += +control.time;
                    mediaElem.play();
                    break;
                }
              }

              break;
            case 'goToStep':
              // do we need to do it instantly?
              let stepId = this.getActionVar(action.vars, 'stepId');

              if (
                this.getActionVar(action.vars, 'priority') != 'onContinue' ||
                hasTimeout > 0 ||
                hasInterval > 0
              ) {
                this.goToStep(stepId);
              } else {
                if (component.type == 'makeChoice') {
                  //this.activeChoice.target_step_id = action.vars.stepId;
                } else if (component.type == 'step') {
                  //this.goToStepAction = action.vars.stepId;
                } else {
                  // add this to the stack..
                  let toToStepFn = (stepId: string) => {
                    this.goToStep(stepId);
                  };
                  var fn = this.wrapFunction(toToStepFn, this, [stepId]);
                  this.actionQueue.push(fn);
                }
              }
              break;
            case 'addToScore':
              this.playerSessionService.sessionData.score += this.getActionVar(
                action.vars,
                'score'
              );
              this.playerSessionService.variablesChanges.next(true);

              // update the SCORM score?
              if (window.API) {
                window.API.LMSSetValue(
                  'cmi.core.score.raw',
                  this.playerSessionService.sessionData.score
                );
                window.API.LMSCommit('');
              }

              break;
            case 'setVarForm':
              var fieldValue = this.getFieldValue(
                component,
                this.getEventVar(action.vars, 'field')
              );

              var _fieldVar = this.getActionVar(action.vars, 'var');

              this.setSessionVar(
                this.playerSessionService.sessionData,
                _fieldVar,
                fieldValue
              );
              break;
            case 'setVar':
              let _val = this.getActionVar(action.vars, 'val');
              let _var = this.getActionVar(action.vars, 'var');
              _val = this.parseVars(_val);
              switch (_val) {
                default:
                  // do we have an 'operator'
                  var twoChar = _val.substring(2, 0);

                  if (twoChar == '+=') {
                    /*this.playerSessionService.sessionData.config.variables[
                      _var
                    ] = parseInt(
                      this.playerSessionService.sessionData.config.variables[
                        _var
                      ]
                    );*/

                    // TODO just concatenate strings if possible?

                    this.setSessionVar(
                      this.playerSessionService.sessionData,
                      _var,
                      +this.getSessionVar(
                        this.playerSessionService.sessionData,
                        _var
                      ) + parseFloat(this.parseSetVar(_val.substring(2)))
                    );
                  } else if (twoChar == '-=') {
                    /*
                    this.playerSessionService.sessionData.config.variables[
                      _var
                    ] = parseInt(
                      this.playerSessionService.sessionData.config.variables[
                        _var
                      ]
                    );
                    this.playerSessionService.sessionData.config.variables[
                      _var
                    ] -= parseInt(this.parseSetVar(_val.substring(2)));
                    */
                    this.setSessionVar(
                      this.playerSessionService.sessionData,
                      _var,
                      +this.getSessionVar(
                        this.playerSessionService.sessionData,
                        _var
                      ) - parseFloat(this.parseSetVar(_val.substring(2)))
                    );
                  } else {
                    /*
                    this.playerSessionService.sessionData.config.variables[
                      _var
                    ] = this.parseSetVar(_val);*/
                    this.setSessionVar(
                      this.playerSessionService.sessionData,
                      _var,
                      _val
                    );
                  }

                  // check for maths
                  let randRegex = /=rand\(([-+]?[0-9]+),([-+]?[0-9]+)\)/g;
                  const matched = [..._val.matchAll(randRegex)];
                  matched.forEach((match) => {
                    this.setSessionVar(
                      this.playerSessionService.sessionData,
                      _var,
                      Math.floor(
                        Math.random() * (+match[2] - +match[1] + 1) + +match[1]
                      )
                    );
                  });

                  break;
                case '--' /*
                  this.playerSessionService.sessionData.config.variables[_var] =
                    parseInt(
                      this.playerSessionService.sessionData.config.variables[
                        _var
                      ]
                    );
                  this.playerSessionService.sessionData.config.variables[_var] =
                    parseInt(
                      this.playerSessionService.sessionData.config.variables[
                        _var
                      ]
                    ) - 1;*/:
                  this.setSessionVar(
                    this.playerSessionService.sessionData,
                    _var,

                    +this.getSessionVar(
                      this.playerSessionService.sessionData,
                      _var
                    ) - 1
                  );
                  break;
                case '++':
                  this.setSessionVar(
                    this.playerSessionService.sessionData,
                    _var,
                    +this.getSessionVar(
                      this.playerSessionService.sessionData,
                      _var
                    ) + 1
                  );

                  /* this.playerSessionService.sessionData.config.variables[_var] =
                    parseInt(
                      this.playerSessionService.sessionData.config.variables[
                        _var
                      ]
                    );
                  this.playerSessionService.sessionData.config.variables[_var] =
                    parseInt(
                      this.playerSessionService.sessionData.config.variables[
                        _var
                      ]
                    ) + 1;*/
                  break;
              }
              /*if (
                this.getSessionVar(this.playerSessionService.sessionData, _var) ===
                0
              ) {
                this.playerSessionService.sessionData.config.variables[_var] =
                  '0';
              }*/
              break;
            case 'playSound':
              var soundType = 'step';
              if (trigger == 'choice') {
                soundType = 'choice';
              }
              this.playAudio(
                environment.mediaUrl +
                  '/assets/' +
                  this.getActionVar(action.vars, 'sound'),
                soundType
              );
              break;
            case 'playSoundtrack':
              this.audioObjs.forEach((_audio: any) => {
                if (_audio.obj.id == 'audioSoundtrack') {
                  _audio.obj.play();
                }
              });
              break;
            case 'stopSounds':
              this.stopAudio(this.getActionVar(action.vars, 'stop'));
              break;
            case 'stopSoundtrack':
              this.audioObjs.forEach((_audio: any) => {
                if (_audio.obj.id == 'audioSoundtrack') {
                  _audio.obj.pause();
                }
              });
              break;
            case 'endActivity':
              this.endActivity.next(true);
              break;
            case 'end':
              // end the scenario
              this.onEnd();
              break;
            case 'hideChoice':
              this.sessionData.hiddenChoices.push(action.vars.choice);
              break;

            case 'startCountdown':
              if (this.activeCountdown == null) {
                this.activeCountdown = {};
                this.activeCountdown.timer = this.getActionVar(
                  action.vars,
                  'timer'
                );
                this.activeCountdown.name = this.getActionVar(
                  action.vars,
                  'name'
                );
                this.activeCountdown.description = this.getActionVar(
                  action.vars,
                  'description'
                );
                this.activeCountdown.timeLeft = this.getActionVar(
                  action.vars,
                  'timer'
                );
                this.activeCountdownClock.next(this.activeCountdown.timeLeft);
                //this.activeCountdown.soundtrack = action.vars.soundtrack;

                // play the soundtrack
                var soundtrack = this.getActionVar(action.vars, 'soundtrack');
                if (soundtrack) {
                  this.playAudio(
                    environment.mediaUrl + '/assets/' + soundtrack,
                    'countdown'
                  );
                }

                this.activeCountdown.showInfo = true;
                this.activeCountdown.action = action;

                Swal.fire({
                  customClass: { container: 'ys-swal' },
                  title: this.activeCountdown.name,
                  icon: 'warning',
                  position: 'center',
                  html: this.activeCountdown.description,
                });

                this.activeCountdownActive.next(true);
                this.activeCountdown.timeout = setTimeout(() => {
                  //this.showChoices.step.status = true;
                  var stepId = this.getActionVar(
                    this.activeCountdown.action.vars,
                    'stepId'
                  );
                  this.goToStep(stepId);
                  clearTimeout(this.activeCountdown.timeout);
                  clearInterval(this.activeCountdown.timeoutInterval);
                  this.activeCountdown = null;

                  this.activeCountdownClock.next(0);
                  this.activeCountdownActive.next(false);
                  // stop the sound
                  this.stopAudio('countdown');
                }, this.activeCountdown.timer * 1000);

                this.activeCountdown.timeoutInterval = setInterval(() => {
                  this.activeCountdown.timeLeft--;
                  this.activeCountdownClock.next(this.activeCountdown.timeLeft);
                }, 1000);
              }
              break;
            case 'setCountdownTime':
              if (this.activeCountdown != null) {
                this.activeCountdown.timeLeft = this.getActionVar(
                  action.vars,
                  'time'
                );
              }
              break;
            case 'endCountdown':
              if (this.activeCountdown != null) {
                clearTimeout(this.activeCountdown.timeout);
                clearInterval(this.activeCountdown.timeoutInterval);
                this.activeCountdown = null;
                this.activeCountdownActive.next(false);
                this.activeCountdownClock.next(0);
                // stop the sound
                this.stopAudio('countdown');
              }
              break;
            case 'setRootStem':
              //this.chatTask.rootStem = action.vars.stem;
              break;
            case 'resetRootStem':
              // this.chatTask.rootStem = null;
              // this.chatTask.activeStems = this.chatTask.startStem;
              break;
            case 'setBgImg':
              /*this.activeContent.container.settings.bgImage =
                action.vars.image;*/

              this.setStepBg(this.getActionVar(action.vars, 'image'));
              this.setScenarioBg(this.getActionVar(action.vars, 'image'));

              break;
            case 'setChatImg':
              /*   angular
                .element('.chatImg')
                .attr(
                  'src',
                  this.basePath +
                    'assets/' +
                    $filter('thumbnail')(action.vars.image, 'medium')
                );
              var el = document.getElementById('chat-img-' + component.uId);
              el.style.animation = 'none';
              el.offsetHeight;
              el.style.animation = null;
*/
              break;
            case 'setScormVar':
              if (window.API) {
                window.API.LMSSetValue(
                  this.getActionVar(action.vars, 'var'),
                  this.getActionVar(action.vars, 'val')
                );
                window.API.LMSCommit('');
              }
              break;
            case 'sendXapiStatement':
              if (typeof lrs !== 'undefined' && typeof TinCan !== 'undefined') {
                let xapiVars = this.getActionVar(action.vars, 'xapiStatement');
                let theStatement = JSON.parse(xapiVars.statement);

                //result
                let tinCanStatement: any = {
                  actor: xapiActor,
                  verb: theStatement.verb,
                  object: theStatement.object,
                };

                if (xapiVars.result) {
                  tinCanStatement.result = {
                    completion: tinCanStatement.resultCompletion,
                    success: tinCanStatement.resultSuccess,
                    score: {
                      raw: this.playerSessionService.sessionData.score,
                    },
                    duration: dayjs
                      .duration(
                        this.playerSessionService.sessionData.duration,
                        's'
                      )
                      .toISOString(),
                  };
                }

                const statement = new TinCan.Statement(tinCanStatement);

                lrs.saveStatement(statement, {
                  callback: function (err: any, xhr: any) {
                    if (err !== null) {
                      if (xhr !== null) {
                        console.log(
                          'Failed to save statement: ' +
                            xhr.responseText +
                            ' (' +
                            xhr.status +
                            ')'
                        );
                        // TODO: do something with error, didn't save statement
                        return;
                      }

                      console.log('Failed to save statement: ' + err);
                      // TODO: do something with error, didn't save statement
                      return;
                    }

                    console.log('Statement saved');
                    // TOOO: do something with success (possibly ignore)
                  },
                });
              }
              break;
            case 'setInventoryItemValue':
              /*
              action.vars.items.forEach(function (itemId) {
                this.updateItem(
                  itemId,
                  action.vars.field,
                  action.vars.val
                );
              });
*/
              break;
            case 'showInventory':
              this.onShowInventory();
              break;

            case 'hideInventory':
              this.onHideInventory();
              break;

            case 'goToFirstChoice':
              // do we need to do it instantly?

              if (
                this.getActionVar(action.vars, 'priority') != 'onContinue' ||
                hasTimeout > 0 ||
                hasInterval > 0
              ) {
                this.goToFirstChoice();
              } else {
                if (component.type == 'makeChoice') {
                  //this.activeChoice.target_step_id = action.vars.stepId;
                } else if (component.type == 'step') {
                  //this.goToStepAction = action.vars.stepId;
                } else {
                  // add this to the stack..
                  let toToChoiceFn = (stepId: string) => {
                    this.goToFirstChoice();
                  };
                  var fn = this.wrapFunction(toToChoiceFn, this, [stepId]);
                  this.actionQueue.push(fn);
                }
              }
              break;

            case 'updateComponent':
              var componentId: any = this.getActionVar(
                action.vars,
                'component'
              );
              var newStyles: any = this.getActionVar(
                action.vars,
                'componentSetting'
              );
              this.updateComponent.next({
                id: componentId,
                newData: newStyles,
              });
              break;

            case 'hideComponent':
              var componentId: any = this.getActionVar(
                action.vars,
                'component'
              );
              setTimeout(() => {
                this.updateComponent.next({
                  id: componentId,
                  newData: { hidden: true },
                });
              }, 10);

              break;

            case 'updateAvatar':
              if (component.type == 'chat') {
                var image: any = this.getActionVar(action.vars, 'image');
                component.data.avatar = image;
              }
              break;

            case 'showComponent':
              var componentId: any = this.getActionVar(
                action.vars,
                'component'
              );
              this.updateComponent.next({
                id: componentId,
                newData: { show: true },
              });
              break;
            case 'prevStep':
              this.onPreviousStep();
              break;
            case 'revertPosition':
              if (component && component.startPos) {
                let componentCopy = JSON.parse(JSON.stringify(component));
                component.pos.x = componentCopy.startPos.x;
                component.pos.y = componentCopy.startPos.y;
              }
              break;
            case 'snapToComponent':
              var componentId: any = this.getActionVar(
                action.vars,
                'component'
              );
              let targetComponent;
              if (triggerData?.components) {
                triggerData.components.forEach((_component: any) => {
                  if (_component.id == componentId) {
                    targetComponent = _component;
                  }
                });
              }
              if (component && targetComponent) {
                let componentCopy = JSON.parse(JSON.stringify(targetComponent));

                let position = this.getActionVar(action.vars, 'position');

                let x = componentCopy.pos.x;
                let y = componentCopy.pos.y;
                switch (position) {
                  case 'centre':
                  default:
                    x =
                      componentCopy.pos.x +
                      componentCopy.width / 2 -
                      component.width / 2;
                    y =
                      componentCopy.pos.y +
                      componentCopy.height / 2 -
                      component.height / 2;
                    break;
                  case 'top-centre':
                    x =
                      componentCopy.pos.x +
                      componentCopy.width / 2 -
                      component.width / 2;
                    y = componentCopy.pos.y;
                    break;
                  case 'top-left':
                    x = componentCopy.pos.x;
                    y = componentCopy.pos.y;
                    break;
                  case 'top-right':
                    x =
                      componentCopy.pos.x +
                      componentCopy.width -
                      component.width;
                    y = componentCopy.pos.y;
                    break;
                  case 'centre-left':
                    x = componentCopy.pos.x;
                    y =
                      componentCopy.pos.y +
                      componentCopy.height / 2 -
                      component.height / 2;
                    break;
                  case 'centre-right':
                    x =
                      componentCopy.pos.x +
                      componentCopy.width -
                      component.width;
                    y =
                      componentCopy.pos.y +
                      componentCopy.height / 2 -
                      component.height / 2;
                    break;
                  case 'bottom-centre':
                    x =
                      componentCopy.pos.x +
                      componentCopy.width / 2 -
                      component.width / 2;
                    y =
                      componentCopy.pos.y +
                      componentCopy.height -
                      component.height;
                    break;
                  case 'bottom-left':
                    x = componentCopy.pos.x;
                    y =
                      componentCopy.pos.y +
                      componentCopy.height -
                      component.height;
                    break;
                  case 'bottom-right':
                    x =
                      componentCopy.pos.x +
                      componentCopy.width -
                      component.width;
                    y =
                      componentCopy.pos.y +
                      componentCopy.height -
                      component.height;
                    break;
                }

                component.pos.x = x;
                component.pos.y = y;
              }
              break;
            case 'confetti':
              this.confettiService.throw();
              break;
            case 'set360Scene':
              this.current360Scene.next(
                this.getActionVar(action.vars, 'scene')
              );
              break;
          }
        });
        // end pass
      }
      return pass;
    };

    if (hasTimeout == 0 && hasInterval == 0 && !isVarListener) {
      doTheAction(action, pass);
    } else if (hasTimeout > 0) {
      // console.log('we have a timeout!');
      var actionTimeout = setTimeout(() => {
        // check the pass here...
        pass = this.checkConditions(action, trigger, component, triggerData);
        doTheAction(action, pass);
      }, hasTimeout);

      this.stepTimers.push(actionTimeout);
    } else if (hasInterval > 0) {
      var actionInterval = setInterval(() => {
        // check the pass here...
        pass = this.checkConditions(action, trigger, component, triggerData);
        console.log(pass);
        doTheAction(action, pass);
      }, hasInterval);

      this.stepIntervals.push(actionInterval);
    } else if (isVarListener) {
      var stepListener = () => {
        setTimeout(() => {
          // check the pass here...
          pass = this.checkConditions(action, trigger, component, triggerData);
          doTheAction(action, pass);
        }, 10);
      };
      this.stepVarListeners.push(stepListener);
    }
    //  }, hasTimeout);

    return pass;
  }

  checkConditions(
    action: any,
    type: string,
    component: any,
    triggerData?: any
  ) {
    let passes: any = [];
    let pass = false;
    action.events.forEach((event: any, eIndex: number) => {
      pass = this.checkCondition(event, type, component, triggerData);
      passes.push(pass);
    });
    // check if they all pass
    pass = true;
    passes.forEach((p: any) => {
      if (p == false) {
        pass = false;
      }
    });
    return pass;
  }

  checkCondition(event: any, type: string, component: any, triggerData?: any) {
    let pass = true;
    switch (event.id) {
      case 'onOverlaps':
        pass = false;
        var droppedOn = this.getEventVar(event.vars, 'component');
        if (droppedOn) {
          // check it
          // are we dropped over anything?
          let collisions: any = [];
          triggerData?.components.forEach((_component: any) => {
            if (_component.id != component.id) {
              if (this.doComponentsCollide(component, _component)) {
                collisions.push(_component.id);
              }
            }
          });

          if (collisions.indexOf(droppedOn) !== -1) {
            pass = true;
          }
        }
        break;

      case 'onNotOverlaps':
        pass = false;
        var droppedOn = this.getEventVar(event.vars, 'component');
        if (droppedOn) {
          // check it
          // are we dropped over anything?
          let collisions: any = [];
          triggerData?.components.forEach((_component: any) => {
            if (_component.id != component.id) {
              if (this.doComponentsCollide(component, _component)) {
                collisions.push(_component.id);
              }
            }
          });

          if (collisions.indexOf(droppedOn) == -1) {
            pass = true;
          }
        }
        break;
      case 'onVar':
        // console.log(event.vars);
        // console.log(this.playerSessionService.sessionData);
        pass = false;
        var _val = this.getEventVar(event.vars, 'val');
        var _var = this.getSessionVar(
          this.playerSessionService.sessionData,
          this.getEventVar(event.vars, 'var')
        );

        //parse the var here?
        _val = this.parseVars(_val);
        var comparator = _val.charAt(0);
        var comparatorB = _val.charAt(1);
        switch (comparator) {
          default:
            // console.log('comparing ' + _var + ' == ' + _val);
            if (_var == _val) {
              pass = true;
            }
            break;
          case '>':
            switch (comparatorB) {
              default:
                if (_var > _val.substring(1)) {
                  pass = true;
                }
                break;
              case '=':
                if (_var >= _val.substring(2)) {
                  pass = true;
                }
                break;
            }
            break;
          case '<':
            switch (comparatorB) {
              default:
                if (_var < _val.substring(1)) {
                  pass = true;
                }
                break;
              case '=':
                if (_var <= _val.substring(2)) {
                  pass = true;
                }
                break;
            }
            break;

          case '!':
            if (comparatorB == '=') {
              if (_var != _val.substring(2)) {
                pass = true;
              }
            }
            break;
        }

        break;

      case 'onScore':
        // console.log(event.vars);
        // console.log(this.playerSessionService.sessionData);
        pass = false;
        var _scoreVal = this.getEventVar(event.vars, 'val');
        var _scoreVar = this.playerSessionService.sessionData.score;
        var comparator = _scoreVal.charAt(0);
        var comparatorB = _scoreVal.charAt(1);
        switch (comparator) {
          default:
            // console.log('comparing ' + _scoreVar + ' == ' + _scoreVal);
            if (_scoreVar == _scoreVal) {
              pass = true;
            }
            break;
          case '>':
            switch (comparatorB) {
              default:
                if (_scoreVar > _scoreVal.substring(1)) {
                  pass = true;
                }
                break;
              case '=':
                if (_scoreVar >= _scoreVal.substring(2)) {
                  pass = true;
                }
                break;
            }
            break;
          case '<':
            switch (comparatorB) {
              default:
                if (_scoreVar < _scoreVal.substring(1)) {
                  pass = true;
                }
                break;
              case '=':
                if (_scoreVar <= _scoreVal.substring(2)) {
                  pass = true;
                }
                break;
            }
            break;

          case '!':
            if (comparatorB == '=') {
              if (_scoreVar != _scoreVal.substring(2)) {
                pass = true;
              }
            }
            break;
        }

        break;

      case 'onItemInInventory':
        pass = true;
        var itemsInInventory: any = [];
        // check items
        this.playerSessionService.sessionData?.inventory?.forEach(
          (item: any, index: number) => {
            itemsInInventory.push(item.id);
          }
        );

        let eventItems = this.getEventVar(event.vars, 'items');
        eventItems.forEach((itemId: any, item: any) => {
          if (itemsInInventory.indexOf(itemId.id) == -1) {
            pass = false;
          }
        });

        break;
      case 'onQuizScore':
        let score = this.getQuizScore(component);
        pass = false;
        var _val = this.getEventVar(event.vars, 'score');
        var comparator = _val.charAt(0);
        switch (comparator) {
          default:
            // console.log('comparing ' + _var + ' == ' + _val);
            if (score == _val) {
              pass = true;
            }
            break;
          case '>':
            if (score > _val.substring(1)) {
              pass = true;
            }
            break;
          case '<':
            if (score < _val.substring(1)) {
              pass = true;
            }
            break;
          case '>=':
            if (score >= _val.substring(2)) {
              pass = true;
            }
            break;
          case '<=':
            if (score <= _val.substring(2)) {
              pass = true;
            }
            break;
          case '!=':
            if (score != _val.substring(2)) {
              pass = true;
            }
            break;
        }
        break;
    }
    return pass;
  }

  restart() {
    // clear session
    // reset scenario components
    this.restarted.next(true);
    // go to step 0
    this.playerSessionService.initSession(
      this.scenario,
      null,
      this.playerSessionService.sessionData.id
    );
    this.playerSessionService.variablesChanges.next(true);

    this.goToStep(this.scenarioService.getStartStep(this.scenario)?.step_id);
  }

  parseVars(content: string) {
    content = content + '';
    if (content && this.playerSessionService.sessionData) {
      let regex = /{(.*?)}/g;

      let varsInText = [...content.matchAll(regex)];

      varsInText.forEach((varLabel) => {
        let sessionVar = this.getSessionVar(
          this.playerSessionService.sessionData,
          varLabel[1]
        );
        if (sessionVar) {
          content = content.replace(varLabel[0], sessionVar);
        }
      });

      regex = /{var\.(.*?)}/g;

      varsInText = [...content.matchAll(regex)];

      varsInText.forEach((varLabel) => {
        let sessionVar = this.getSessionVar(
          this.playerSessionService.sessionData,
          varLabel[1]
        );
        if (sessionVar) {
          content = content.replace(varLabel[0], sessionVar);
        }
      });
    }

    return content;
  }

  parseSetVar(val: string) {
    switch (val) {
      default:
        return val;
        break;
    }
  }

  playAudio(audio: string, audioType: string, loop?: any) {
    let audioObj = new Audio(audio);
    let volume;
    if (this.mutedState == true) {
      volume = 0;
    } else {
      volume = 1;
    }
    audioObj.volume = volume;
    audioObj.className = 'scAudio';
    audioObj.muted = this.mutedState;
    if (audioType == 'soundtrack') {
      audioObj.id = 'audioSoundtrack';
    }
    if (loop) {
      audioObj.loop = loop;
    }

    audioObj.play().catch((error) => {
      if (audioType == 'soundtrack') {
        // do something here...
      }
    });
    var audioData = {
      obj: audioObj,
      type: audioType,
    };
    this.audioObjs.push(audioData);
  }

  stopAudio(audioType: string) {
    this.audioObjs.forEach((_audio: any) => {
      if (_audio.type == audioType || audioType == 'all') {
        _audio.obj.pause();
      }
    });
  }
  removeAudio() {
    this.audioObjs.forEach((_audio: any) => {
      _audio.obj = null;
    });
    this.audioObjs = [];
  }

  getActionVar(vars: [], id: string): any {
    let value = '';
    vars.forEach((actionVar: any) => {
      if (actionVar.name == id) {
        value = actionVar.value;
      }
    });

    return value;
  }

  getEventVar(vars: [], id: string): any {
    let value = '';
    vars.forEach((eventVar: any) => {
      if (eventVar.name == id) {
        value = eventVar.value;
      }
    });

    return value;
  }

  executeNextInActionQueue() {
    if (this.actionQueue.length > 0) {
      // execute the fn and remove it from the queue
      this.actionQueue.shift()();
    }
  }
  executeActionQueue() {
    for (var i = 0; i < this.actionQueue.length; i++) {
      this.actionQueue.shift()();
    }
  }
  wrapFunction(fn: any, context: any, params: any) {
    return function () {
      fn.apply(context, params);
    };
  }

  goToStep(stepId: string) {
    this.clearStepTimeouts();
    this.clearStepIntervals();
    this.clearStepVarListeners();
    // reset the hidden state of the choices component here?
    this.scenario.steps.forEach((step: any) => {
      if (step.step_id == this.currentStepId) {
        step.content.components.forEach((component: any) => {
          if (component.type == 'choices') {
            component.hidden = component.hiddenTillEnd;
          }
        });
      }
    });

    this.currentStepId = stepId;
    this.currentStep.next(stepId);
  }

  goToFirstChoice() {
    // get first choice if any
    let foundFirst: any = null;

    // get step choices from step component?
    this.scenario.steps.forEach((step: any) => {
      if (step.step_id == this.currentStepId) {
        // this is the step..
        step.content.components.forEach((component: any) => {
          if (component.type == 'choices') {
            var availableChoices: any = [];
            component.choices.forEach((choice: any) => {
              if (!this.checkChoice(choice)) {
                availableChoices.push(choice);
              }
            });
            // should the choice be show?
            if (availableChoices[0]) {
              foundFirst = availableChoices[0];
            }
          }
        });
      }
    });
    if (foundFirst && foundFirst.target_step_id) {
      this.makeChoice.next(foundFirst);
      // this.goToStep(foundFirst.target_step_id);
    }
  }

  goToNthChoice(nth: number) {
    // get first choice if any
    let foundFirst: any = null;
    // get step choices from step component?
    this.scenario.steps.forEach((step: any) => {
      if (step.step_id == this.currentStepId) {
        // this is the step..
        step.content.components.forEach((component: any) => {
          if (component.type == 'choices') {
            var availableChoices: any = [];
            component.choices.forEach((choice: any) => {
              if (!this.checkChoice(choice)) {
                availableChoices.push(choice);
              }
            });
            // should the choice be show?
            if (availableChoices[nth]) {
              foundFirst = availableChoices[nth];
            }
          }
        });
      }
    });
    if (foundFirst && foundFirst.target_step_id) {
      // do the choice actions?
      this.makeChoice.next(foundFirst);
      //this.goToStep(foundFirst.target_step_id);
    }
  }

  getSessionVar(sessionData: any, _var: string) {
    let varVal: any = '';
    sessionData.vars?.forEach((_sessionVar: any) => {
      if (_sessionVar.name == _var) {
        varVal = _sessionVar.value;
      }
    });

    return varVal;
  }
  setSessionVar(sessionData: any, _var: string, value: any) {
    sessionData.vars.forEach((_sessionVar: any) => {
      if (_sessionVar.name == _var) {
        let oldVal = '' + _sessionVar.value;
        _sessionVar.value = value;
        if ('' + oldVal !== '' + value) {
          // the variable has changed
          this.playerSessionService.variablesChanges.next(true);
        }
      }
    });
  }

  getPicked(component: any) {
    let picked: any = [];
    if (!component.type) {
      // assume inventory
      picked = this.playerSessionService.sessionData.inventory.picked;
    } else {
      if (
        !this.playerSessionService.sessionData?.taskResponses ||
        (this.playerSessionService.sessionData?.taskResponses &&
          !this.playerSessionService.sessionData?.taskResponses[component.id])
      ) {
        picked = [];
      } else {
        picked =
          this.playerSessionService.sessionData.taskResponses[component.id][
            this.playerSessionService.sessionData.taskResponses[component.id]
              .length - 1
          ].picked;
      }
    }

    return picked;
  }

  getFieldValue(component: any, field: string) {
    let fieldValue = '';

    if (
      this.playerSessionService.sessionData.taskResponses &&
      this.playerSessionService.sessionData.taskResponses[component.id]
    ) {
      fieldValue =
        this.playerSessionService.sessionData.taskResponses[component.id][
          this.playerSessionService.sessionData.taskResponses[component.id]
            .length - 1
        ].formData[field];
    }
    return fieldValue;
  }

  getQuizScore(component: any) {
    let score = 0;
    if (
      this.playerSessionService.sessionData.taskResponses &&
      this.playerSessionService.sessionData.taskResponses[component.id]
    ) {
      score =
        this.playerSessionService.sessionData.taskResponses[component.id][
          this.playerSessionService.sessionData.taskResponses[component.id]
            .length - 1
        ].quizData.score;
    }
    return score;
  }

  setStepBg(image: string) {
    this.scenario.steps.forEach((step: any) => {
      if (step.step_id == this.currentStepId) {
        step.content.canvas.style.bgImage = image;
      }
    });
  }
  setScenarioBg(image: string) {
    this.scenario.style.bgImage = image;
  }

  preloadAssets() {
    let urls = this.parseImages();
    for (let i = 0; i < urls.length; i++) {
      let img = new Image();
      img.src = urls[i];
      img.onload = () => {
        // console.log('loaded img ' + i);
        //this.loaded();
      };
    }

    // get audio too
    let audioUrls = this.parseAudio();
    for (let i = 0; i < audioUrls.length; i++) {
      let audio = new Audio();
      audio.preload = 'auto';
      audio.addEventListener('canplaythrough', () => {}, false);
      audio.src = environment.mediaUrl + '/assets/' + audioUrls[i];
    }
  }

  parseImages() {
    var m;
    // let rex = /(...\w+).(jpg|png|gif|jpeg)/g;
    let rex = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/g;
    let urls = [];
    while ((m = rex.exec(JSON.stringify(this.scenario)))) {
      urls.push(m[0]);
    }
    urls = urls.filter((value, index, array) => array.indexOf(value) === index);
    return urls;
  }

  parseAudio() {
    var m;
    // let rex = /(...\w+).(jpg|png|gif|jpeg)/g;
    let rex = /(\d+)\/(\w+)\.mp3/gm;
    let urls = [];
    while ((m = rex.exec(JSON.stringify(this.scenario)))) {
      urls.push(m[0]);
    }
    urls = urls.filter((value, index, array) => array.indexOf(value) === index);
    return urls;
  }

  checkChoice(choice: any) {
    let hidden = false;
    if (choice.config) {
      hidden = false;

      if (choice.config.hidden == true) {
        hidden = true;
      }

      if (
        Array.isArray(choice.config.showWhen) &&
        choice.config.showWhen.length > 0
      ) {
        let pass = true;
        let passes: any = [];
        choice.config.showWhen.forEach((condition: any) => {
          if (condition.type == 'score') {
            // try it here
            let theVar = this.playerSessionService.sessionData.score;

            var comparator = condition.data.value.charAt(0);
            var comparatorB = condition.data.value.charAt(1);
            pass = false;
            switch (comparator) {
              default:
                // console.log('comparing ' + theVar + ' == ' + condition.data.value);
                if (theVar == condition.data.value) {
                  pass = true;
                }
                break;
              case '>':
                switch (comparatorB) {
                  default:
                    if (+theVar > +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar >= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;
              case '<':
                switch (comparatorB) {
                  default:
                    if (+theVar < +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar <= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;

              case '!':
                if (comparatorB == '=') {
                  if (theVar != condition.data.value.substring(2)) {
                    pass = true;
                  }
                }
                break;
            }
          }

          if (condition.type == 'var') {
            // try it here
            let theVar = this.getSessionVar(
              this.playerSessionService.sessionData,
              condition.data.variable
            );

            var comparator = condition.data.value.charAt(0);
            var comparatorB = condition.data.value.charAt(1);
            pass = false;
            switch (comparator) {
              default:
                // console.log('comparing ' + theVar + ' == ' + condition.data.value);
                if (theVar == condition.data.value) {
                  pass = true;
                }
                break;
              case '>':
                switch (comparatorB) {
                  default:
                    if (+theVar > +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar >= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;
              case '<':
                switch (comparatorB) {
                  default:
                    if (+theVar < +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar <= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;

              case '!':
                if (comparatorB == '=') {
                  if (theVar != condition.data.value.substring(2)) {
                    pass = true;
                  }
                }
                break;
            }
          }

          if (condition.type == 'score') {
            var comparator = condition.data.value.charAt(0);
            var comparatorB = condition.data.value.charAt(1);
            pass = false;
            switch (comparator) {
              default:
                // console.log('comparing ' + this.playerSessionService.sessionData.score + ' == ' + condition.data.value);
                if (
                  this.playerSessionService.sessionData.score ==
                  condition.data.value
                ) {
                  pass = true;
                }
                break;
              case '>':
                switch (comparatorB) {
                  default:
                    if (
                      this.playerSessionService.sessionData.score >
                      +condition.data.value.substring(1)
                    ) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (
                      this.playerSessionService.sessionData.score >=
                      +condition.data.value.substring(2)
                    ) {
                      pass = true;
                    }
                    break;
                }
                break;
              case '<':
                switch (comparatorB) {
                  default:
                    if (
                      this.playerSessionService.sessionData.score <
                      +condition.data.value.substring(1)
                    ) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (
                      this.playerSessionService.sessionData.score <=
                      +condition.data.value.substring(2)
                    ) {
                      pass = true;
                    }
                    break;
                }
                break;

              case '!':
                if (comparatorB == '=') {
                  if (
                    this.playerSessionService.sessionData.score !=
                    +condition.data.value.substring(2)
                  ) {
                    pass = true;
                  }
                }
                break;
            }
          }

          if (condition.type == 'topVar') {
            // try it here
            let topVar = null;
            let topVarScore = -999999999999999;
            this.playerSessionService.sessionData.vars.forEach(
              (_sessionVar: any) => {
                if (
                  _sessionVar.value > topVarScore &&
                  _sessionVar.name != 'user' &&
                  _sessionVar.name != 'score'
                ) {
                  topVar = _sessionVar.name;
                  topVarScore = _sessionVar.value;
                }
              }
            );
            if (topVar && topVar == condition.data.variable) {
              pass = true;
            } else {
              pass = false;
            }
          }

          if (condition.type == 'bottomVar') {
            // try it here
            let bottomVar = null;
            let bottomVarScore = 999999999999999999999;
            this.playerSessionService.sessionData.vars.forEach(
              (_sessionVar: any) => {
                if (
                  _sessionVar.value < bottomVarScore &&
                  _sessionVar.name != 'user' &&
                  _sessionVar.name != 'score'
                ) {
                  bottomVar = _sessionVar.name;
                  bottomVarScore = _sessionVar.value;
                }
              }
            );
            if (bottomVar && bottomVar == condition.data.variable) {
              pass = true;
            } else {
              pass = false;
            }
          }

          if (condition.type == 'inventory') {
            let hasItems = true;
            var itemsInInventory: any = [];
            // check items
            if (this.playerSessionService.sessionData?.inventory?.length == 0) {
              hasItems = false;
            }
            this.playerSessionService.sessionData?.inventory?.forEach(
              (item: any, index: number) => {
                itemsInInventory.push(item.id);
              }
            );
            condition.data.items.forEach((itemId: any, item: any) => {
              if (itemsInInventory.indexOf(itemId.id) == -1) {
                hasItems = false;
              }
            });

            pass = hasItems;
          }
          passes.push(pass);
        });

        pass = true;
        passes.forEach((_pass: any) => {
          if (_pass != true) {
            pass = false;
          }
        });
        hidden = !pass;
      }
    }

    return hidden;
  }

  clearStepTimeouts() {
    // clear any timesouts
    for (let i = 0; i < this.stepTimers.length; i++) {
      clearTimeout(this.stepTimers[i]);
    }
    this.stepTimers = [];
  }

  clearStepIntervals() {
    // clear any timesouts
    for (let i = 0; i < this.stepIntervals.length; i++) {
      clearInterval(this.stepIntervals[i]);
    }
    this.stepIntervals = [];
  }

  clearStepVarListeners() {
    this.stepVarListeners = [];
  }

  getComponentHidden(component: any, content?: any) {
    //console.log(component.type);
    let hidden = component.hidden;

    // is it in a block?
    let block = this.getComponentBlock(component, content);
    if (block) {
      if (block.hidden) {
        return block.hidden;
      }
      // check if it's been hidden
      let blockElem = document?.getElementById(
        'component-container-ys-' + block.id
      );
      if (blockElem) {
        let blockStyle = window.getComputedStyle(blockElem);
        if (blockStyle.display == 'none') {
          return true;
        }
      }
    }

    if (component.hiddenTillEnd == true) {
      // hidden = true;
    }
    if (component.config) {
      if (
        Array.isArray(component.config.showWhen) &&
        component.config.showWhen.length > 0
      ) {
        hidden = false;

        if (component.hidden == true) {
          hidden = true;
        }

        let pass: any = undefined;
        let passes: any = [];
        component?.config?.showWhen?.forEach((condition: any) => {
          if (condition.type == 'score') {
            // try it here
            let theVar = this.playerSessionService.sessionData.score;

            var comparator = condition.data.value.charAt(0);
            var comparatorB = condition.data.value.charAt(1);
            pass = false;
            switch (comparator) {
              default:
                // console.log('comparing ' + theVar + ' == ' + condition.data.value);
                if (theVar == condition.data.value) {
                  pass = true;
                }
                break;
              case '>':
                switch (comparatorB) {
                  default:
                    if (+theVar > +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar >= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;
              case '<':
                switch (comparatorB) {
                  default:
                    if (+theVar < +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar <= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;

              case '!':
                if (comparatorB == '=') {
                  if (theVar != condition.data.value.substring(2)) {
                    pass = true;
                  }
                }
                break;
            }
          }
          if (condition.type == 'var') {
            // try it here
            let theVar = this.getSessionVar(
              this.playerSessionService.sessionData,
              condition.data.variable
            );

            var comparator = condition.data.value.charAt(0);
            var comparatorB = condition.data.value.charAt(1);
            pass = false;
            switch (comparator) {
              default:
                // console.log('comparing ' + theVar + ' == ' + condition.data.value);
                if (theVar == condition.data.value) {
                  pass = true;
                }
                break;
              case '>':
                switch (comparatorB) {
                  default:
                    if (+theVar > +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar >= +condition.data.value.substring(2)) {
                      pass = true;
                      // console.log('yes');
                    }
                    break;
                }
                break;
              case '<':
                switch (comparatorB) {
                  default:
                    if (+theVar < +condition.data.value.substring(1)) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (+theVar <= +condition.data.value.substring(2)) {
                      pass = true;
                    }
                    break;
                }
                break;

              case '!':
                if (comparatorB == '=') {
                  if (theVar != condition.data.value.substring(2)) {
                    pass = true;
                  }
                }
                break;
            }
          }

          if (condition.type == 'score') {
            var comparator = condition.data.value.charAt(0);
            var comparatorB = condition.data.value.charAt(1);
            pass = false;
            switch (comparator) {
              default:
                // console.log('comparing ' + this.playerSessionService.sessionData.score + ' == ' + condition.data.value);
                if (
                  this.playerSessionService.sessionData.score ==
                  condition.data.value
                ) {
                  pass = true;
                }
                break;
              case '>':
                switch (comparatorB) {
                  default:
                    if (
                      this.playerSessionService.sessionData.score >
                      +condition.data.value.substring(1)
                    ) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (
                      this.playerSessionService.sessionData.score >=
                      +condition.data.value.substring(2)
                    ) {
                      pass = true;
                    }
                    break;
                }
                break;
              case '<':
                switch (comparatorB) {
                  default:
                    if (
                      this.playerSessionService.sessionData.score <
                      +condition.data.value.substring(1)
                    ) {
                      pass = true;
                    }
                    break;
                  case '=':
                    if (
                      this.playerSessionService.sessionData.score <=
                      +condition.data.value.substring(2)
                    ) {
                      pass = true;
                    }
                    break;
                }
                break;

              case '!':
                if (comparatorB == '=') {
                  if (
                    this.playerSessionService.sessionData.score !=
                    +condition.data.value.substring(2)
                  ) {
                    pass = true;
                  }
                }
                break;
            }
          }

          if (condition.type == 'topVar') {
            // try it here
            let topVar = null;
            let topVarScore = -999999999999999;
            this.playerSessionService?.sessionData?.vars?.forEach(
              (_sessionVar: any) => {
                if (
                  _sessionVar.value > topVarScore &&
                  _sessionVar.name != 'user' &&
                  _sessionVar.name != 'score'
                ) {
                  topVar = _sessionVar.name;
                  topVarScore = _sessionVar.value;
                }
              }
            );
            if (topVar && topVar == condition.data.variable) {
              pass = true;
            } else {
              pass = false;
            }
          }

          if (condition.type == 'bottomVar') {
            // try it here
            let bottomVar = null;
            let bottomVarScore = 999999999999999999999;
            this.playerSessionService?.sessionData?.vars?.forEach(
              (_sessionVar: any) => {
                if (
                  _sessionVar.value < bottomVarScore &&
                  _sessionVar.name != 'user' &&
                  _sessionVar.name != 'score'
                ) {
                  bottomVar = _sessionVar.name;
                  bottomVarScore = _sessionVar.value;
                }
              }
            );
            if (bottomVar && bottomVar == condition.data.variable) {
              pass = true;
            } else {
              pass = false;
            }
          }

          if (condition.type == 'inventory') {
            let hasItems = true;
            var itemsInInventory: any = [];
            // check items
            if (this.playerSessionService.sessionData?.inventory?.length == 0) {
              hasItems = false;
            }
            this.playerSessionService.sessionData?.inventory?.forEach(
              (item: any, index: number) => {
                itemsInInventory.push(item.id);
              }
            );
            condition.data.items.forEach((itemId: any, item: any) => {
              if (itemsInInventory.indexOf(itemId.id) == -1) {
                hasItems = false;
              }
            });

            pass = hasItems;
          }

          if (condition.type == 'inventoryNot') {
            let hasItems = true;
            var itemsInInventory: any = [];
            // check items
            if (this.playerSessionService.sessionData?.inventory?.length == 0) {
              hasItems = false;
            }
            this.playerSessionService.sessionData?.inventory?.forEach(
              (item: any, index: number) => {
                itemsInInventory.push(item.id);
              }
            );
            condition.data.items.forEach((itemId: any, item: any) => {
              if (itemsInInventory.indexOf(itemId.id) == -1) {
                hasItems = false;
              }
            });

            pass = !hasItems;
          }

          if (typeof pass !== 'undefined') {
            passes.push(pass);
          }
        });

        if (passes.length > 0) {
          pass = true;
          passes.forEach((_pass: any) => {
            if (_pass != true) {
              pass = false;
            }
          });
          hidden = !pass;
        }
      }
    }
    // console.log(hidden);
    if (component?.config?.showWhenOverride) {
      component.hidden = hidden;
    }

    return hidden;
  }

  onShowModal(title: any, content: any) {
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
        modalTitle: title,
        modalContent: content,
      },
    };
    this.bsModalRef = this.modalService.show(
      PlayerModalComponent,
      initialState
    );
    this.bsModalRef.setClass(
      'modal-xl anim-open ys-modal scenario-ui-' + this.scenario.id
    );
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });
  }

  onShowMedia(media: any) {
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
        media: media,
      },
    };
    this.bsModalRef = this.modalService.show(
      PlayerModalComponent,
      initialState
    );
    this.bsModalRef.setClass('modal-xl anim-open modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });
  }

  onShowInventory() {
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
      },
    };
    this.bsModalRef.id = 'inventory';
    this.bsModalRef = this.modalService.show(InventoryComponent, initialState);
    this.bsModalRef.setClass(
      'modal-xl anim-open inventory-wrap scenario-ui-' + this.scenario.id
    );
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });
  }
  onHideInventory() {
    if (this.bsModalRef.id == 'inventory') {
      this.bsModalRef.hide();
    }
    // console.log(this.bsModalRef);
    this.bsModalRef.hide();
  }
  onShowHelp() {
    let helpContent: any = '';
    // console.log('Show help');

    if (this.scenario.layout) {
      this.scenario.layout.content.components.forEach((component: any) => {
        if (component.type == 'controls') {
          helpContent = component.data.helpContent;
        }
      });
    }

    // get the help content...

    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
        helpContent: helpContent,
      },
    };
    this.bsModalRef = this.modalService.show(PlayerHelpComponent, initialState);
    this.bsModalRef.setClass('modal-xl anim-open modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });
  }

  getInventoryItem(item: any) {
    var scenarioItem: any;
    this.scenario.inventory.items.forEach((_item: any) => {
      if (_item.id == item.id) {
        scenarioItem = _item;
      }
    });
    if (scenarioItem) {
      return scenarioItem;
    } else {
      return item;
    }
  }

  onEnd() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to end this scenario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, end it',
    }).then((result) => {
      if (result.value) {
        window.close();
        // SCORM?
        if (window.API) {
          window.API.LMSSetValue('cmi.core.exit', '');

          window.API.LMSCommit('');
        }
      }
    });
  }

  onPreviousStep() {
    if (this.playerSessionService.sessionData?.history?.length > 1) {
      // go to the previous step
      let prevStep =
        this.playerSessionService.sessionData.history[
          this.playerSessionService.sessionData.history.length - 2
        ];
      // remove the last step from history
      this.playerSessionService.sessionData?.history?.pop();
      this.goToStep(prevStep);
      // remove the last step from history
      this.playerSessionService.sessionData?.history?.pop();
    }
  }

  onComponentPressed(component: any) {
    // which components can we press?
    const clickAllowed = [
      'text',
      'image',
      'shape',
      'video',
      'audio',
      'drawing',
      'table',
      'chart',
      'download',
      'icon',
      'blank',
      'block',
    ];
    // interactivity show window on click
    if (
      component?.interactivity?.window?.id &&
      component?.interactivity?.window?.trigger == 'click'
    ) {
      this.updateComponent.next({
        id: component.interactivity.window.id,
        newData: { show: true },
      });
    }

    if (
      clickAllowed.indexOf(component.type) !== -1 &&
      component.data &&
      component.data.actions &&
      component.data.actions.length > 0
    ) {
      this.playerSessionService.addToLog('componentPressed', {
        id: component.id,
        type: component.type,
        name: component.name,
      });
      // check if there are any actions?

      this.doActions(component.data.actions, 'componentPressed', component);
    }
  }

  onComponentDoublePressed(component: any) {
    // which components can we press?
    const clickAllowed = [
      'text',
      'image',
      'shape',
      'video',
      'audio',
      'drawing',
      'table',
      'chart',
      'download',
      'icon',
      'blank',
      'block',
    ];
    // interactivity show window on click
    if (
      component?.interactivity?.window?.id &&
      component?.interactivity?.window?.trigger == 'dblclick'
    ) {
      this.updateComponent.next({
        id: component.interactivity.window.id,
        newData: { show: true },
      });
    }

    if (
      clickAllowed.indexOf(component.type) !== -1 &&
      component.data &&
      component.data.actions &&
      component.data.actions.length > 0
    ) {
      this.playerSessionService.addToLog('componentDoublePressed', {
        id: component.id,
        type: component.type,
        name: component.name,
      });
      // check if there are any actions?

      this.doActions(
        component.data.actions,
        'componentDoublePressed',
        component
      );
    }
  }

  onComponentHovered(component: any) {
    // which components can we press?
    const clickAllowed = [
      'text',
      'image',
      'shape',
      'video',
      'audio',
      'drawing',
      'table',
      'chart',
      'download',
      'button',
      'icon',
      'blank',
      'block',
    ];

    // interactivity show window on click
    if (
      component?.interactivity?.window?.id &&
      component?.interactivity?.window?.trigger == 'mouseenter:mouseleave'
    ) {
      this.updateComponent.next({
        id: component.interactivity.window.id,
        newData: { show: true },
      });
    }

    if (
      clickAllowed.indexOf(component.type) !== -1 &&
      component.data &&
      component.data.actions &&
      component.data.actions.length > 0
    ) {
      this.playerSessionService.addToLog('componentHovered', {
        id: component.id,
        type: component.type,
        name: component.name,
      });
      this.doActions(component.data.actions, 'componentHovered', component);
    }
  }

  onComponentDropped(component: any, content: any) {
    // which components can we press?
    const dropAllowed = [
      'text',
      'image',
      'shape',
      'video',
      'audio',
      'drawing',
      'table',
      'chart',
      'download',
      'icon',
      'button',
      'blank',
    ];
    if (
      dropAllowed.indexOf(component.type) !== -1 &&
      component.data &&
      component.data.actions &&
      component.data.actions.length > 0
    ) {
      this.playerSessionService.addToLog('componentDropped', {
        id: component.id,
        type: component.type,
        name: component.name,
      });
      this.doActions(
        component.data.actions,
        'componentDropped',
        component,
        content
      );
    }
  }

  onActivityEnded(event: any, content: any) {
    this.playerSessionService.addToLog('activityEnded', {
      id: event.id,
      type: event.type,
    });
    // show the choices!
    content.components.forEach((component: any) => {
      // console.log(component);
      if (component.type == 'choices') {
        component.hidden = false;
      }
    });
    // do actions

    if (event.data.actions) {
      event.data.actions.forEach((action: any) => {
        this.doAction(event, action, null, 'onComplete', null);
      });
    }
  }

  onVideoEnd($event: any, events?: any, component?: any, content?: any) {
    content.components.forEach((component: any) => {
      if (component.type == 'choices') {
        if (component.hiddenTillEnd) {
          component.hidden = false;
        }
      }
      if (
        component.config &&
        component.config.showWhen &&
        Array.isArray(component.config.showWhen)
      ) {
        component.config.showWhen.forEach((condition: any) => {
          if (condition.type == 'event' && condition.data.event == 'videoEnd') {
            component.hidden = false;
          }
        });
      }
    });
    this.playerSessionService.addToLog('videoEnd', {
      id: component.id,
      name: component.name,
    });

    // events
    events.forEach((action: any) => {
      this.doAction(component, action, null, 'onMediaEnds', content);
    });
  }

  onVideoPause($event: any, events?: any, component?: any, content?: any) {
    events.forEach((action: any) => {
      this.doAction(component, action, null, 'onMediaPaused', content);
    });
  }
  onVideoPlay($event: any, events?: any, component?: any, content?: any) {
    events.forEach((action: any) => {
      this.doAction(component, action, null, 'onMediaPlays', content);
    });
  }

  onVideoTimeUpdate($event: any, events?: any, component?: any, content?: any) {
    // console.log($event.srcElement.duration - $event.srcElement.currentTime);
    var videoID = $event.srcElement.id;
    var countdownElem = this.document.getElementById('countdown-' + videoID);
    events.forEach((_event: any) => {
      const exactTime = $event.srcElement.currentTime;
      const current = Math.floor(exactTime);

      // still in the same second! do nothing
      if (current === this.videoTime[component.id]) return;

      if (_event.time == current) {
        this.doAction(component, _event.action, null, 'onMediaTime', content);
      }
      this.videoTime[component.id] = current;
    });
    if (countdownElem) {
      var progress =
        ($event.srcElement.currentTime / $event.srcElement.duration) * 100;
      this.document.getElementById('countdown-' + videoID).style.width =
        progress + '%';
    }
    //var timeSpan = document.querySelector('#videoTimer-' + component.id);
    //timeSpan.innerText = video.duration - video.currentTime;
  }
  onVideoCanPlay($event: any, component: any) {
    if (component.video.muted) {
      $event.srcElement.muted = true;
    }
  }

  onVideoClick($event: any, component: any) {
    if (!component.video.controls && !component.video.disablePauseClick) {
      if (!$event.srcElement.paused) {
        $event.srcElement.pause();
      } else {
        $event.srcElement.play();
      }
    }
  }

  // audio

  onAudioEnd($event: any, events?: any, component?: any, content?: any) {
    // events
    events.forEach((action: any) => {
      this.doAction(component, action, null, 'onMediaEnds', content);
    });
  }

  onAudioPause($event: any, events?: any, component?: any, content?: any) {
    events.forEach((action: any) => {
      this.doAction(component, action, null, 'onMediaPaused', content);
    });
  }
  onAudioPlay($event: any, events?: any, component?: any, content?: any) {
    events.forEach((action: any) => {
      this.doAction(component, action, null, 'onMediaPlays', content);
    });
  }

  onAudioTimeUpdate($event: any, events?: any, component?: any, content?: any) {
    events.forEach((_event: any) => {
      const exactTime = $event.srcElement.currentTime;
      const current = Math.floor(exactTime);

      // still in the same second! do nothing
      if (current === this.videoTime[component.id]) return;

      if (_event.time == current) {
        this.doAction(component, _event.action, null, 'onMediaTime', content);
      }
      this.videoTime[component.id] = current;
    });
  }

  onChoice(choice: any, step: any) {
    // make it null for animations
    if (choice.choice_id) {
      this.playerSessionService.addToLog('makeChoice', {
        id: choice.choice_id,
        title: choice.title,
        stepId: step.step_id,
      });
      this.playerSessionService?.sessionData?.choices?.push({
        id: choice.choice_id,
        title:choice.title
      });
      // do the choice actions here
      this.doActions(choice.actions, 'choice', choice);
      /* if (this.disableAnimations != true) {
        this.canvasAnimationClass = '';
        this.canvasAnimationStyle = '';
      }*/

      step.content.components.forEach((component: any) => {
        if (component.type == 'choices') {
          component.hidden = component.hiddenTillEnd;
        }
        if (component.type == 'pick') {
          component.data.feedback = 'Task completed';
        }
      });

      if (choice.target_step_id) {
        this.currentStep.next(choice.target_step_id);
      }
    }
  }

  doComponentsCollide(component1: any, component2: any) {
    return !(
      +component1.pos.y + +component1.height <= +component2.pos.y ||
      +component1.pos.y >= +component2.pos.y + +component2.height ||
      +component1.pos.x + +component1.width <= +component2.pos.x ||
      +component1.pos.x >= +component2.pos.x + +component2.width
    );
  }
  // block functions
  getComponentBlock(component: any, content: any) {
    let block: any;
    content?.components.forEach((contentComponent: any) => {
      if (contentComponent.type == 'block') {
        if (contentComponent.data.componentIds.indexOf(component.id) !== -1) {
          block = contentComponent;
        }
      }
    });
    return block;
  }
  getBlockComponents(component: any, content: any) {
    let blockComponents: any = [];
    content?.components.forEach((_component: any) => {
      if (component.data.componentIds.indexOf(_component.id) !== -1) {
        blockComponents.push(_component);
      }
    });
    return blockComponents;
  }

  isActiveComponent(component: any) {
    let hasPress = false;
    if (
      (component.data &&
        component.data.actions &&
        component.data.actions.length > 0) ||
      component.interactivity?.popover?.template?.trigger ||
      component.interactivity?.window?.trigger
    ) {
      component.data.actions.forEach((action: any) => {
        action.events.forEach((event: any, eIndex: number) => {
          if (event.id == 'onPress') {
            hasPress = true;
          }
        });
      });

      if (
        component.interactivity?.popover?.template?.trigger == 'click' ||
        component.interactivity?.popover?.template?.trigger == 'dblclick' ||
        component.interactivity?.window?.trigger == 'click' ||
        component.interactivity?.window?.trigger == 'dblclick'
      ) {
        hasPress = true;
      }
    }
    return hasPress;
  }

  getComponent(scenario: any, componentId: string) {
    let component: any = null;
    scenario.steps.forEach((step: any) => {
      step.content?.components.forEach((_component: any) => {
        if (_component.id == componentId) {
          component =  _component;
        }
      });
    });
    return component;
  }
}
