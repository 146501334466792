import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { nanoid } from 'nanoid';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-hotspot-component',
  templateUrl: './hotspot-component.component.html',
  styleUrls: ['./hotspot-component.component.scss'],
})
export class HotspotComponentComponent implements OnInit {
  @Input() component: any;
  @Input() scenario: any;
  completed = false;
  varsSub: any;
  scenarioVars:any;
  hotspotVisible: any = {};

  @Output() activityEnded: EventEmitter<any> = new EventEmitter();

  constructor(
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService
  ) {}
  ngOnInit() {
    this.varsSub = this.playerSessionService.variablesChanges.subscribe(
      (changed) => {
        // copy the object so the pipe updates
        this.scenarioVars = JSON.parse(
          JSON.stringify(this.playerSessionService.getVars())
        );
      }
    );
  }

  getHotspotStyle(hotspot: any) {
    let opacity = 1;
    if (this.component.data?.options?.hideIcons) {
      opacity = 0;
    }
    let style: any = {
      width: hotspot.width,
      height: hotspot.height,
      left: hotspot.x + 'px',
      top: hotspot.y + 'px',
      opacity: opacity,
    };

    if (this.component.hotspotAnimationIn?.name) {
      style['--animate-duration'] =
        this.component.hotspotAnimationIn.speed + 's';
      style['--animate-delay'] = this.component.hotspotAnimationIn.delay + 's';

      if (this.component.hotspotAnimationIn.repeat) {
        if (this.component.hotspotAnimationIn.repeat !== 'infinite') {
          style['--animate-repeat'] = +this.component.hotspotAnimationIn.repeat;
        }
      }
    }

    return style;
  }

  getHotspotClass() {
    let classStr = '';
    if (this.component.hotspotAnimationIn) {
      classStr +=
        'animate__animated animate__delay-1s animate__' +
        this.component.hotspotAnimationIn.name;

      if (this.component.hotspotAnimationIn.repeat) {
        if (this.component.hotspotAnimationIn.repeat == 'infinite') {
          classStr += ' animate__infinite';
        } else {
          classStr += ' animate__repeat-1';
        }
      }
    }
    return classStr;
  }

  getHotspotImageStyles(component: any) {
    let style: any = {};
    if (component.data.image.offsetX) {
      style['left'] = component.data.image.offsetX + 'px';
    }
    if (component.data.image.offsetY) {
      style['top'] = component.data.image.offsetY + 'px';
    }
    return style;
  }

  onHotspotPressed(hotspot: any) {

    // check actions...
    // interactivity show window on click
    if (
      hotspot?.interactivity?.window?.id &&
      hotspot?.interactivity?.window?.trigger == 'click'
    ) {
      this.playerService.updateComponent.next({
        id: hotspot.interactivity.window.id,
        newData: { show: true },
      });
    }
    
    this.playerSessionService.addToLog('hotspotPressed', {
      id: this.component.id,
      hotspot: hotspot,
    });
    this.playerService.doActions(
      hotspot.actions,
      'hotspotPressed',
      this.component
    );
  }

  onComplete() {
    this.hotspotVisible = {};
    this.completed = true;
    this.activityEnded.emit(this.component);
    this.playerService.executeActionQueue();
  }

  onCancel() {
    this.hotspotVisible = {};
    this.playerSessionService.addToLog('hotspotCancelled', {
      id: this.component.id,
    });
    this.completed = true;
    this.activityEnded.emit(this.component);
  }
  ngOnDestroy(): void {
    this.hotspotVisible = {};
    this.varsSub?.unsubscribe();
  }

  onHotspotShown(hotspot: any) {
    if(!hotspot.id){
      hotspot.id = nanoid();
    }
    this.hotspotVisible[hotspot.id] = true;
  }
  onHotspotHidden(hotspot: any) {
    this.hotspotVisible[hotspot.id] = false;
  }
}
