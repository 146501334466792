<audio
  *ngIf="component.audio.src"
  style="width: 100%; max-height: 100%"
  src="{{ component.audio.src }}"
  [attr.autoplay]="component.audio.autoplay && !disableAudio ? true : null"
  [attr.loop]="component.audio.loop && !disableAudio ? true : null"
  controls
  controlsList="nodownload"
  [muted]="muted"
  id="audio-{{ component.id }}"

  (ended)="playerService.onAudioEnd($event, endEvents, component, content)"
  (pause)="playerService.onAudioPause($event, pauseEvents, component, content)"
  (play)="playerService.onAudioPlay($event, playEvents, component, content)"
  (timeupdate)="playerService.onAudioTimeUpdate($event, timedEvents, component, content)"
>
  Your browser does not support the audio element.
</audio>
<span class="img-placeholder" *ngIf="!component.audio.src">
  <i class="bx bx-volume-full"></i>
</span>
