import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-embed-component',
  templateUrl: './embed-component.component.html',
  styleUrls: ['./embed-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedComponentComponent implements OnInit {
  @Input() component: any;

  @ViewChild('embedIframe') embedIframe?: ElementRef;

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.embedIframe) {
      if (this.component.embed.params) {
        this.component.embed.params.forEach((param: any) => {
          this.embedIframe?.nativeElement.setAttribute(param.name, param.value);
        });
      }
    }
  }
}
