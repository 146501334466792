import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PlayerSessionService } from 'src/app/services/player-session.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-player-help',
  templateUrl: './player-help.component.html',
  styleUrls: ['./player-help.component.scss']
})
export class PlayerHelpComponent {

  @Input() scenario: any;
  helpContent:any;
  picked: any = [];
  playerInventroy: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private playerSessionService: PlayerSessionService,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {}

}
