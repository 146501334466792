import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from '../services/content.service';

@Pipe({
  name: 'blockStyle',
})
export class BlockStylePipe implements PipeTransform {
  constructor(private contentService: ContentService) {}
  transform(component: unknown, content: unknown, componentSettings:string, scale?:any): any[] {
    let blockStyles = this.contentService.getBlockStyle(component, content);
    return blockStyles;
  }
}
