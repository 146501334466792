import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'animationClass'
})
export class AnimationClassPipe implements PipeTransform {

  transform(component: any, controlVar:any, componentData?:any): unknown {
    

    let classStr = '';
    if (component[controlVar]) {
      classStr +=
        'animate__animated animate__delay-1s animate__' +
        component[controlVar].name;

      if (component[controlVar].repeat) {
        if (component[controlVar].repeat == 'infinite') {
          classStr += ' animate__infinite';
        } else {
          classStr += ' animate__repeat-1';
        }
      }
    }
    return classStr;
  }

}
