import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-component',
  templateUrl: './text-component.component.html',
  styleUrls: ['./text-component.component.scss'],
})
export class TextComponentComponent {
  @Input() component: any;
  @Input() htmlString: any;
  viewInit = false;

  ngOnInit(): void {}

  ngOnChanges(changes: any) {
    if (
      this.htmlString &&
      this.component.content &&
      this.htmlString !== this.component.content
    ) {
      setTimeout(() => {
        this.doAutoHeight(this.component);
      }, 10);
    }
  }

  doAutoHeight(selectedComponent: any) {
    if (selectedComponent.autoHeight == true) {
      selectedComponent.height = 0;
      let componentElem = document.getElementById(
        'component-ys-inner-' + selectedComponent.id
      );

      // check for padding notation...
      let paddingOffset = 0;
      if (String(selectedComponent.style.padding).indexOf(' ') >= 0) {
        let paddingSplit = selectedComponent.style.padding.split(' ');
        if (paddingSplit[0] && paddingSplit[2]) {
          paddingOffset = +paddingSplit[0] + +paddingSplit[2];
        }
      } else {
        paddingOffset = selectedComponent.style.padding * 2;
      }

      if (componentElem) {
        let children = componentElem.children;
        let qlEditorElem: any = children[0];
        qlEditorElem.style.height = 0;
        selectedComponent.height = qlEditorElem.scrollHeight + paddingOffset;
        qlEditorElem.style.height = '100%';
      }
    }
  }
}
