<div
  class="popover-wrap"
  [ngClass]="{
    'popover-wrap-left':
      component.interactivity.popover.template.layout == 'left',
    'popover-wrap-right':
      component.interactivity.popover.template.layout == 'right',
    'popover-wrap-top':
      component.interactivity.popover.template.layout == 'top',
    'popover-wrap-bottom':
      component.interactivity.popover.template.layout == 'bottom' ||
      !component.interactivity.popover.template.layout
  }"
>
  <div
    *ngIf="
      component.interactivity.popover.template.image &&
      component.interactivity.popover.content.image
    "
  >
    <img
      src="{{ component.interactivity.popover.content.image }}"
      alt=""
      style="width: 100%;{{
        component.interactivity.popover.template.mediaPadding
          ? 'padding:' +
            component.interactivity.popover.template.mediaPadding +
            'px'
          : ''
      }}"
    />
  </div>
  <div
    *ngIf="
      component.interactivity.popover.template.video &&
      component.interactivity.popover.content.video
    "
  >
    <video
      style="width: 100%; max-height: 100%"
      [attr.controls]="true"
      controlsList="nodownload"
      playsinline
    >
      <source
        src="{{ component.interactivity.popover.content.video }}"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
  <div
    class="p-3"
    *ngIf="component.interactivity.popover.template.body"
    [innerHtml]="
      component?.interactivity?.popover?.content?.body
        | scenarioVars : scenarioVars
    "
  ></div>
</div>
<div
  *ngIf="
    component.interactivity.popover.template.audio &&
    component.interactivity.popover.content.audio
  "
  class="p-2"
>
  <audio
    style="width: 100%; max-height: 100%"
    src="{{ component.interactivity.popover.content.audio }}"
    controls
    controlsList="nodownload"
  >
    Your browser does not support the audio element.
  </audio>
</div>
