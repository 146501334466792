<div class="modal-header">
  <h4 class="modal-title pull-left"><i class="bx bx-help-circle"></i> Help</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div
  class="modal-body"
  *ngIf="scenario && playerInventroy"
  style="max-height: 75vh; overflow-y: scroll"
>
<div [innerHTML]="helpContent" *ngIf="helpContent"></div>
</div>
