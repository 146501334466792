<div class="chat-container" style="--animate-duration: 0.25s">
  <div class="task-obscure" *ngIf="completed && component.data.obscure"></div>
  <div
    class="chat-character"
    [ngClass]="{
      'chat-container-r': component.style.layout == 'right'
    }"
  >
    <div class="chat-speech">
      <div class="chat-speech-inner {{ convoAnimClass }}">
        <div *ngIf="lastAsk">
          <div
            class="last-ask speech-bubble sb-tl speech-bubble-xs {{
              lastAskAnim
            }}"
            style="margin-left: 20px"
          >
            {{ lastAsk }}
          </div>
        </div>
        <blockquote
          class="speech-bubble {{ bubbleAnimClass }}"
          [ngClass]="{
            'sb-tr': component.style.layout == 'left',
            'sb-tl': component.style.layout == 'right'
          }"
        >
          <p>
            <span [innerHTML]="response | nl2br"></span>
          </p>
        </blockquote>
      </div>
      <div class="chat-choices {{ chatChoicesAnimClass }}" *ngIf="!completed">
        <a
          *ngFor="let stem of activeStems"
          class="speech-bubble speech-bubble-sm sb-tl hover-grow"
          (click)="onChooseStem(stem)"
          >{{ stem.q }}</a
        >
      </div>
    </div>
    <div class="chat-avatar">
      <div class="chat-avatar-inner {{ avatarAnimClass }}">
        <i class="bx bxs-user" *ngIf="!component.data.avatar"></i>
        <img
          src="{{ component.data.avatar }}"
          *ngIf="component.data.avatar"
          alt=""
          [width]="component.data.avatarWidth"
        />
      </div>
    </div>
  </div>

  <div class="task-btns text-center" *ngIf="!completed">
    <button class="btn btn-ys" (click)="onEndChat($event)">
      <i class="bx {{component.style.endChatIcon}} me-1" *ngIf="component.style.endChatIcon"></i> 
      <ng-container *ngIf="!component.data.endLabel">End Chat</ng-container>
      <ng-container *ngIf="component.data.endLabel">{{
        component.data.endLabel
      }}</ng-container>
    </button>
  </div>


  <div class="task-btns text-center" *ngIf="completed && (component.data.allowRetake)">
    <button
    type="button"
    class="btn btn-ys btn-end-chat"
    (click)="onRetakeChat()"
  >
    <i
      class="bx {{ component.style.retakeChatIcon }} me-1"
      *ngIf="component.style.retakeChatIcon"
    ></i>
    <ng-container *ngIf="!component.data.retakeLabel"
      >Retake</ng-container
    >
    <ng-container *ngIf="component.data.retakeLabel">{{
      component.data.retakeLabel
    }}</ng-container>
  </button>
  </div>
</div>
