export class ScenarioSession {
  public scenario_session_id :number;
  public session_id: string;
  public scenario_id :number; 
  public published_hash: string;
  public user: string;
  public session_data: string;
  public log: string;
  public score: string;
  public vars: string;
  public route: string;
  public duration: string;
  public step_id:string;
  public last_active: string;
  public created: string;
  public modified: string;

constructor(
   scenario_session_id :number,
   session_id: string,
   scenario_id :number, 
   published_hash: string,
   user: string,
   session_data: string,
   log: string,
   score: string,
   vars: string,
   route: string,
   duration: string,
   step_id: string,
   last_active: string,
   created: string,
   modified: string
) {
  this.scenario_session_id = scenario_session_id;
  this.session_id = session_id;
  this.scenario_id = scenario_id;
  this.published_hash = published_hash;
  this.user = user;
  this.session_data = session_data;
  this.log = log;
  this.score = score;
  this.vars = vars;
  this.route = route;
  this.duration = duration;
  this.step_id = step_id;
  this.last_active = last_active;
  this.created = created;
  this.modified = modified;
}
}
