import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ContentService } from 'src/app/services/content.service';
import { PlayerService } from 'src/app/services/player.service';
import { InventoryComponent } from '../inventory/inventory.component';
import { PlayerHelpComponent } from './player-help/player-help.component';
import Swal from 'sweetalert2';
import { PlayerSessionService } from 'src/app/services/player-session.service';

@Component({
  selector: 'app-global-components',
  templateUrl: './global-components.component.html',
  styleUrls: ['./global-components.component.scss'],
})
export class GlobalComponentsComponent implements OnInit, OnDestroy {
  @Input() content: any;
  @Input() step: any;
  @Input() scenario: any;
  @Input() width: any;
  @Input() height: any;
  @Input() options: any;
  @Input() disableAnimations = false;
  @Input() disableVideo = false;
  @Input() disableAudio = false;
  @Input() disableOverflow = false;
  muted = false;
  fullscreen = false;
  canvasAnimationClass?: string;
  canvasAnimationStyle?: string;
  helpContent: any = '';
  @ViewChild('stepContentContainer') stepContentContainer?: ElementRef;

  scenarioVars: any;
  @Input() scale: any;
  varsSub: any;


  componentAtDragStart: any;
  componentsAtDragStart: any = {};

  constructor(
    public contentService: ContentService,
    public playerService: PlayerService,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public playerSessionService: PlayerSessionService
  ) {}

  ngOnInit(): void {
    // clone the content so we don't disturb the original with actions..
    this.content = JSON.parse(JSON.stringify(this.content));
    this.content.components.forEach((component: any) => {
      if (component.type == 'controls') {
        this.helpContent = component.data.helpContent;
      }
    });
  }

  ngAfterContentInit() {
    this.varsSub = this.playerSessionService.variablesChanges.subscribe(
      (changed) => {
        if (changed) {
          this.scenarioVars = JSON.parse(
            JSON.stringify(this.playerSessionService.getVars())
          );
        }
      }
    );
  }

  onOpenFullscreen() {
    this.fullscreen = true;
    this.playerService.fullscreen.next(true);
  }
  onCloseFullscreen() {
    this.fullscreen = false;
    this.playerService.fullscreen.next(false);
  }

  closeFullscreen() {
    this.fullscreen = false;
    this.playerService.fullscreen.next(false);
  }
  onUnMute() {
    this.muted = false;
    this.playerService.muted.next(false);
  }

  onMute() {
    this.muted = true;
    this.playerService.muted.next(true);
  }

  onShowInventory() {
    this.playerService.onShowInventory();
    /*
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
      },
    };
    this.bsModalRef = this.modalService.show(
      InventoryComponent,
      initialState
    );
    this.bsModalRef.setClass('modal-xl anim-open modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });*/
  }
  onShowHelp() {
    this.playerService.onShowHelp();
    /*
    const initialState: ModalOptions = {
      animated: false,
      initialState: {
        scenario: this.scenario,
        helpContent:this.helpContent
      },
    };
    this.bsModalRef = this.modalService.show(
      PlayerHelpComponent,
      initialState
    );
    this.bsModalRef.setClass('modal-xl anim-open modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide?.subscribe(() => {
      this.bsModalRef.setClass('modal-xl anim-close');
    });*/
  }

  onRestart() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to restart this scenario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Restart it',
    }).then((result) => {
      if (result.value) {
        this.playerService.restart();
      }
    });
  }

  onClose() {
    this.playerService.onEnd();
  }

  onBack() {
    this.playerService.onPreviousStep();
  }

  canGoBack() {
    return this.playerSessionService.sessionData?.history?.length > 1;
  }

  ngOnDestroy(): void {
    this.varsSub?.unsubscribe();
  }

  onComponentPressed(component: any) {
    // which components can we press?
    const clickAllowed = [
      'text',
      'image',
      'shape',
      'video',
      'audio',
      'drawing',
      'table',
      'chart',
      'download',
    ];
    if (
      clickAllowed.indexOf(component.type) !== -1 &&
      component.data &&
      component.data.actions &&
      component.data.actions.length > 0
    ) {
      this.playerSessionService.addToLog('componentPressed', {
        id: component.id,
        type: component.type,
        name: component.name,
      });
      this.playerService.doActions(
        component.data.actions,
        'componentPressed',
        component
      );
    }
  }

  onComponentHovered(component: any) {
    // which components can we press?
    const clickAllowed = [
      'text',
      'image',
      'shape',
      'video',
      'audio',
      'drawing',
      'table',
      'chart',
      'download',
    ];
    if (
      clickAllowed.indexOf(component.type) !== -1 &&
      component.data &&
      component.data.actions &&
      component.data.actions.length > 0
    ) {
      this.playerSessionService.addToLog('componentHovered', {
        id: component.id,
        type: component.type,
        name: component.name,
      });
      this.playerService.doActions(
        component.data.actions,
        'componentHovered',
        component
      );
    }
  }


  onDragStarted($event: any, component: any) {
    this.componentAtDragStart = JSON.parse(JSON.stringify(component));
    let componentCopy = JSON.parse(JSON.stringify(component));
    component.startPos = {
      x: componentCopy.pos.x,
      y: componentCopy.pos.y,
    };
    if (component.type == 'block') {
      let components = this.playerService.getBlockComponents(
        component,
        this.content
      );
      components.forEach((_component: any) => {
        this.componentsAtDragStart[_component.id] = JSON.parse(
          JSON.stringify(_component)
        );
      });
    }
  }

  onDragMove($event: any, component: any) {
    if (this.componentAtDragStart) {
      component.pos.x =
        this.componentAtDragStart.pos.x + $event?.distance?.x / this.scale;
      component.pos.y =
        this.componentAtDragStart.pos.y + $event?.distance?.y / this.scale;
    }
    // is this a block?
    if (component.type == 'block') {
      let components = this.playerService.getBlockComponents(
        component,
        this.content
      );
      components.forEach((_component: any) => {
        _component.pos.x =
          this.componentsAtDragStart[_component.id].pos.x +
          $event?.distance?.x / this.scale;
        _component.pos.y =
          this.componentsAtDragStart[_component.id].pos.y +
          $event?.distance?.y / this.scale;
      });
    }
  }

  onDragEnd($event: any, component: any) {
    // set it and reset the drag?
    let elem = document.getElementById('component-drag-' + component.id);
    let playerElem = document.getElementById('step-content-container');
    if (elem && playerElem) {
      let rect = elem.getBoundingClientRect();
      let playerRect = playerElem.getBoundingClientRect();
      component.pos.x = (rect.left - playerRect.left) / this.scale;
      component.pos.y = (rect.top - playerRect.top) / this.scale;
      $event.source._dragRef.setFreeDragPosition({
        x: component.pos.x,
        y: component.pos.y,
      });
      $event.source._dragRef.reset();
    }
    // do the actions
    this.playerService.onComponentDropped(component, this.content);
  }

}
